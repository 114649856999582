import { FieldType, TableSchema } from "../types";

export const tenantTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "_id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "application.name",
            field: "name",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            defaultSort: true
        },
        {
            name: "application.host",
            field: "host",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "application.isDeactivated",
            field: "isDeactivated",
            searchable: true,
            sortable: true,
            type: FieldType.Boolean
        }
    ]
};
