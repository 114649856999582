import React, { FC, useState, useEffect } from 'react';
import { ArticleCommentRes, Permission, ArticleRes, PlatformFullConfig } from 'app-types';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../../reducers';
import { useUserAccess } from '../../../../../../../hooks/useUserAccess';
import { Spinner, Wrapper } from '../../../../../../../components/Common';
import { __ } from '../../../../../../../helpers/i18n';
import { modal } from '../../../../../../../actions';
import Confirmation from '../../../../../../../components/Modals/Confirmation';
import { useCommentForm } from '../../../../../../../hooks/articles/useComment';
import CommentForm from '../../CommentForm';

interface Props {
  article: ArticleRes;
  getArticle: () => void;
  comment: ArticleCommentRes;
  commentParentId?: string;
}

interface ActionProps {
  kind: 'default' | 'danger';
}

const Action = styled.button<ActionProps>`
  ${({ kind }) =>
    kind === 'danger' &&
    css`
      color: ${({ theme }) => theme.colors.colorDanger};
    `}
  margin-right: ${({ theme }) => `calc(${theme.mainSpacing})`};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: ${({ theme }) => `calc(${theme.mainSpacing}/2) 0`};
`;

const Actions: FC<Props> = ({ article, comment, commentParentId, getArticle }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const allowNestedComments = useSelector(
    (state: RootState) => (state.platformConfig?.config as PlatformFullConfig).allowNestedComments,
  );
  const allowUserToDeleteOwnComments = useSelector(
    (state: RootState) => (state.platformConfig?.config as PlatformFullConfig).allowUserToDeleteOwnComments,
  );
  const { disableComments } = article;
  const { hasAccess } = useUserAccess();
  const { deleteComment, loading } = useCommentForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);

  useEffect(() => {
    setIsAnswering(false);
    setIsEditing(false);
  }, [comment]);

  const confirmDelete = () => {
    dispatch(
      modal.showModal(
        <Confirmation
          text={__('article.confirmCommentDelete')}
          confirmCallback={() =>
            deleteComment(comment.id, article._id, commentParentId, getArticle)
          }
        />,
      ),
    );
  };

  const deletePossible = () => {
    if (comment.deleted || comment.deletedByModerator || comment.deletedByUser) return false;
    if (hasAccess(Permission.ModerateComments)) return true;
    if (comment.authorId === user.details!._id && allowUserToDeleteOwnComments) return true;
    return false;
  };

  const answerPossible = () => {
    if (allowNestedComments && !commentParentId && !disableComments) return true;
    return false;
  };

  const editPossible = () => {
    if (disableComments) return false;
    if (comment.authorId === user.details!._id) return true;
    return false;
  };

  return (
    <>
      <Wrapper position="relative">
        {loading && <Spinner overlay halfTransparent small />}
        {deletePossible() && (
          <Action kind="danger" type="button" onClick={confirmDelete}>
            {__('application.delete')}
          </Action>
        )}
        {editPossible() && (
          <Action
            kind="default"
            type="button"
            onClick={() => {
              setIsEditing(!isEditing);
              setIsAnswering(false);
            }}
          >
            {isEditing ? __('application.cancelEdit') : __('application.edit')}
          </Action>
        )}
        {answerPossible() && (
          <Action
            kind="default"
            type="button"
            onClick={() => {
              setIsAnswering(!isAnswering);
              setIsEditing(false);
            }}
          >
            {isAnswering ? __('article.dontAnswerComment') : __('article.answerComment')}
          </Action>
        )}
      </Wrapper>
      {isAnswering && (
        <CommentForm articleId={article._id} commentParentId={comment.id} getArticle={getArticle} />
      )}
      {isEditing && (
        <CommentForm
          articleId={article._id}
          commentId={comment.id}
          commentParentId={commentParentId}
          getArticle={getArticle}
          content={comment.content}
        />
      )}
    </>
  );
};

export default Actions;
