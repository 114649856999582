import React, { FC } from 'react';
import styled from 'styled-components';
import { Image } from '../../../../../../components/Common';
import ApiService from '../../../../../../services/api-service';
import { gtMedia } from '../../../../../../config/config';

const StyledUserImage = styled.div`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.mainSpacing};
  flex: 0 0 auto;
  box-shadow: ${({ theme }) =>
    `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-.5 * ${theme.mainSpacing}) rgba(0,0,0, 0.5)`};

  ${gtMedia('sm')} {
    width: 40px;
    height: 40px;
  }
`;

interface Props {
  id: string | null;
}

const Author: FC<Props> = ({ id }) => {
  return (
    <StyledUserImage>
      {id ? (
        <Image round src={`${ApiService.url}user/avatarFn/photo/${id}`} />
      ) : (
        <Image round src={`/assets/images/no-avatar.svg`} />
      )}
    </StyledUserImage>
  );
};

export default Author;
