/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomSelectOption, Segment, SelectOption, TenantSelectOption } from 'app-types';
import { useApi } from '../services/useApi';
import { SelectOptions } from '../types/formFieldTypes';

interface UseTenant {
  modules: any[];
  defaultModules: any[];
  forms: SelectOptions[];
  tenants: TenantSelectOption[];
  handleLoadActiveModuleAndSelectTenant: (tenant: CustomSelectOption<{ id: string, host: string }>) => void;
}

/**
 *  @About Logic for tenants related actions
 */

export const useTenant = (): UseTenant => {
  const dispatch = useDispatch();
  const { api } = useApi();
  const [modules, setModules] = useState<SelectOption[]>([]);
  const [defaultModules, setDefaultModules] = useState<SelectOption[]>([]);
  const [forms, setForms] = useState<SelectOptions[]>([]);
  const [tenants, setTenants] = useState<TenantSelectOption[]>([]);

  const getModules = () => {
    api<SelectOption[]>({
      method: 'GET',
      path: 'assets/all/not-default-segments',
      onSuccess: (res) => setModules(res),
    });
  };

  const getDefaultModules = () => {
    api<Segment[]>({
      method: 'GET',
      path: 'assets/all/default-segments',
      onSuccess: (res) => {
        const options: SelectOption[] = res.map((segment: Segment) => ({
          ...segment,
          value: segment._id,
          label: segment.text,
        }));
        setDefaultModules(options);
      },
    });
  };

  const getFormList = async () => {
    api<SelectOptions[]>({
      method: 'GET',
      path: 'form/get-ids',
      onSuccess: (res) => setForms(res),
    });
  };

  const getTenantList = async () => {
    api<TenantSelectOption[]>({
      method: 'GET',
      path: 'tenant/get-ids',
      onSuccess: (res) => setTenants(res),
    });
  };

  const handleLoadActiveModuleAndSelectTenant = (tenant: CustomSelectOption<{ id: string, host: string }>) => {
    window.location.replace(`https://${tenant.value.host}/dashboard`);
  };

  useEffect(() => {
    const fetchData = async () => {
      getModules();
      getDefaultModules();
      getFormList();
      getTenantList();
    };
    fetchData();
  }, []);

  return { modules, defaultModules, forms, tenants, handleLoadActiveModuleAndSelectTenant };
};
