import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import styled from 'styled-components';
import { DashboardNav } from '../../../types/navs';
import SocialItem from './SocialItem';
import { RootState } from '../../../reducers';
import { __ } from '../../../helpers/i18n';
import './SocialsPanel.scss';
import { PlatformFullConfig } from 'app-types';

interface Props {
  column?: boolean;
}

const FollowLabel = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
  margin-bottom: ${({ theme }) => theme.mainSpacing};
`;

const SocialsPanel: FC<Props> = ({ column }) => {
  const config = useSelector((state: RootState) => state.platformConfig?.config as PlatformFullConfig);
  const socials: DashboardNav[] = [
    {
      text: 'Facebook',
      path: `https://www.facebook.com/${config?.tenantFbPageID}/`,
      iconName: 'facebook',
      isActive: !!config?.tenantFbPageID,
    },
    {
      text: 'Instagram',
      path: `https://www.instagram.com/${config?.tenantInstagramPageID}/`,
      iconName: 'instagram',
      isActive: !!config?.tenantInstagramPageID,
    },
    {
      text: 'Youtube',
      path: `https://www.youtube.com/${config?.tenantYbPageID}/`,
      iconName: 'youtube',
      isActive: !!config?.tenantYbPageID,
    },
    {
      text: 'LinkedIn',
      path: `https://www.linkedin.com/${config?.tenantLIPageID}/`,
      iconName: 'linkedin',
      isActive: !!config?.tenantLIPageID,
    },
    {
      text: 'Twitter',
      path: `https://www.twitter.com/${config?.tenantTwitterPageID}/`,
      iconName: 'twitter',
      isActive: !!config?.tenantTwitterPageID,
    },
  ];

  return (
    <>
      <FollowLabel>{__('application.followUs')}</FollowLabel>
      <ul className={clsx('socials', { column })}>
        {socials
          .filter((link) => link.isActive)
          .map(({ iconName, path }) => (
            <SocialItem key={iconName} iconName={iconName} path={path} />
          ))}
      </ul>
    </>
  );
};
export default SocialsPanel;
