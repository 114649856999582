import {Form, IsRequired, MinLength} from "itf-validation/dist";
import {ActionType} from "../ranking";

@Form({ post: 'ranking/add-config' })
export class AddRankingConfigDto {
    constructor () {
        const formName: string = 'AddRankingConfigDto'
    }

    @IsRequired()
    actionType: ActionType;

    @IsRequired()
    rankingType: string;

    @IsRequired()
    points: number;

    @MinLength(0)
    @IsRequired()
    tenantId: string;

    @MinLength(0)
    specialActionId?: string;

    @MinLength(0)
    numOfRegularAction?: number;
}
