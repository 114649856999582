import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableSchema, TableSchemaField } from 'app-tables';
import { SingleColumnsConfig } from 'app-types';
import { Spinner } from '../../../components/Common';
import Table from '../Table';
import debounce from '../../../utils/debouce';
import { apiTable } from '../../../actions';
import SearchBar from '../../Common/SearchComponent';
import ApiService from '../../../services/api-service';

interface Props {
  fetchApiTableData: (url: string, method?: string) => void;
  clearApiTable: () => void;
  loading: boolean;
  data: any;
  scheme: TableSchema;
  apiEndpointSubUrl: string;
  apiEndpointMethod?: string; // Defaults to GET
  fields?: any;
  noSearch?: boolean;
  highlight?: (row: any) => boolean;
  dehighlight?: (row: any) => boolean;
  useNaming?: string;
  tenantId: string;
}
class ApiTable<T> extends Component<Props> {
  private debouncedFetch: () => void;

  state = {
    searchQuery: '',
    searchBy: '',
    page: 1,
    sortBy: '',
    sortDirBack: false,
    loading: true,
    customColumnsConfig: {},
    data: {
      countTotal: 0,
      items: [],
    },
  };

  constructor(props: Props) {
    super(props);
    this.debouncedFetch = debounce(this.fetch, 400);
    const { scheme } = props;
    const defaultSearchField = scheme.fields.find(
      (field) => ['string', 'number'].indexOf(typeof field.defaultSearch) > -1,
    );
    const currSort = this.getCurrentSort();
    if (defaultSearchField) {
      this.state.searchBy = defaultSearchField.field;
      this.state.searchQuery = String(defaultSearchField.defaultSearch);
    }
    if (currSort) {
      this.state.sortBy = currSort.sortBy;
      this.state.sortDirBack = currSort.sortDirBack;
    }
  }

  private async fetch() {
    const { fetchApiTableData, apiEndpointMethod } = this.props;
    await fetchApiTableData(this.buildUrl(), apiEndpointMethod);
  }

  private getCurrentSort(): {
    sortBy: string;
    sortDirBack: boolean;
  } {
    const { sortBy, sortDirBack } = this.state;
    const { scheme } = this.props;
    if (sortBy !== '') {
      return {
        sortBy,
        sortDirBack,
      };
    } else {
      const field = scheme.fields.find((f) => f.defaultSort === true) as TableSchemaField;
      if (!field)
        return {
          sortBy: '',
          sortDirBack: false,
        };
      return {
        sortBy: field.field,
        sortDirBack: field.oppositeSortDir !== true,
      };
    }
  }

  private getNames() {
    const { useNaming, tenantId } = this.props;
    ApiService.callFetch(
      'GET',
      `column-config/${tenantId}/${useNaming}`,
      (data: SingleColumnsConfig) => {
        this.setState({
          customColumnsConfig: data,
        });
      },
    );
  }

  componentDidMount() {
    const { apiEndpointSubUrl, useNaming, noSearch } = this.props;

    if (useNaming) {
      this.getNames();
    }

    if(!noSearch) {
      try {
        const storageSearchBy = localStorage.getItem(`${apiEndpointSubUrl}_searchBy`);
        const storageSearchQuery = localStorage.getItem(`${apiEndpointSubUrl}_searchQuery`);
        const storageSortBy = localStorage.getItem(`${apiEndpointSubUrl}_sortBy`);
        const storageDirBack = JSON.parse(
          localStorage.getItem(`${apiEndpointSubUrl}_sortDirBack`) || 'false',
        );
        const storagePage = JSON.parse(localStorage.getItem(`${apiEndpointSubUrl}_page`) || '0');

        if (storageSearchBy) {
          this.setState({ searchBy: storageSearchBy });
        }
        if (storageSearchQuery) {
          this.setState({ searchQuery: storageSearchQuery });
        }
        if (storageSortBy) {
          this.setState({ sortBy: storageSortBy });
        }
        if (storageDirBack) {
          this.setState({ sortDirBack: storageDirBack });
        }
        if (storagePage) {
          this.setState({ page: storagePage });
        }

      } catch (e) {}
    }
    this.fetch();
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = this.props;

    if (data.uuid !== prevProps.data.uuid) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { clearApiTable } = this.props;
    clearApiTable();
  }

  handlePageChange = (newPage: number) => {
    const { page } = this.state;
    const { apiEndpointSubUrl } = this.props;
    if (page === newPage) return;
    localStorage.setItem(`${apiEndpointSubUrl}_page`, JSON.stringify(newPage));

    this.setState(
      {
        page: newPage,
      },
      this.fetch,
    );
  };

  handleSortChange = (sortBy: string, sortDirBack: boolean) => {
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();

    if (currSort.sortBy === sortBy && currSort.sortDirBack === sortDirBack) return;

    localStorage.setItem(`${apiEndpointSubUrl}_sortBy`, sortBy);
    localStorage.setItem(`${apiEndpointSubUrl}_sortDirBack`, JSON.stringify(sortDirBack));

    this.setState(
      {
        sortBy,
        sortDirBack,
      },
      this.fetch,
    );
  };

  private buildUrl(): string {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy, searchQuery, page, sortBy, sortDirBack } = this.state;
    return `${apiEndpointSubUrl}?${new URLSearchParams({
      searchBy,
      searchQuery,
      page,
      sortBy,
      sortDirBack,
    } as any)}`;
  }

  handleSearchChange = (searchBy: string, searchQuery: string) => {
    const { searchBy: searchByNow, searchQuery: searchQueryNow } = this.state;
    const { apiEndpointSubUrl } = this.props;
    if (searchBy === searchByNow && searchQuery === searchQueryNow) return;

    const newSearchQuery = searchBy === searchByNow ? searchQuery : '';

    localStorage.setItem(`${apiEndpointSubUrl}_searchBy`, searchBy);
    localStorage.setItem(`${apiEndpointSubUrl}_searchQuery`, newSearchQuery);
    localStorage.setItem(`${apiEndpointSubUrl}_page`, JSON.stringify(0));

    this.setState(
      {
        page: 1,
        searchBy,
        searchQuery: newSearchQuery,
      },
      this.debouncedFetch,
    );
  };

  render() {
    const { page, searchQuery, searchBy, customColumnsConfig } = this.state;
    const { loading, data, scheme, noSearch, highlight, dehighlight } = this.props;
    let table;
    if (loading) {
      table = <Spinner overlay transparent />;
    } else {
      table = (
        <Table
          data={data}
          scheme={scheme}
          initialPage={page}
          sort={this.getCurrentSort()}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSortChange}
          highlight={highlight}
          dehighlight={dehighlight}
          page={page}
          customColumnsConfig={customColumnsConfig}
        />
      );
    }
    return (
      <>
        {data &&
          !noSearch &&
          (data.countTotal > 0 || searchQuery) &&
          SearchBar(
            scheme.fields,
            {
              searchBy,
              searchQuery,
            },
            null,
            this.handleSearchChange,
          )}
        <div className="api-table">
          <div className="api-table-wrapper">{table}</div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  data: state.apiTable,
  loading: state.apiTable.loading,
  tenantId: state.platformConfig?.config?._id,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { fetchApiTableData: apiTable.fetchApiTableData, clearApiTable: apiTable.clearApiTable },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ApiTable);
