import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ButtonsContainer } from '../../../components/Layout';
import { Button } from '../../../components/Common';
import ModalContent from '../../../components/Common/Modal/ModalContent';
import ModalTitle from '../../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../../components/Common/Modal/ModalHeader';
import { modal } from '../../../actions';

interface Props {
  text: string;
  confirmCallback: () => void;
  hideModal: () => void;
  children?: React.ReactNode;
  cancelCallback?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

const Confirmation = ({
  text,
  confirmCallback,
  hideModal,
  children,
  cancelCallback,
  confirmLabel,
  cancelLabel,
}: Props) => {
  const handleConfirmation = () => {
    hideModal();
    confirmCallback();
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.confirmation" />
      </ModalHeader>
      <p className="confirmation-paragraph">{text}</p>
      {children}
      <ButtonsContainer>
        <Button
          small
          onClick={cancelCallback || hideModal}
          text={cancelLabel || 'application.no'}
          variant="secondary"
        />
        <Button small onClick={handleConfirmation} text={confirmLabel || 'application.yes'} />
      </ButtonsContainer>
    </ModalContent>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ hideModal: modal.hideModal }, dispatch);

export default connect(null, mapDispatchToProps)(Confirmation);
