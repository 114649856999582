import { FieldType, TableSchema } from '../types';

const adPlaceToString: {
  [key: number]: string;
} = {
  0: 'Dół strony',
  1: 'Góra strony',
};

const adStatusToString: {
  [key: number]: string;
} = {
  0: 'Nieaktywna',
  1: 'Aktywna',
};

export const adTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'ad.link',
      field: 'link',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'ad.dateFrom',
      field: 'dateFrom',
      searchable: false,
      sortable: false,
      type: FieldType.Date,
    },
    {
      name: 'ad.dateTo',
      field: 'dateTo',
      searchable: false,
      sortable: false,
      type: FieldType.Date,
    },
    {
      name: 'ad.place',
      field: 'place',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
      customValue: (value) => adPlaceToString[value.place],
    },
    {
      name: 'ad.status',
      field: 'status',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
      customValue: (value) => adStatusToString[value.status],
    },
  ],
};
