import { CustomSelectOption, MessageType, SelectOption } from 'app-types';

interface SetCommunicationData {
  type: 'SET_COMMUNICATION_DATA';
  payload: Communication;
}

interface ClearCommunicationData {
  type: 'CLEAR_COMMUNICATION_DATA';
}

type Action = SetCommunicationData | ClearCommunicationData;
type View =
  | MessageType.Mail
  | MessageType.SMS
  | MessageType.Push
  | MessageType.FB
  | MessageType.Linkedin
  | 'planned'
  | 'history'
  | 'schemas';

export interface Communication {
  view?: View;
  emailTitle?: string;
  emailContent?: string;
  emailReceivers?: SelectOption[] | null;
  emailDate?: Date;
  smsContent?: string;
  smsReceivers?: any[] | null;
  smsDate?: Date;
  pushTitle?: string;
  pushContent?: string;
  pushData?: any;
  pushReceivers?: SelectOption[] | null;
  schemaTitle?: string;
  schemaContent?: string;
  schemaType?: CustomSelectOption<MessageType>;
}

export const defaultEmail = {
  emailTitle: '',
  emailContent: '',
  emailReceivers: null,
  emailDate: undefined,
};

export const smsDefault = {
  smsContent: '',
  smsReceivers: null,
  smsDate: undefined,
};

export const pushDefault = {
  pushTitle: '',
  pushContent: '',
  pushData: '',
  pushReceivers: null,
};

const initialState: Communication = {
  view: MessageType.Mail,
  ...defaultEmail,
  ...smsDefault,
  ...pushDefault,
  schemaTitle: '',
  schemaContent: '',
  schemaType: {
    value: MessageType.Mail,
    label: 'Szablon email',
  },
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_COMMUNICATION_DATA': {
      return { ...state, ...action.payload };
    }
    case 'CLEAR_COMMUNICATION_DATA': {
      return initialState;
    }
    default:
      return state;
  }
};
