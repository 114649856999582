import React from 'react';
import './ModalTitle.scss';
import { __ } from '../../../../helpers/i18n';

interface Props {
  title: string;
}

const ModalTitle = ({ title }: Props) => <h2 className="modal-title">{__(title)}</h2>;

export default ModalTitle;
