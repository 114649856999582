import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { Button } from '..';

interface QuizButtonProps {
  children?: React.ReactNode;
  isPhoto?: boolean;
  onClick?: () => void;
  checked: boolean;
  isCorrect: boolean;
  isWrong: boolean;
}

export const QuizButton: FC<QuizButtonProps> = ({
  children,
  checked,
  onClick,
  isCorrect,
  isWrong,
}) => {
  const { colors } = useTheme();
  const { colorSuccess, colorDanger, colorPrimary, colorDisabled } = colors;
  const getColor = () => {
    if (isCorrect) {
      return colorSuccess;
    }
    if (isWrong) {
      return colorDanger;
    }

    if (checked) {
      return colorPrimary;
    }
    return colorDisabled;
  };

  return (
    <Button
      width="100%"
      minHeight={['30vw', null, null, '300px']}
      backgroundColor="colorLight"
      color="colorDark"
      border="3px solid"
      fontSize="1.4rem"
      textAlign="center"
      justifyContent="center"
      overflowX="auto"
      onClick={onClick}
      borderColor={getColor()}
      padding={0}
      pb={0}
      pt={0}
      pl={0}
      pr={0}
    >
      {children}
    </Button>
  );
};
