import React, { CSSProperties, FC, ReactNode } from 'react';
import { MdDelete } from 'react-icons/md';
import {  useTheme } from 'styled-components';
import { IconActionButton } from '..';

interface PreviewIconProps {
  action: () => void;
  styleProps?: CSSProperties;
  icon?: ReactNode;
}



// eslint-disable-next-line import/prefer-default-export
export const RemoveIcon: FC<PreviewIconProps> = ({ action, styleProps, icon, }) => {
  const { colors } = useTheme();
  return (
    <IconActionButton {...styleProps} onClick={action} bgColor={colors.colorDanger} title="application.delete">
      {icon ?? <MdDelete size={18} color="#fff" />}
    </IconActionButton>
  );
};
