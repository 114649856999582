import React, { FC } from 'react';
import styled from 'styled-components';

import { Icon, Text } from '..';
import { Row, Column } from '../../Layout';
import { __ } from '../../../helpers/i18n';
import { gtMedia } from '../../../config/config';

const StyledContentWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing}/2)`};
  text-decoration: none;
  align-content: center;
  position: relative;
  justify-content: space-between;

  ${gtMedia('sm')} {
    flex: 1 1 25%;
  }
`;

const StyledBox = styled.div<Props>`
  height: 60px;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  background: ${({ backgroundColor }) => backgroundColor};
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing} * 2)`};
`;

interface Props {
  number?: number;
  icon?: string;
  description?: string;
  backgroundColor: string;
}
// eslint-disable-next-line import/prefer-default-export
export const StatisticsBox: FC<Props> = ({ number, description, icon, backgroundColor }) => (
  <StyledContentWrapper>
    <StyledBox backgroundColor={backgroundColor}>
      <Row width="80%" justifyContent="flex-start" alignItems="center">
          <Text mb={0} mr={1} width="100%" color="#fff" fontSize={3} fontWeight="bold">
            {number}
          </Text>
          <Text mb={0} color="#fff" fontSize="0.9rem">
            {__(description)}
          </Text>
      </Row>
      <Icon disabled color="#fff" inline big size={40} icon={icon} />
    </StyledBox>
  </StyledContentWrapper>
);
