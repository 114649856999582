import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

export const formatDate = (val: string | Date, withYear = false) => {
  try {
    const d = new Date(val);
    return format(d, withYear ? 'EEEE, do LLLL yyyy H:mm' : 'EEEE, do LLLL H:mm', { locale: pl });
  } catch (e) {
    return '-';
  }
};

export const formatDateClassic = (val: string | Date) => {
  try {
    const d = new Date(val);
    return format(d, 'dd/MM/yyyy', { locale: pl });
  } catch (e) {
    return '-';
  }
};
