/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DashboardElementRes } from 'app-types';
import styled, { css } from 'styled-components';
import { Image } from '../../Common';
import { Row, Column } from '../../Layout';
import StyledColumn from '../HomeNewsDesktop/StyledColumn';
import { gtMedia } from '../../../config/config';
import { ArticleTitle } from '../../Styled/ArticleTitle';
import { RoundedWithShadow } from '../../Styled/CarouselItem';
import {
    getImageLinkForDashboardElement,
    getMainLinkForDashboardElement,
} from '../../../utils/dashboardLink';

interface StyledLinkProps {
    main?: boolean;
    side?: boolean;
    $newsBox?: boolean;
    $last?: boolean;
}

const StyledLink = styled(Link) <StyledLinkProps>`
  height: 175px;
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;

  ${gtMedia('lg')} {
    height: 225px;
  }

  ${({ main }) =>
        main &&
        css`
      height: 365px;
      margin-bottom: 0;
      ${gtMedia('lg')} {
        height: 465px;
      }
    `}
  ${({ side }) =>
        side &&
        css`
      padding: ${({ theme }) => `0 (${theme.mainSpacing} * 0.5)`};
    `}
  ${({ $newsBox }) =>
        $newsBox &&
        css`
      margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
    `}
  ${({ $last }) =>
        $last &&
        css`
      margin-bottom: 0;
    `}
`;

interface Props {
    items: DashboardElementRes[];
    carouselItems?: boolean | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const NewsArticles: FC<Props> = ({ items, carouselItems }) => {
        if (items.length) {
        if (carouselItems) {
            return (
                <>
                    {items.map((item: DashboardElementRes) => (
                        <RoundedWithShadow key={item.id}>
                            <Link to={`${getMainLinkForDashboardElement(item)}`}>
                                <Image src={`${getImageLinkForDashboardElement(item)}`} />
                                <div className="title">{item.title}</div>
                            </Link>
                        </RoundedWithShadow>
                    ))}
                </>
            );
        } else {
            return (
                <StyledColumn side width={[1, 1 / 3]}>
                    <Row>
                        {items.map((item, index) => (
                            <Column width={1} key={item.id}>
                                <StyledLink
                                    $newsBox
                                    $last={index !== 0}
                                    to={`${getMainLinkForDashboardElement(item)}/view/${item.id}`}
                                >
                                    <RoundedWithShadow>
                                        <Image src={`${getImageLinkForDashboardElement(item)}`} />
                                        <ArticleTitle title={item.title} />
                                    </RoundedWithShadow>
                                </StyledLink>
                            </Column>
                        ))}
                    </Row>
                </StyledColumn>
            );
        }
    } else {
        return null;
    }
};
