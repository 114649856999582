import React, { FC } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { gtMedia } from '../../../config/config';

export const StyledContainer = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.mainSpacing};
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxPageWidth};
  &.dark {
    background-color: ${({ theme }) => theme.colors.colorPrimary};
  }

  ${gtMedia('md')} {
    padding: ${({ theme }) => `0 calc(${theme.mainSpacing} * 3)`};
  }
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  mode?: 'light' | 'dark';
  fluid?: boolean;
}
const Container: FC<Props> = ({ children, className, mode, fluid }) => (
  <StyledContainer
    className={clsx({
      [`${className}`]: className,
      dark: mode === 'dark',
      light: mode === 'light',
      fluid,
    })}
  >
    {children}
  </StyledContainer>
);

export default Container;
