import { FieldType, TableSchema } from "../types";

export const rankingTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "ID",
      field: "id",
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: "Imię",
      field: "firstName",
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: "Nazwisko",
      field: "lastName",
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: "Punkty",
      field: "points",
      searchable: false,
      sortable: true,
      type: FieldType.Text,
      defaultSort: true,
    },

  ]
};
