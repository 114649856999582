import { AdditionalFieldRes, FormRes } from 'app-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { useApi } from '../services/useApi';

interface Props {
  myProfile?: string;
}

interface UseAdditionalFields {
  findFormByName: (name: string, isId?: boolean) => void;
  additionalFields: AdditionalFieldRes[];
}

interface FormFields {
  text?: string;
  _id: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useAdditionalFields = (props?: Props): UseAdditionalFields => {
  const [additionalFields, setAdditionalFields] = useState<AdditionalFieldRes[]>([]);
  const [formId, setFormId] = useState<string>();
  const forms: FormFields[] = useSelector((state: RootState) => state.platformConfig.basicForms);
  const { api } = useApi();

  const findFormByName = (name: string, isId?: boolean) => {
    let form: FormFields | undefined;
    if (!isId) {
      const searchedForm = (forms?.length > 0 && forms.find((f) => f.text === name)) || undefined;
      form = searchedForm;
      if (searchedForm) {
        setFormId(searchedForm._id);
      }
    } else {
      const searchedForm = (forms?.length > 0 && forms.find((f) => f._id === name)) || undefined;
      form = searchedForm;
      if (form) {
        setFormId(name);
      }
    }
  };

  const getAdditionalFields = () => {
    api<FormRes[]>({
      method: 'GET',
      path: `form/get-additional-fields/${formId}/${props?.myProfile ? props.myProfile : ''}`,
      onSuccess: (res) => {
        if (res && res?.length > 0) {
          setAdditionalFields(res[0].additionalFields);
        } else setAdditionalFields([]);
      },
    });
  };

  useEffect(() => {
    if (formId) {
      getAdditionalFields();
    }
  }, [formId]);

  return { additionalFields, findFormByName };
};
