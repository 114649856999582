import { Capacitor } from "@capacitor/core";

const getContent = () => {
  return document.getElementById('root');
};

const scrollToTop = () => {
  const content = getContent();
  if (content) {
    content.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

export const scrollTop = () => {
  if (Capacitor.isNativePlatform()) {
    scrollToTop();
  } else {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }
};

export const scrollToElement = (element: HTMLElement | Element | null) => {
  if (!element) return;
  setTimeout(() => {
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.scrollY - 200,
      behavior: 'smooth',
    });
  });
};
