/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DashboardElementRes } from 'app-types';
import styled, { css } from 'styled-components';
import { Image } from '..';
import StyledColumn from '../HomeNewsDesktop/StyledColumn';
import { gtMedia } from '../../../config/config';
import { ArticleTitle } from '../../Styled/ArticleTitle';
import { RoundedWithShadow } from '../../Styled/CarouselItem';
import {
  getImageLinkForDashboardElement,
  getMainLinkForDashboardElement,
} from '../../../utils/dashboardLink';

interface StyledLinkProps {
  main?: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  height: 175px;
  position: relative;
  display: block;
  cursor: pointer;
  height: 365px;
  margin-bottom: 0;
  ${gtMedia('lg')} {
    height: 465px;
  }

  ${({ main }) =>
    main &&
    css`
      height: 365px;
      margin-bottom: 0;
      ${gtMedia('lg')} {
        height: 465px;
      }
    `}
`;

interface Props {
  items: DashboardElementRes[];
  carouserItem?: boolean | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const PromotedArticle: FC<Props> = ({ items, carouserItem }) => {
  if (items.length > 0) {
    if (carouserItem) {
      return (
        <>
          {items.map((item: DashboardElementRes) => (
            <RoundedWithShadow key={item.id}>
              <Link to={`${getMainLinkForDashboardElement(item)}`}>
                <Image src={`${getImageLinkForDashboardElement(item)}`} alt={item.title} />
                <div className="title">{item.title}</div>
              </Link>
            </RoundedWithShadow>
          ))}
          item
        </>
      );
    } else {
      return (
        <>
          {items.map((item: DashboardElementRes) => (
            <StyledColumn key={item.id} side width={[1, 2 / 3]}>
              <RoundedWithShadow>
                <StyledLink to={`${getMainLinkForDashboardElement(item)}`}>
                  <Image src={`${getImageLinkForDashboardElement(item)}`} alt={item.title} />
                  <ArticleTitle bigFont title={item.title} />
                </StyledLink>
              </RoundedWithShadow>
            </StyledColumn>
          ))}
        </>
      );
    }
  } else {
    return null;
  }
};
