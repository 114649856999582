import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  flex-wrap: wrap;
`;

const HeaderContent: FC<Props> = ({ children }) => <StyledHeaderContent>{children}</StyledHeaderContent>;

export default HeaderContent;
