import styled from 'styled-components';
import { gtMedia, ltMedia } from '../../../config/config';

// eslint-disable-next-line import/prefer-default-export
export const StyledForm = styled.form`
  width: 100%;
  position: relative;

  ${ltMedia('md')} {
    .button {
      width: 100%;
    }
  }

  ${gtMedia('md')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
