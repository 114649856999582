import { Email, Form, IsRequired, MinLength } from "itf-validation";

@Form({ post: "user/update-user", edit: "user/my-data" })
export class UpdateUserDto {
  constructor() {
    const formName: string = "UpdateUserDto";
  }

  @IsRequired()
  firstName: string;

  @IsRequired()
  lastName: string;

  @Email()
  email: string;

  @IsRequired()
  @MinLength(5)
  postCode: string;

  @IsRequired()
  @MinLength(9)
  tel: string;
}
