import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { lighten } from 'polished';
import { Icon } from '..';

const StyledContentRemover = styled.button<{ bgColor?: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  outline: none;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, bgColor }) => (!bgColor ? theme.colors.colorInput : bgColor)};
  color: ${({ theme }) => lighten(0.2, `${theme.colors.colorDark}`)};
  border: none;
  font-weight: bold;
  z-index: 9;
  &:hover {
    color: ${({ theme }) => theme.colors.colorDark};
  }
`;

interface ContentRemoverProps {
  handleRemove: () => void;
  bgColor?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const ContentRemover: FC<ContentRemoverProps> = ({ handleRemove, bgColor }) => {
  const { colors } = useTheme();
  return (
    <StyledContentRemover bgColor={bgColor} type="button" title="Usuń video" onClick={() => handleRemove()}>
      <Icon icon="trash" color={colors.colorDanger} />
    </StyledContentRemover>
  );
};
