import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledEditorWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.colorInput}`};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  flex: 0 1 100%;
  margin: 0 ${({ theme }) => theme.mainSpacing};
  margin-bottom: ${({ theme }) => `calc(2 * ${theme.mainSpacing})`};
`;
