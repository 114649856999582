import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '..';
import { ltMedia } from '../../../config/config';
import { usePlatform } from '../../../hooks/usePlatform';

interface Props {
  src: string;
  alt?: string;
  mobileRatio?: number;
  noTopRadius?: boolean;
}

const ImageContainer = styled.div<{ mobileRatio?: number; noTopRadius?: boolean }>`
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  overflow: hidden;
  position: relative;

  ${({ noTopRadius }) =>
    noTopRadius &&
    `
border-top-right-radius: 0;
border-top-left-radius: 0;
`}
  ${({ mobileRatio }) =>
    mobileRatio &&
    css`
      ${ltMedia('md')} {
        width: 100%;
        padding-bottom: ${100 / mobileRatio}%;
      }
    `}
`;

const StyledImage = styled.img<{ mobileRatio?: number }>`
  width: 100%;
  display: block;
  ${({ mobileRatio }) =>
    mobileRatio &&
    css`
      ${ltMedia('md')} {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `}
`;

export const ResponsiveImage = ({ src, mobileRatio, alt, noTopRadius }: Props) => {
  const { tenantQuery, platformId } = usePlatform();
  const [noImage, setNoImage] = useState(false);

  if (noImage) return null;

  if (platformId === '') {
    return <Spinner />;
  }
  return (
    <ImageContainer
      mobileRatio={mobileRatio}
      onError={() => setNoImage(true)}
      noTopRadius={noTopRadius}
    >
      <StyledImage src={`${src}/${tenantQuery}`} mobileRatio={mobileRatio} alt={alt} />
    </ImageContainer>
  );
};
