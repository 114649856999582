import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledFileWrapper = styled.div`
  width: 250px;
  height: 175px;
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.mainSpacing};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  border: ${({ theme }) => `1px dashed ${theme.colors.colorDisabled}`};
`;
