import { FieldType, TableSchema } from '../types';


export const logsArchiveTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: 'Nazwa',
            field: 'name',
            searchable: false,
            sortable: false,
            type: FieldType.Text,
        },
    ],
};
