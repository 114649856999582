import ApiService from '../services/api-service';
import { notifications } from '../actions';
import { __ } from '../helpers/i18n';

export const setUser = (user: any) => ({ type: 'SET_USER', payload: user });

export const logout = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'LOGOUT',
    'LOGOUT',
    () => {
      dispatch(notifications.successNotification(__('application.logoutCorrectly')));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_GRID' });
    },
    null,
    'auth/logout',
  );
};

export const clearUserSession = () => ({ type: 'CLEAR_USER_SESSION' });
 