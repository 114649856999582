import { CustomSelectOption } from '../catalog';
import { SelectOption } from '../form';

export interface TemporaryActionTableRes {
  _id: string;
  title: string;
  createdDt: Date;
  startDt: Date;
  endDt: Date;
  isMainRanking: boolean;
  isDeactivated: boolean;
}

export interface TemporaryActionReq {
  title: string;
  content: string;
  startDt: Date;
  endDt: Date;
  photoFn: any;
  termsFileFn: any;
  isHidden: boolean;
  actionAvailableAfterEnd: boolean;
  actionVisibilityStartDt?: Date;
  actionVisibilityEndDt?: Date;
  displayInGallery: boolean;
  groups: SelectOption[];
  showForAnotherGroup?: SelectOption[];
  acceptType: CustomSelectOption<TemporaryActionAcceptType>;
  isSingleLike?: boolean;
  addPointsToMainRanking: boolean;
  isMainRanking: boolean;
  additionalPointToMainRanking: number;
  actionPlace: SelectOption[];
  allowSuperUserAllAccess?: boolean;
}

export interface TemporaryActionEditRes {
  _id: string;
  title: string;
  content: string;
  startDt: Date;
  endDt: Date;
  photoFn: string;
  termsFileFn: string;
  isHidden: boolean;
  actionAvailableAfterEnd: boolean;
  actionVisibilityStartDt?: Date;
  actionVisibilityEndDt?: Date;
  displayInGallery: boolean;
  isDeactivated: boolean;
  groups: SelectOption[];
  showForAnotherGroup?: SelectOption[];
  ranking?: SelectOption[];
  acceptType: CustomSelectOption<TemporaryActionAcceptType>;
  isSingleLike?: boolean;
  addPointsToMainRanking: boolean;
  isMainRanking: boolean;
  actionPlace: SelectOption[];
  allowSuperUserAllAccess?: boolean;
}

export interface TemporaryActionRes {
  _id: string;
  title: string;
  content: string;
  startDt: Date;
  endDt: Date;
  photoFn: string;
  termsFileFn: string;
  isHidden: boolean;
  actionAvailableAfterEnd: boolean;
  actionVisibilityStartDt?: Date;
  actionVisibilityEndDt?: Date;
  displayInGallery: boolean;
  isDeactivated: boolean;
  groups: SelectOption[];
  showForAnotherGroup?: SelectOption[];
  acceptType: CustomSelectOption<TemporaryActionAcceptType>;
  isSingleLike?: boolean;
  addPointsToMainRanking: boolean;
  isMainRanking: boolean;
  withRealization: boolean;
  withCustomFormFields: boolean;
  actionPlace: SelectOption[];
  allowSuperUserAllAccess?: boolean;
}

export type ViewOrEdit = 'view' | 'edit';

export type TemporaryActionFile = 'photo' | 'terms';

export enum TemporaryActionAcceptType {
  AdminModerator = 'admin_moderator',
  Superuser = 'superuser',
  AdminModeratorSuperuser = 'admin_moderator_superuser',
  LikesByUser = 'likes_by_user',
}

export enum ActionPlace {
  Actions,
  Promoted,
}
