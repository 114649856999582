import { useState } from 'react';
import { DashboardElementRes } from 'app-types';
import { useApi } from '../../services/useApi';

interface UseDashboard {
  items: DashboardElementRes[];
  loading: boolean;
  getNewsSection: () => void;
}

export const useDashboard = (): UseDashboard => {
  const { api, loading } = useApi();
  const [items, setItems] = useState<DashboardElementRes[]>([]);

  const getNewsSection = () => {
    api({
      method: 'GET',
      path: 'dashboard/news-section',
      onSuccess: (data: DashboardElementRes[]) => {
        setItems(data);
      },
    });
  };

  return { items, loading, getNewsSection };
};
