import React, { FC, useEffect } from 'react';
import { AdPlace, UserRole } from 'app-types';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '../../Layout';
import { RootState } from '../../../reducers';
import { SelectTenantToOrder, SuperAdminBoxes, UserBoxes } from '..';
import { BasicStatistics } from '../../../views/Dashboard/Start/BasicStatistics';
import { Ad } from '../Ad';
import { useTenant } from '../../../hooks/useTenant';
import { getTenantActiveModules } from '../../../slices/selectedTenantAndModules';

const UserActions: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.details);
  const platformId = useSelector((state: RootState) => state.platformConfig?.config?._id);

  const isSuperAdmin = user?.role === UserRole.SuperAdmin;

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getTenantActiveModules(platformId))
    }
  }, [platformId])

  return (
    <>
      <section>
        <Container>
          {!isSuperAdmin && <Ad place={AdPlace.PAGE_TOP} />}
          {isSuperAdmin && <SelectTenantToOrder />}
          {isSuperAdmin ? <SuperAdminBoxes /> : <UserBoxes />}
        </Container>
      </section>
      {isSuperAdmin && platformId && <BasicStatistics />}
    </>
  );
};

export default UserActions;
