import { Capacitor } from '@capacitor/core';
import { DashboardElement, DashboardElementRes } from 'app-types';
import ApiService from '../services/api-service';

export const isExternal = (dest: string) => {
    const hostPath = Capacitor.isNativePlatform()
    ? process.env.REACT_APP_HOSTNAME!
    : window.location.origin;

    console.log('CHECKING EXTERNAL', dest, !dest.includes('http://') && !dest.includes('https://'), !dest.includes(hostPath));
    if (!dest.includes('http://') && !dest.includes('https://')) return false;
    return !dest.includes(hostPath);
};


export const getProperPath = (path: string) => {
    const hostPath = Capacitor.isNativePlatform()
    ? process.env.REACT_APP_HOSTNAME!
    : window.location.origin;
    
    if (path.includes(hostPath)) return path
        .replace(hostPath, '')
        .replace('http://', '')
        .replace('https://', '')
    return path;
};

export const getMainLinkForDashboardElement = (element: DashboardElementRes) => {
    let basePath = '';
    switch (element.type) {
        case DashboardElement.ARTICLE:
            basePath = `dashboard/articles/view/${element.id}`;
            break;
        case DashboardElement.TEMPORARY_ACTION:
            basePath = `dashboard/time-actions/view/${element.id}/details`;
            break;
        default:
            basePath = '';
    }

    return getProperPath(basePath);
};

export const getImageLinkForDashboardElement = (element: DashboardElementRes) => {
    let basePath = ';'
    switch (element.type) {
        case DashboardElement.ARTICLE:
            basePath = `${ApiService.url}article/image/articleFn/${element.id}`;
            break;
        case DashboardElement.TEMPORARY_ACTION:
            basePath = `${ApiService.url}temporary-action/file/${element.id}/photo`;
            break;
        default:
            basePath = '';
    }

    return getProperPath(basePath);
};
