import {SelectOption} from "../";

export interface StaticEntity {
  title: string;
  createdDt: Date;
  modifiedDt: Date;
  content: string;
  icon: SelectOption;
  type: StaticType;
  tenantId: string;
  faqContent: FAQReq;
}

export enum StaticType {
  RULES,
  PRIVACY,
  INFO,
  FAQ,
  PRODUCT_CATEGORIES
}

export interface StaticReq {
  content: string;
  title?: string;
  icon?: SelectOption;
}

export interface FAQReq {
  question: string;
  answer: string;
}
