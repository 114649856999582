import styled, { css } from 'styled-components';

interface StyledFileProps {
  isFile?: boolean;
  video?: boolean;
  disabled?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const StyledFile = styled.input<StyledFileProps>`
  opacity: ${({ isFile }) => (!isFile ? '0' : '1')};
  ${({ isFile }) =>
    !isFile &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      top: 20px;
      background-color: #fff;
    `}
`;
