import { FieldType, TableSchema } from "../types";

export const pointsHistorySchema: TableSchema = {
    countPerPage: 10,
    fields: [
        { name: 'Identyfikator', field: '_id', searchable: false, sortable: false, type: FieldType.Text },
        { name: "Email", field: "user.email", searchable: false, sortable: false, type: FieldType.Email },
        { name: "Imię", field: "user.firstName", searchable: false, sortable: false, type: FieldType.Text },
        { name: "Nazwisko", field: "user.lastName", searchable: false, sortable: false, type: FieldType.Text },
        { name: 'Liczba punktów', field: 'points', searchable: false, sortable: false, type: FieldType.Text },
        { name: 'Typ akcji', field: 'actionType', searchable: false, sortable: false, type: FieldType.Text },
        { name: 'Data przydzielenia punktów', field: 'createdDt', searchable: false, sortable: true, type: FieldType.Date, defaultSort: true },
        {
            name: "Ranking",
            field: "rankingConfigName",
            searchable: false,
            sortable: true,
            type: FieldType.Text,
            defaultSort: true,
        }
    ],
};
