import {FieldType, TableSchema} from "../types";

const acceptStatusToString: {
    [key: number]: string
} = {
    0: 'Utworzony',
    1: 'W trakcie',
    2: 'Zakończony',
    3: 'Anulowany',
    4: 'Zablokowany',
    5: 'Usunięty',
};

export const reportContestTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "_id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "startDt",
            field: "startDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
            defaultSort: true
        },
        {
            name: "endDt",
            field: "endDt",
            searchable: true,
            sortable: true,
            type: FieldType.Date,
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "address",
            field: "address",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "contestStatus",
            field: "contestStatus",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            customValue: value => acceptStatusToString[value.contestStatus],
        }
    ]
};
