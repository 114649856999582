/* eslint-disable import/prefer-default-export */
import { ArticleCommentReq } from 'app-types';
import { useDispatch } from 'react-redux';

import { useApi } from '../../services/useApi';
import { successNotification } from '../../actions/notifications';
import { __ } from '../../helpers/i18n';

interface UseCommentForm {
  loading: boolean;
  submit: (
    content: ArticleCommentReq,
    articleId: string,
    editId?: string,
    commentParentId?: string,
    callback?: () => void,
  ) => void;
  deleteComment: (
    articleId: string,
    commentId: string,
    commentParentId?: string,
    callback?: () => void,
  ) => void;
}

export interface ContentWysiwyg {
  type: string;
  data: string;
}

export const useCommentForm = (): UseCommentForm => {
  const dispatch = useDispatch();
  const { api, loading } = useApi();

  const submit = (
    content: ArticleCommentReq,
    articleId: string,
    editId?: string,
    commentParentId?: string,
    callback?: () => void,
  ) => {
    api({
      method: editId ? 'PATCH' : 'POST',
      path: editId
        ? `article/edit-comment/${articleId}/${editId}/${commentParentId || ''}`
        : `article/add-comment/${articleId}/${commentParentId || ''}`,
      onSuccess: () => {
        if (editId) {
          dispatch(successNotification(__('article.commentModified')));
        } else {
          dispatch(successNotification(__('article.commentAdded')));
        }
        if (typeof callback === 'function') callback();
      },
      data: content,
    });
  };

  const deleteComment = (
    commentId: string,
    articleId: string,
    commentParentId?: string,
    callback?: () => void,
  ) => {
    api({
      method: 'DELETE',
      path: `article/delete-comment/${articleId}/${commentId}/${commentParentId || ''}`,
      onSuccess: () => {
        dispatch(successNotification(__('article.commentRemoved')));
        if (typeof callback === 'function') callback();
      },
    });
  };

  return {
    submit,
    deleteComment,
    loading,
  };
};
