import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RealizationStatus, RealizationTableRes } from 'app-types';
import { formatDate } from '../../../utils/formatDate';
import Card from '../Card';

interface CardProps {
  item: RealizationTableRes;
}

const statusToString: {
  [key: number]: string;
} = {
  [RealizationStatus.NoAction]: 'Zgłoszenie oczekujące',
  [RealizationStatus.Accepted]: 'Zgłoszenie zaakceptowane',
  [RealizationStatus.Rejected]: 'Zgłoszenie odrzucone',
  [RealizationStatus.Delivered]: 'Zgłoszenie dostarczone',
};

const StyledCardStatus = styled.div<{ status: RealizationStatus }>`
  padding: 10px;
  text-align: center;
  ${({ status }) => {
    switch (status) {
      case RealizationStatus.Accepted:
        return css`
          background-color: ${({ theme }) => theme.colors.colorSuccess};
          color: #fff;
        `;
      case RealizationStatus.Rejected:
        return css`
          background-color: ${({ theme }) => theme.colors.colorDanger};
          color: #fff;
        `;
      case RealizationStatus.Delivered:
        return css`
          background-color: ${({ theme }) => theme.colors.colorSecondary};
          color: #fff;
        `;
      default:
        return css`
          background-color: #fff;
        `;
    }
  }}
`;

export const RealizationCard: FC<CardProps> = ({ item }) => (
  <Card
    key={item._id}
    title={formatDate(item.createdDt, true)}
    extraText={item.temporaryActionTitle}
    imgUrlRaw={item.photosFn[0]}
    to={`/dashboard/realizations/view/${item._id}`}
    content={
      <StyledCardStatus status={item.status}>{statusToString[item.status]}</StyledCardStatus>
    }
  />
);
