import React, { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Select, { GroupedOptionsType, OptionsType } from 'react-select';
import { __ } from '../../../helpers/i18n';
import { inputSelectStyles } from '../../../helpers/additionalStyles';
import { InputLabel } from '..';

interface BaseInputProps {
  name: string;
  required?: boolean;
  isDisabled?: boolean;
  label?: string;
}

const SelectContainer = styled.div`
  position: relative;
`;

interface Props extends BaseInputProps {
  options?:
    | any
    | OptionsType<{ value: any; label: string }>
    | GroupedOptionsType<{ value: any; label: string }>;
  handleChange?: (selected?: any) => void;
  handleCreate?: any;
  isMulti?: boolean;
  isCreatable?: boolean;
  defaultValue?: any | { value: any; label: string } | null;
  id?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const FormSelect: FC<Props> = ({
  handleChange,
  options,
  required,
  isDisabled,
  name,
  isMulti,
  isCreatable,
  defaultValue,
  handleCreate,
  label,
  id,
}) => {
  const { control, trigger } = useFormContext();

  return (
    <SelectContainer>
      {label && <InputLabel name={name} required={required} text={label} />}
      <Controller
        rules={{ required }}
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field, ...fieldProps }) => {
          if (handleCreate || isCreatable) {
            return (
              <CreatableSelect
                id={id}
                menuPortalTarget={document.body}
                // menuPlacement="bottom"
                {...fieldProps}
                styles={inputSelectStyles()}
                isMulti={isMulti}
                noOptionsMessage={() => __('application.noOptions')}
                isDisabled={isDisabled}
                onChange={(e) => {
                  field.onChange(e);
                  // eslint-disable-next-line no-unused-expressions
                  handleChange?.(e);
                  trigger();
                }}
                onCreateOption={(e: any) => {
                  if (field?.value && !handleCreate) {
                    field.onChange([...field.value, { value: e, label: e, __isNew__: true }]);
                  } else if (!handleCreate) {
                    field.onChange([{ value: e, label: e, __isNew__: true }]);
                  }
                  // eslint-disable-next-line no-unused-expressions
                  handleCreate?.(e);
                }}
                options={options ?? []}
                formatCreateLabel={(option: any) => `${__('application.create')} "${option}"`}
                placeholder={__('application.choose')}
                value={field.value}
              />
            );
          } else {
            return (
              <Select
                menuPortalTarget={document.body}
                // menuPlacement="bottom"
                id={id}
                {...fieldProps}
                styles={inputSelectStyles()}
                isMulti={isMulti}
                noOptionsMessage={() => __('application.noOptions')}
                options={options ?? []}
                isDisabled={isDisabled}
                name={name}
                defaultValue={defaultValue}
                onChange={(e) => {
                  field.onChange(e);
                  // eslint-disable-next-line no-unused-expressions
                  handleChange?.(e);
                }}
                placeholder={__('application.choose')}
                value={field.value}
                isClearable={!required}
              />
            );
          }
        }}
      />
    </SelectContainer>
  );
};
