import React, { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { modal } from '../../../actions';
import { editorJsTools } from '../../../config/editorJs-tools';
import { __ } from '../../../helpers/i18n';
import { useStaticSites } from '../../../hooks/useStaticSites';
import ApiService from '../../../services/api-service';
import { setPrivacyAccepted } from '../../../slices/userSlice';
import { Button, EditorJsContainer, Spinner } from '../../Common';
import ModalContent from '../../Common/Modal/ModalContent';
import ModalHeader from '../../Common/Modal/ModalHeader';
import ModalTitle from '../../Common/Modal/ModalTitle';
import { ButtonsContainer } from '../../Layout';
import { StyledWysiwyg } from '../../Styled';

const PrivacyPolicyAccept: FC = () => {
  const { staticContent, getContent, platformId } = useStaticSites();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getContent('static/privacy');
  }, []);

  const acceptPrivacy = async () => {
    setLoading(true);
    await ApiService.callFetch('PATCH', 'user/accept-privacy-policy', (accepted: boolean) => {
      if (accepted) {
        dispatch(modal.hideModal());
        dispatch(setPrivacyAccepted(true));
      }
    });
    setLoading(false);
  };

  if (!staticContent) return <Spinner />;

  return (
    <ModalContent wide>
      <ModalHeader>
        <ModalTitle title="application.privacy_policy" />
      </ModalHeader>

      <p>{__('application.privacy_accept_required')}</p>
      <StyledWysiwyg preview>
        <EditorJsContainer
          value={staticContent?.content && JSON.parse(staticContent.content)}
          autofocus={false}
          readOnly
          tools={editorJsTools({ _id: platformId })}
        />
      </StyledWysiwyg>

      <ButtonsContainer justifyContent="center">
        {loading && <Spinner overlay />}
        <Button type="button" text="application.i_accept_privacy" onClick={acceptPrivacy} />
      </ButtonsContainer>
    </ModalContent>
  );
};

export default PrivacyPolicyAccept;
