import { FieldType, TableSchema } from "../types";

export const contestParticipantTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "Identyfikator",
      field: "_id",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "Nazwisko",
      field: "lastName",
      searchable: true,
      sortable: true,
      defaultSort: true,
      type: FieldType.Text
    },
    {
      name: "Imię",
      field: "firstName",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "Data i godzina wejścia",
      field: "scannedDt",
      searchable: false,
      sortable: true,
      type: FieldType.Date
    },
    {
      name: "Data i godzina otwarcia kodu",
      field: "openedCodeDt",
      searchable: false,
      sortable: true,
      type: FieldType.Date
    },
    {
      name: "Zeskanowany",
      field: "usedCode",
      searchable: true,
      sortable: true,
      type: FieldType.Boolean
    },
    {
      name: "Uczestnik",
      field: "participants",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "Email skanującego",
      field: "scanningPerson.email",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    }
  ]
};
