import { UserCart } from "../user";
import { SelectOption } from "../form";

export enum CostType {
    Points,
    Money,
    Mixed,
    Info,
    PointsWithoutDeduction
}

export interface CatalogCategoryData {
    title: string;
    forGroups?: SelectOption[];
    description?: string;
}

export interface CatalogCategoryReq {
    body: CatalogCategoryData;
    categoryFn: string;
}

export interface CatalogProductReq {
    body: CatalogProductData;
    productFn: string;
}

export interface CatalogProductData {
    name: string;
    content?: string;
    price?: number;
    pointsPrice?: number;
    costType?: CustomSelectOption<CostType>;
    canBeExchanged: boolean;
    quantityType: CustomSelectOption<QuantityType>;
    quantityInStock?: number;
    productCategory: SelectOption[];
    responsiblePersonEmail?: string;
    productCode: string;
    externalUrl?: string;
    parameters: ProductParameter[];
    companyContactInfo?: CompanyContactInfo;
}

export interface ProductParameter {
    name: string;
    value: string;
}

export interface CatalogProductTableRes {
    _id: string;
    createdDt: Date;
    name: string;
    content: string;
    price: number;
    pointsPrice: number;
    costType: CustomSelectOption<CostType>;
    productCategory: SelectOption[];
    canBeExchanged: boolean;
    quantityInStock?: number;
    currency: string;
    shortDescription: string;
    responsiblePersonEmail: string;
    alreadyBought: number;
    limitPerPerson: number;
}

export interface CatalogCategoryRes {
    _id: string;
    title: string;
    description: string;
    createdDt: Date;
    tenantId: string;
    categoryFn: string;
    addTileOverlay: boolean;
}

export interface CatalogCategoryFullRes extends CatalogCategoryRes {
    forGroups?: SelectOption[];
}

export interface CatalogProductRes {
    _id: string;
    name: string;
    description?: string;
    content: string;
    productFn: string;
    price: number;
    pointsPrice: number;
    costType: CustomSelectOption<CostType>;
    productCategory: SelectOption[];
    canBeExchanged: boolean;
    createdDt: Date;
    productCode: string;
    quantityType: CustomSelectOption<QuantityType>;
    quantityInStock?: number;
    responsiblePersonEmail: string;
    tenantId: string;
    externalUrl?: string;
    parameters: ProductParameter[];
    currency: string;
    companyContactInfo?: CompanyContactInfo;
    limitPerPerson?: number;
}

export interface CatalogProductFull extends CatalogProductRes {
    forGroups: SelectOption[];
}

export interface CatalogCategoryTableRes {
    _id: string;
    createdDt: Date;
    title: string;
}

export enum QuantityType {
    UntilLastProduct,
    NoLimit,
    Custom,
}

export interface UserCodesTableRes {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    tel: string;
    order: string;
}

export type CustomSelectOption<T> = {
    value: T,
    label: string;
}

export interface OrderQuantityReq {
    quantity: number;
    productId: string;
}

export enum PaymentStatus {
    Pending,
    Accepted,
    Canceled,
    Chargeback,
}

export interface CompanyContactInfo {
    name: string;
    address: string;
    email: string;
}

export interface CartRes {
    cartPrice: number;
    cartPointsPrice: number;
    items: CartProduct[];
}

export interface CatalogOrderTableRes {
    createdDt: Date;
    user: CatalogUserType;
    order: CatalogOrderType;
    tenantId: string;
}

export interface CatalogUserType {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    tel: string;
}

export interface CatalogOrderType {
    _id: string;
    currency: string;
    responsiblePersonEmail: string;
    productCode: string;
    productCategory: SelectOption[];
    pointsPrice: number;
    price: number;
    content: string;
    name: string;
    quantityInCart: number;
    sumOfPointsPrice: number;
    sumOfPrice: number;
    isUsed: boolean;
    uniquerProductCode: string;
    tenantId: string;
}

export enum PaidStatus {
    NoPaid,
    Paid,
    Canceled
}

export interface ProcSpecData {
    status: PayUStatusCode;
    redirectUri: string;
    orderId: string;
    extOrderId: string;
}

export interface PayUStatusCode {
    statusCode: string;
}

export interface CatalogOrderItem {
    currency?: string;
    responsiblePersonEmail: string;
    productCode: string;
    productCategory: SelectOption[];
    pointsPrice: number;
    price: number;
    _id: string;
    name: string;
}

export interface CatalogOrderUser {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    tel: string;
}

export interface CartProduct {
    id: string;
    name: string;
    quantity: number;
    price: number;
    pointsPrice: number;
    currency?: string;
}