import { Capacitor } from '@capacitor/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ResponsiveImage } from '..';
import { AdListDetails, AdPlace, PlatformFullConfig } from 'app-types';
import { apiUrl } from '../../../config/apiUrl';
import { RootState } from '../../../reducers';
import ApiService from '../../../services/api-service';
import { getProperPath, isExternal } from '../../../utils/dashboardLink';

interface Props {
    place: AdPlace;
}

const AdsContainer = styled.div`
  margin: ${({ theme }) => `calc(2 * ${theme.mainSpacing}) 0`};
`;

export const Ad = ({ place }: Props) => {
    const { ads } = useSelector((state: RootState) => state.platformConfig?.config as PlatformFullConfig);

    const handleClick = (id: string) => {
        ApiService.callFetch('PATCH', `ad/ad-click/${id}`);
    };

    const getContent = (ad: AdListDetails) => (
        <ResponsiveImage
            alt={ad.title}
            mobileRatio={15 / 5}
            src={`${apiUrl}ad/ad-photo/imageFn/${ad._id}`}
        />
    );

    if (!ads) return null;

    const adsForPlace: AdListDetails[] = ads.filter(
        (ad: AdListDetails) => Number(ad.place) === place,
    );

    if (!adsForPlace || adsForPlace.length === 0) return null;

    return (
        <AdsContainer>
            {adsForPlace.map((ad) =>
                isExternal(ad.link) ? (
                    <a key={ad._id} target="__blank" href={getProperPath(ad.link)} onClick={() => handleClick(ad._id)}>
                        {getContent(ad)}
                    </a>
                ) : (
                    <Link
                        key={ad._id}
                        onClick={() => handleClick(ad._id)}
                        to={getProperPath(ad.link)}
                    >
                        {getContent(ad)}
                    </Link>
                ),
            )}
        </AdsContainer>
    );
};
