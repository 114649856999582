import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => `0 calc(${theme.mainSpacing} * -0.5)`};
`;

const ActionsBoxesContainer: FC<Props> = ({ children }) => <StyledDiv>{children}</StyledDiv>;

export default ActionsBoxesContainer;
