import {FieldType, TableSchema} from "../types";

const acceptStatusToString: {
    [key: number]: string
} = {
    0: 'created',
    1: 'in_progress',
    2: 'ended',
    3: 'cancelled',
    4: 'blocked',
    5: 'deleted',
};

export const contestTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "createdDt",
            field: "createdDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date,
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "status",
            field: "contestStatus",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            customValue: value => acceptStatusToString[value.contestStatus],
        }
    ]
};
