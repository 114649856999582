import { ArticleCommentRes } from './comment';
import { ApiResult } from '../apiMessages';
import { SelectOption } from '../form';
import {QuizType} from "../quiz";

export enum ArticleStatus {
  UNPUBLISHED,
  PUBLISHED,
}

export interface ArticleReq {
  body: ArticleBody;
  content: OutputData;
}

export interface ArticleBody {
  title: string;
  comments: ArticleCommentRes[];
  disableComments: boolean;
  articleFn: string;
  articleCategory: SelectOption[];
  articlePlace: SelectOption[];
  articleQuiz: SelectOption[];
  videoFn?: string;
  videoTitle?: string;
  videoOnBottom?: boolean;
  videoDeleted?: boolean;
  quizId?: string;
}

export type BlockToolData<T extends object = any> = T;

export interface OutputBlockData {
  type: string;
  data: BlockToolData;
}
export interface OutputData {
  version?: string;
  time?: number;
  blocks: OutputBlockData[];
}

export interface ArticleRes {
  _id: string;
  title: string;
  createdDt: Date;
  content: any;
  articleFn: string;
  disableComments: boolean;
  isPublished: boolean;
  articleCategory: SelectOption[];
  articlePlace: SelectOption[];
  articleQuiz: SelectOption[];
  comments: ArticleCommentRes[];
  tenantId: string;
  forGroups: SelectOption[] | null;
  videoFn?: string;
  videoTitle?: string;
  videoOnBottom?: boolean;
  videoPrvLink?: string;
  videoStatus?: VideoStatus;
  quizId?: string;
  quizType: QuizType;
  status: ArticleStatus;
}

export interface ArticleListRes {
  _id: string;
  title: string;
  articleFn: string;
  videoStatus?: VideoStatus;
  videoUri?: string;
  quizId?: string;
}

export interface ArticleTableRes {
  _id: string;
  title: string;
  createdDt: Date;
  videoStatus?: VideoStatus;
  videoUri?: string;
}

export enum ArticlePlace {
  News,
  KnowledgeBase,
  Promoted,
  Standalone,
  Articles,
}

export enum VideoStatus {
  In_Progress,
  Complete,
  Error,
}

export interface SinglePhotoLinkRes {
  success: number;
  file: {
    url: string;
  };
}

export interface MultiplePhotoLinkRes {
  result: ApiResult;
  payload: MultiplePhotoPayload[];
}

export interface MultiplePhotoPayload {
  _id: string;
  link: string;
}

export interface CheckVideoRes {
  status: VideoStatus;
}
