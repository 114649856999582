import {FieldType, TableSchema} from "../types";

const messageStatusToString: {
    [key: number]: string
} = {
    0: 'Email',
    1: 'SMS',
    2: 'Push'
};

const destinationToString: {
    [key: number]: string
} = {
    0: 'Registration',
    1: 'Invitation',
    2: 'AddToContest',
    3: 'ProfileAccepted',
    4: 'ProfileRejected',
};

export const templateTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "Identyfikator",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Data utworzenia",
            field: "createdDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date,
        },
        {
            name: "Tytuł",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Typ wiadomości",
            field: "messageType",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            customValue: value => messageStatusToString[value.messageType],
        },
        {
            name: "Miejsce docelowe",
            field: "destination",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            customValue: value => destinationToString[value.destination],
        }
    ]
};
