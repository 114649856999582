import { FieldType, TableSchema } from '../types';

// order was changed so disabled is last
const acceptStatusToString: {
  [key: number]: { order: number, value: string };
} = {
  0: { value: 'Oczekujący', order: 1 },
  1: { value: 'Zaakceptowany', order: 2 },
  2: { value: 'Odrzucony', order: 3 },
  3: { value: 'Zablokowany', order: 5 },
  4: { value: 'Nieaktywny', order: 4 },
};

const roleToString: {
  [key: number]: string;
} = {
  0: 'Superadmin',
  1: 'Administrator',
  2: 'Moderator',
  3: 'Superuser',
  4: 'User',
};

export const usersTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'Identyfikator',
      field: 'id',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'Imię',
      field: 'firstName',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'Nazwisko',
      field: 'lastName',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'Email',
      field: 'email',
      searchable: true,
      sortable: true,
      type: FieldType.Email,
    },
    {
      name: 'Tel',
      field: 'tel',
      searchable: true,
      sortable: true,
      type: FieldType.Tel,
    },
    {
      name: 'Czy przypisany opiekun',
      field: 'selectedSuperUser',
      searchable: false,
      sortable: true,
      type: FieldType.Boolean,
    },
    {
      name: 'Czy jest aktywny?',
      field: 'isEnabled',
      searchable: false,
      sortable: true,
      type: FieldType.Boolean,
    },
    {
      name: 'Status',
      field: 'isAccepted',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      searchableValues: acceptStatusToString,
      customValue: (value) => acceptStatusToString[value.isAccepted]?.value,
    },
    {
      name: 'Zaimportowany',
      field: 'isImported',
      searchable: false,
      sortable: true,
      type: FieldType.Boolean,
    },
    {
      name: 'Zaakceptował regulamin',
      field: 'rulesAccepted',
      searchable: true,
      sortable: true,
      type: FieldType.Boolean,
    },
    {
      name: 'Rola',
      field: 'role',
      searchable: false,
      sortable: true,
      type: FieldType.Text,
      searchableValues: roleToString,
      customValue: (value) => roleToString[value.role]
    },
    {
      name: 'Zaproszenie',
      field: 'invitationId',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'Data rejestracji',
      field: 'registeredDt',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
  ],
};
