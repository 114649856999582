import React, { FC } from 'react';
import styled from 'styled-components';

import { Icon } from '../../../Common';
import { history } from '../../../../App';

const StyledLink = styled.button`
  color: red;
  font-size: 2.5rem;
  font-weight: 700;
  border: none;
  background: none;
  outline: none;
  text-decoration: none;
  padding: 0;
`;

interface Props {
  path?: string;
}

const HeaderBackArrow: FC<Props> = ({ path }) => {
  const goBack = () => {
    if (history.action !== 'PUSH') history.push('/');
    else if (path) history.push(path);
    else history.goBack();
  };
  return (
    <StyledLink onClick={goBack}>
      <Icon big icon="back-arrow" />
    </StyledLink>
  );
};

export default HeaderBackArrow;
