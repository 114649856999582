import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalField, AdditionalFieldReq } from 'app-types';
import { apiService } from '../services/apiService';

// eslint-disable-next-line no-return-await
interface GetAdditionalFields {
  formId: string;
  tenantId?: string;
}

interface ChangeFieldOrder {
  dragFieldId: string;
  dragFieldOrder: number;
  dropFieldId: string;
  dropFieldOrder: number;
}

export const getAdditionalFields = createAsyncThunk(
  'form/get-additional-fields',
  async ({ formId }: GetAdditionalFields) =>
    apiService(`form/get-additional-fields/${formId}`, 'GET'),
);

export const customFormSchema = createSlice({
  name: 'customFormSchema',
  initialState: [] as AdditionalField[],
  reducers: {
    changeOrder: (state, action: PayloadAction<ChangeFieldOrder>) => {
      const { dragFieldId, dropFieldId, dragFieldOrder, dropFieldOrder } = action.payload;
      state.map((field) => {
        if (field._id === dragFieldId) {
          field.order = dropFieldOrder;
        }

        if (field._id === dropFieldId) {
          field.order = dragFieldOrder;
        }
        return field;
      });
    },
    createAdditionalFields: (state, action: PayloadAction<AdditionalFieldReq>) => {
      const {
        label,
        type,
        name,
        isRequired,
        placeholder,
        conditionalOptions,
        _id,
        groupBy,
        order,
      } = action.payload;
      let formSelectOptions;

      if (type === 'select' && action.payload.selectOptions) {
        const { selectOptions } = action.payload;
        formSelectOptions = selectOptions.split(';');
      }

      const conditionalFieldOptions = {
        fieldName: conditionalOptions?.fieldName?.value,
        fieldValue: conditionalOptions?.fieldValue,
      };

      const newField = {
        _id,
        label,
        type,
        name,
        formSelectOptions,
        isRequired,
        placeholder,
        order,
        conditionalOptions: conditionalFieldOptions,
        groupBy,
      };

      state.push(newField);
    },
    updateAdditionalField: (state, action: PayloadAction<Omit<AdditionalFieldReq, 'order'>>) => {
      const { label, type, name, isRequired, placeholder, conditionalOptions, _id, groupBy } =
        action.payload;
      let formSelectOptions;

      if (type === 'select' && action.payload.selectOptions) {
        const { selectOptions } = action.payload;
        formSelectOptions = selectOptions.split(';');
      }

      const conditionalFieldOptions = {
        fieldName: conditionalOptions?.fieldName?.value,
        fieldValue: conditionalOptions?.fieldValue,
      };

      const field = {
        _id,
        label,
        type,
        name,
        formSelectOptions,
        isRequired,
        placeholder,
        conditionalOptions: conditionalFieldOptions,
        groupBy,
      };

      const fieldIndex = state.findIndex((oldField) => oldField._id === field._id);

      state[fieldIndex] = { ...field, order: state[fieldIndex].order };
    },
    removeAdditionalField: (state, action: PayloadAction<number>) =>
      state
        .filter((field, index) => index !== action.payload)
        .sort((a, b) => a.order - b.order)
        .map((field, index) => ({ ...field, order: index + 1 })),
    clearFormSchema: (state) => [],
  },
  extraReducers: {
    [getAdditionalFields.fulfilled as any]: (state, action: PayloadAction<any>) => {
      if (action.payload?.length > 0) {
        const { additionalFields } = action.payload[0];
        additionalFields.forEach((field: any) => {
          state.push(field);
        });
      }
    },
  },
});

export const {
  createAdditionalFields,
  removeAdditionalField,
  clearFormSchema,
  updateAdditionalField,
  changeOrder,
} = customFormSchema.actions;

export default customFormSchema.reducer;
