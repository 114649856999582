import { FieldType, TableSchema } from '../types';

export const reportRankingTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'id',
      field: '_id',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'createdDt',
      field: 'createdDt',
      searchable: false,
      sortable: true,
      type: FieldType.Date,
      defaultSort: true,
    },
    {
      name: 'firstName',
      field: 'user.firstName',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'lastName',
      field: 'user.lastName',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'receivedPoints',
      field: 'points',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
  ],
};
