import { appStatus } from '../actions/app-status';
import { store } from '../config/store';

const setWindowState = (): void => {
  const listenToWindowEvent = (name: string, mapEventToAction: any) => (
    dispatch: any,
  ) => {
    const handleEvent = (e: any) => {
      dispatch(mapEventToAction(e));
    };
    window.addEventListener(name, handleEvent);
  };

  store.dispatch(listenToWindowEvent('offline', appStatus));

  store.dispatch(listenToWindowEvent('online', appStatus));

  store.dispatch(
    appStatus({ type: window.navigator.onLine ? 'online' : 'offline' })
  );
};

export default setWindowState;
