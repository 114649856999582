import React, { FC, useEffect } from 'react';
import { NewsCarosuel } from './NewsCarousel';

import { Spinner } from '..';
import { Container } from '../../Layout';
import { useDashboard } from '../../../hooks/dashboard/useDashboard';

const HomeNewsMobile: FC = () => {
  const { items, loading, getNewsSection } = useDashboard();

  useEffect(() => {
    getNewsSection();
  }, []);

  if (loading) return <Spinner />;
  if (items && items.length !== 0) {
    return (
      <section>
        <Container>
          <NewsCarosuel items={items} />
        </Container>
      </section>
    );
  } else return <></>;
};

export default HomeNewsMobile;
