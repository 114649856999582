import { QuizListRes, QuizType } from 'app-types';
import React, { FC } from 'react';
import { quizListSchema } from 'app-tables';

import { InteractiveTableSchema } from '../../../utils/table-schema-utils';
import { ApiList, Card } from '..';
import { StyledApiListWrapper } from '../../Styled';

interface Props {
  item: QuizListRes;
  index: number;
  initialPage: number;
}

const QuizCard = ({ item, index, initialPage }: Props) => (
  <Card
    use="quiz"
    key={item._id}
    title={item.title}
    to={`/dashboard/quizzes/preview/${item._id}/`}
    imgUrl={`quiz/image/quizPictureFn/${item._id}`}
    extraText={item.description}
    points={`${item?.maxPoints}PKT` ?? null}
  />
);

const SurveyCard = ({ item, index, initialPage }: Props) => (
  <Card
    use="quiz"
    key={item._id}
    title={item.title}
    to={`/dashboard/quizzes/surveys/preview/${item._id}`}
    imgUrl={`quiz/image/quizPictureFn/${item._id}`}
    extraText={item.description}
  />
);

interface QuizzesListProps {
  quizType: QuizType;
  apiUrl: 'active-list' | 'solved-list' | 'finished-list';
  survey?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const QuizzesList: FC<QuizzesListProps> = ({ quizType, apiUrl, survey }) => {
  const scheme = new InteractiveTableSchema(quizListSchema);
  return (
    <StyledApiListWrapper>
      <ApiList
        noSearch
        scheme={scheme}
        apiEndpointSubUrl={`quiz/${apiUrl}/${quizType}`}
        component={survey ? SurveyCard : QuizCard}
      />
    </StyledApiListWrapper>
  );
};
