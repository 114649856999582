import React from 'react';
import styled from 'styled-components';
import { gtMedia } from '../../../../config/config';
import { StyledText } from '../../Text';

interface Props {
  firstName: string;
  lastName: string;
}

const StyledUserName = styled(StyledText)`
  ${gtMedia('md')} {
    font-size: 2.5rem;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const UserName = React.memo<Props>(({ firstName, lastName }) => {
  const fullName = () => `${firstName} ${lastName}`;
  return (
    <StyledUserName textAlign="center" fontWeight="bold" fontSize="extraBig" my={1} data-cy="userName">
      {fullName()}
    </StyledUserName>
  );
});
