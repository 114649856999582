import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: React.ReactNode;
  preview?: boolean;
}

const StyledTableH = styled.th`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing} / 1.5) calc(${theme.mainSpacing} / 1.5)`};
  &:first-child {
    padding-left: 0;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const TableH = ({ children }: Props) => {

  return (
    <StyledTableH>
      {children}
    </StyledTableH>
  );
};
