import React, { FC } from 'react';

import { IconButton } from '..';

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line import/prefer-default-export
export const CarouselButton: FC<Props> = ({ children }) => (
  <IconButton width="38px" height="38px" mr={5} ml={5} bRadius="50%" bg="transparent" withShadow>
    {children}
  </IconButton>
);
