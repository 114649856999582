import { theme } from './theme';
import { FontSizeOption, FontSize } from './styled';

export type BreakPoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type BreakPointData = { name: string; size: string };

// lt - less than
export const ltMedia = (point: BreakPoints): string => {
  let mediaClass = '.no-media';
  theme._breakpoints?.forEach((b: BreakPointData) => {
    if (b.name === point) mediaClass = `@media (max-width: ${b.size})`;
  });
  return mediaClass;
};

// gt - greater than
export const gtMedia = (point: BreakPoints): string => {
  let mediaClass = '.no-media';
  theme._breakpoints?.forEach((b: BreakPointData) => {
    if (b.name === point) mediaClass = `@media (min-width: ${b.size})`;
  });
  return mediaClass;
};

export const getFontSize = (size?: FontSizeOption | number): string => {
  let returnFont = theme.fontSizes[2].size; // default: 1.4rem
  if (size) {
    if (typeof size === 'number') return `${size}rem`;

    theme.fontSizes.forEach((font: FontSize) => {
      if (font.name === size) {
        returnFont = font.size;
      }
    });
  }
  return returnFont;
};

export const calcSpace = (prop?: boolean | number, multiplier?: number): number | string => {
  if (prop === true) {
    return `calc(${multiplier ?? 1} * ${theme.mainSpacing})`;
  } else if (prop) {
    return `calc(${prop ?? 1} * ${theme.mainSpacing})`;
  } else {
    return 0;
  }

};
