import { VideoStatus, CheckVideoRes } from 'app-types';
import React, { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { PhotoWrapper, VideoWrapper, Image, VideoNotAvailable } from '..';
import { apiUrl } from '../../../config/apiUrl';
import { useApi } from '../../../services/useApi';
import { MultimediaFile } from '../../../types/photo';

interface FilePreviewProps {
  name: string;
  editId?: string | number;
  quizFile?: string;
  video?: boolean;
  filePath?: string | null;
  customFilePath?: string;
  findByName?: (fieldName: string) => MultimediaFile[];
  deleteContent?: () => void;
}

type FileType = 'video' | 'photo';

export const FilePreview: FC<FilePreviewProps> = ({
  name,
  video,
  quizFile,
  deleteContent,
  editId,
  findByName,
  filePath,
  customFilePath,
}) => {
  const fileType: FileType = video ? 'video' : 'photo';
  const { api } = useApi();
  const [isVideoAvailable, setIsVideoAvailable] = useState<boolean>(false);

  const getVideoStatus = (id: string | number) => {
    switch (name) {
      case 'articleVideo':
        api<CheckVideoRes>({
          method: 'GET',
          path: `article/check-video-status/${id}`,
          onSuccess: (data) => {
            if (data?.status === VideoStatus.Complete) setIsVideoAvailable(true);
          },
        });
        break;
      case 'quizVideo':
        api<CheckVideoRes>({
          method: 'GET',
          path: `quiz/check-video-status/${id}`,
          onSuccess: (data) => {
            if (data?.status === VideoStatus.Complete) setIsVideoAvailable(true);
          },
        });
        break;
    }
  };

  useEffect(() => {
    if (filePath && editId && fileType === 'video') {
      getVideoStatus(editId);
    }
  }, [filePath]);

  switch (fileType) {
    case 'video': {
      const isVideo = filePath || (findByName && findByName(name)?.length > 0);
      return (
        <VideoWrapper deleteVideo={deleteContent}>
          {video &&
            isVideo &&
            (findByName && findByName(name)?.length > 0 ? (
              findByName(name).map((vid) =>
                vid.previewUrl.map((previewUrl) => (
                  <ReactPlayer
                    className="react-player"
                    controls
                    playsInline
                    width="100%"
                    height="100%"
                    url={previewUrl}
                  />
                )),
              )
            ) : isVideoAvailable ? (
              <ReactPlayer
                className="react-player"
                controls
                playsInline
                width="100%"
                height="100%"
                url={filePath ?? ''}
              />
            ) : (
              <VideoNotAvailable height="100%" />
            ))}
        </VideoWrapper>
      );
    }
    case 'photo':
      return (
        <PhotoWrapper deletePhotos={deleteContent}>
          {(editId || quizFile) && findByName && findByName(name).length === 0 ? (
            quizFile ? (
              <Image src={`${apiUrl}${quizFile}`} />
            ) : (
              <Image src={customFilePath || `${apiUrl}${filePath}/${name}/${editId}`} />
            )
          ) : (
            findByName &&
            findByName(name)?.length > 0 &&
            findByName(name).map((photo) =>
              photo.previewUrl.map((previewUrl) => (
                <Image isPreview key={previewUrl} src={previewUrl} />
              )),
            )
          )}
        </PhotoWrapper>
      );
    default:
      return null;
  }
};
