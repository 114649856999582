import { OutputData } from '../article';
import { SelectOption } from '../form';

export enum SlideLayout {
  FullBackground = 'full_background',
  TextLeftImageRight = 'text_left_image_right',
  TextRightImageLeft = 'text_right_image_left',
}

export interface Slide {
  _id: string;
  title: string;
  order: number;
  subtitle?: string;
  layout: SelectOption;
  pictureFn?: string;
  content: string;
  htmlContent: string;
  withOverlay: boolean;
  color: string;
  buttonLabel?: string;
  buttonHref?: string;
}

export interface SlidePreview {
  _id: string;
  order: number;
  title: string;
}

export interface SetSlideReq {
  _id?: string;
  title: string;
  order: number;
  subtitle?: string;
  layout: SelectOption;
  content: string;
  withOverlay: boolean;
  color: string;
  buttonLabel?: string;
  buttonHref?: string;
}
