/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import { Button } from '../..';

interface Props {
  text: string;
  append: (data: Record<string, unknown>) => void;
  dataCy?: string;
}

export const FieldArrayButton: FC<Props> = ({ append, text, dataCy }) => (
  <Button
    mb={1}
    ml="auto"
    variant="secondary"
    small
    onClick={() => append({})}
    text={text}
    dataCy={dataCy}
  />
);
