import React, { FC } from 'react';
import StyledPageErrorContent from '../../../components/Styled/StyledPageErrorContent';
import { PageTitle } from '../../../components/Common';

export const Content: FC = () => (
  <StyledPageErrorContent>
    <PageTitle>Serwis, na który próbujesz wejść jest nieaktywny</PageTitle>
  </StyledPageErrorContent>
);

