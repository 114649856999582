import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableSchema, TableSchemaField } from 'app-tables';
import Spinner from '../Spinner';
import SearchBar from '../../Common/SearchComponent';
import debounce from '../../../utils/debouce';
import { apiList } from '../../../actions';
import List from '../List';
import { __ } from '../../../helpers/i18n';
import { Alert } from '..';
import { RootState } from '../../../reducers';
import { Container } from '../../../components/Layout';

interface Props {
  fetchApiListData: (url: string) => void;
  fetchApiListInfiniteData: (url: string) => void;
  clearApiList: () => void;
  loading: boolean;
  data: any;
  component: React.ComponentClass<any> | React.FunctionComponent<any>;
  scheme: TableSchema;
  apiEndpointSubUrl: string;
  apiEndpointMethod?: string; // Defaults to GET
  narrow?: boolean;
  padded?: boolean;
  id?: string;
  noSearch?: boolean;
  filterBy?: any[];
  nextToSearchBar?: ReactNode;
  negativeMargin?: boolean;
  infinite?: boolean;
}

interface State {
  page: number;
  prevY: number;
  searchQuery: string;
  searchBy: string;
  sortBy: string;
  sortDirBack: boolean;
  loading: boolean;
  data: {
    countTotal: number;
    items: any[];
  };
}

class ApiList<T> extends Component<Props, State> {
  private debouncedFetch: () => void;
  private observer: IntersectionObserver | null = null;
  private loadingRef: React.RefObject<HTMLDivElement> = React.createRef();

  state = {
    searchQuery: '',
    searchBy: '',
    page: 1,
    sortBy: '',
    sortDirBack: false,
    loading: true,
    prevY: 0,
    data: {
      countTotal: 0,
      items: [],
    },
  };

  constructor(props: Props) {
    super(props);
    this.debouncedFetch = debounce(this.fetch, 400);
    const { scheme } = props;
    const defaultSearchField = scheme.fields.find(
      (field) => ['string', 'number'].indexOf(typeof field.defaultSearch) > -1,
    );
    const currSort = this.getCurrentSort();
    if (defaultSearchField) {
      this.state.searchBy = defaultSearchField.field;
      this.state.searchQuery = String(defaultSearchField.defaultSearch);
    }
    if (currSort) {
      this.state.sortBy = currSort.sortBy;
      this.state.sortDirBack = currSort.sortDirBack;
    }
  }

  private async fetch() {
    const { fetchApiListData } = this.props;

    fetchApiListData(this.buildUrl());
  }

  private async fetchInfinite() {
    const { fetchApiListInfiniteData } = this.props;

    fetchApiListInfiniteData(this.buildUrl());
  }

  private getCurrentSort(): {
    sortBy: string;
    sortDirBack: boolean;
  } {
    const { sortBy, sortDirBack } = this.state;
    const { scheme } = this.props;
    if (sortBy !== '') {
      return {
        sortBy,
        sortDirBack,
      };
    } else {
      const field = scheme.fields.find((f) => f.defaultSort === true) as TableSchemaField;
      if (!field) {
        return {
          sortBy: '',
          sortDirBack: false,
        };
      }
      return {
        sortBy: field.field,
        sortDirBack: field.oppositeSortDir !== true,
      };
    }
  }

  handleObserver = (entities: any[]) => {
    const { data } = this.state;
    const y = entities[0].boundingClientRect.y;

    if (data.items.length !== 0 && data.items.length === data.countTotal) return;
    if (entities[0].isIntersecting) {
      this.setState(
        (state) => ({ page: state.page + 1 }),
        () => {
          this.fetchInfinite();
        },
      );
    }
    this.setState({ prevY: y });
  };

  prepareInfiniteScroll() {
    if (!this.loadingRef || !this.loadingRef.current) return;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(this.handleObserver, options);
    this.observer.observe(this.loadingRef.current);
  }

  componentDidMount() {
    const { apiEndpointSubUrl, infinite } = this.props;
    try {
      const storageSearchBy = localStorage.getItem(`${apiEndpointSubUrl}_searchBy`);
      const storageSearchQuery = localStorage.getItem(`${apiEndpointSubUrl}_searchQuery`);
      const storageSortBy = localStorage.getItem(`${apiEndpointSubUrl}_sortBy`);
      const storageDirBack = JSON.parse(
        localStorage.getItem(`${apiEndpointSubUrl}_sortDirBack`) || 'false',
      );
      const storagePage = JSON.parse(localStorage.getItem(`${apiEndpointSubUrl}_page`) || '0');

      if (storageSearchBy) {
        this.setState({ searchBy: storageSearchBy });
      }
      if (storageSearchQuery) {
        this.setState({ searchQuery: storageSearchQuery });
      }
      if (storageSortBy) {
        this.setState({ sortBy: storageSortBy });
      }
      if (storageDirBack) {
        this.setState({ sortDirBack: storageDirBack });
      }
      if (storagePage && !infinite) {
        this.setState({ page: storagePage });
      } else {
        this.setState({ page: 0 });
      }
    } catch (e) {}
    this.fetch();

    if (infinite) this.prepareInfiniteScroll();
  }

  componentDidUpdate(prevProps: Props) {
    const { data } = this.props;
    if (data.uuid !== prevProps.data.uuid) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { clearApiList } = this.props;
    clearApiList();
  }

  handlePageChange = (newPage: number) => {
    const { page } = this.state;
    const { apiEndpointSubUrl } = this.props;
    if (page === newPage) return;
    localStorage.setItem(`${apiEndpointSubUrl}_page`, JSON.stringify(newPage));

    this.setState(
      {
        page: newPage,
      },
      this.fetch,
    );
  };

  handleSortChange = (sortBy: string, sortDirBack: boolean) => {
    const { apiEndpointSubUrl } = this.props;
    const currSort = this.getCurrentSort();

    if (currSort.sortBy === sortBy && currSort.sortDirBack === sortDirBack) return;

    localStorage.setItem(`${apiEndpointSubUrl}_sortBy`, sortBy);
    localStorage.setItem(`${apiEndpointSubUrl}_sortDirBack`, JSON.stringify(sortDirBack));

    this.setState(
      {
        sortBy,
        sortDirBack,
      },
      this.fetch,
    );
  };

  private buildUrl(): string {
    const { apiEndpointSubUrl } = this.props;
    const { searchBy, searchQuery, page, sortBy, sortDirBack } = this.state;
    return `${apiEndpointSubUrl}?${new URLSearchParams({
      searchBy,
      searchQuery,
      page,
      sortBy,
      sortDirBack,
    } as any)}`;
  }

  handleSearchChange = (searchBy: string, searchQuery: string) => {
    const { searchBy: searchByNow, searchQuery: searchQueryNow } = this.state;
    const { apiEndpointSubUrl } = this.props;
    if (searchBy === searchByNow && searchQuery === searchQueryNow) return;

    const newSearchQuery = searchBy === searchByNow ? searchQuery : '';

    localStorage.setItem(`${apiEndpointSubUrl}_searchBy`, searchBy);
    localStorage.setItem(`${apiEndpointSubUrl}_searchQuery`, newSearchQuery);
    localStorage.setItem(`${apiEndpointSubUrl}_page`, JSON.stringify(0));

    this.setState(
      {
        page: 1,
        searchBy,
        searchQuery: newSearchQuery,
      },
      this.debouncedFetch,
    );
  };

  render() {
    const { page, searchQuery, searchBy } = this.state;
    const { loading, scheme, narrow, filterBy, data, component, id, noSearch, infinite } =
      this.props;
    let list;

    if (loading && (!infinite || !data.items || data.items.length === 0)) {
      list = <Spinner overlay />;
    } else if (data.countTotal === 0) {
      list = <Container><Alert simple type="error" text="application.noData" /></Container>;
    } else if (filterBy && !loading) {
      const ids = filterBy.map((item: any) => item.id);
      const filteredData = () => {
        const filteredItems = data.items.filter((item: any) => !ids.includes(item.id));
        return {
          countTotal: filteredItems.length,
          items: filteredItems,
          loading,
        };
      };
      list = (
        // <Row width={1} margin={0 && !negativeMargin}>
        <List
          data={filteredData()}
          scheme={scheme}
          narrow={narrow}
          initialPage={page}
          onPageChange={this.handlePageChange}
          component={component}
          id={id}
          page={page}
          noPagination={infinite}
        />
        // </Row>
      );
    } else {
      list = (
        // <Row width={1} margin={0 && !negativeMargin}>
        <List
          data={data}
          scheme={scheme}
          narrow={narrow}
          initialPage={page}
          onPageChange={this.handlePageChange}
          component={component}
          id={id}
          page={page}
          noPagination={infinite}
        />
        // </Row>
      );
    }

    return (
      <>
        {data &&
          !noSearch &&
          SearchBar(
            scheme.fields,
            {
              searchBy,
              searchQuery,
            },
            null,
            this.handleSearchChange,
            undefined,
            true,
            undefined,
            undefined,
            this.props.nextToSearchBar,
          )}
        <div className="api-list">
          {list}
          <div ref={this.loadingRef} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  data: state.apiList,
  loading: state.apiList.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchApiListData: apiList.fetchApiListData,
      fetchApiListInfiniteData: apiList.fetchApiListInfiniteData,
      clearApiList: apiList.clearApiList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApiList);
