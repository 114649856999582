import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../reducers';
import { Image } from '../../Common';
import { apiUrl } from '../../../config/apiUrl';
import { gtMedia } from '../../../config/config';

const StyledUserImage = styled.div`
  width: ${({ theme }) => theme.userImageSizeMobile};
  height: ${({ theme }) => theme.userImageSizeMobile};
  object-fit: cover;
  border-radius: 50%;
  box-shadow: ${({ theme }) =>
    `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-.5 * ${theme.mainSpacing}) rgba(0,0,0, 0.5)`};
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${gtMedia('md')} {
    margin-top: ${({ theme }) => `calc( -3 * ${theme.mainSpacing})`};
    width: ${({ theme }) => theme.userImageSizeDesktop};
    height: ${({ theme }) => theme.userImageSizeDesktop};
  }
`;

const UserImage: FC = () => {
  const user = useSelector((state: RootState) => state.user.details);

  const initials = () => `${user?.firstName?.charAt(0)} ${user?.lastName?.charAt(0)}`;

  console.log('GETTING USER IMAEG FROM', `${apiUrl}user/avatarFn/photo/${user!._id}/${user?.photoFn}`);

  return (
    <StyledUserImage>
      <Image
        avatar
        round
        src={`${apiUrl}user/avatarFn/photo/${user!._id}/${user?.photoFn}`}
        alt={initials()}
      />
    </StyledUserImage>
  );
};

export default UserImage;
