import React, { ReactNode } from 'react';
import { Container } from '../../Layout';

import './TableWrapper.scss';

interface Props {
  children: ReactNode;
}

const TableWrapper = ({ children }: Props) => (
  <Container>
    <div className="table-wrapper">{children}</div>
  </Container>
);

export default TableWrapper;
