export const mapBy = <T>(arr: T[], mapBy: keyof T) => {
  return arr.map((element) => element[mapBy]);
};

/**
 * @param arr Array of object to mapped
 * @param mapTo array of tuples [oldPropName, newPropName]
 * @returns {K[]}
 */

export const mapTo = <T, K>(arr: T[], mapTo: [keyof T, keyof K][]) =>
  arr.map((element) => {
    mapTo.forEach((elementToMap) => {
      if (element[elementToMap[0]]) {
        // key of dont have index type - ts design problem
        // @ts-ignore
        element[elementToMap[1]] = element[elementToMap[0]];
        delete element[elementToMap[0]];
        return element;
      }
    });
    return element;
  }) ?? [];
