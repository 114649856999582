import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface StyledInfoBoxProps {
  completed?: boolean;
  info?: boolean;
  bgColor?: string;
}

interface InfoBoxProps extends StyledInfoBoxProps {
  text: string;
}

export const StyledInfoBox = styled.div<StyledInfoBoxProps>`
  border-radius: 5px;
  min-height: 30px;
  max-height: 40px;
  color: ${({ theme }) => theme.colors.colorLight};
  word-wrap: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  padding: ${({ theme }) => `${theme.mainSpacing} calc(2 * ${theme.mainSpacing})`};
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.colors.colorBackground};

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${({ theme }) => theme.colors.colorPrimary};
    `}
  ${({ info }) =>
    info &&
    css`
      color: ${({ theme }) => theme.colors.colorDark};
      border: 1px solid ${({ theme }) => theme.colors.colorPrimary};
    `}
`;

const InfoBox: FC<InfoBoxProps> = ({ completed, text, info, bgColor }) => (
  <StyledInfoBox bgColor={bgColor} completed={completed} info={info}>
    {text}
  </StyledInfoBox>
);

export default InfoBox;
