import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Picker from 'emoji-picker-react';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { ArticleCommentReq, PlatformFullConfig } from 'app-types';
import styled from 'styled-components';
import Author from '../Author';
import { useCommentForm } from '../../../../../../hooks/articles/useComment';
import { FormField, Button, Spinner, Wrapper } from '../../../../../../components/Common';
import { StyledForm } from '../../../../../../components/Styled';
import { Column } from '../../../../../../components/Layout';

import { RootState } from '../../../../../../reducers';
import { __ } from '../../../../../../helpers/i18n';
import { gtMedia } from '../../../../../../config/config';
import { InputGroup } from '../../../../../../components/Layout/InputGroup';

interface Props {
  articleId: string;
  getArticle: () => void;
  commentId?: string;
  commentParentId?: string;
  content?: string;
}

const CommentButtons = styled.div`
  flex: 0 1 100%;

  ${gtMedia('sm')} {
    flex: 0 1 auto;
    margin-left: ${({ theme }) => `calc(${theme.mainSpacing}/2)`};
  }
`;

const StyledPicker = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => `calc(1.5 * ${theme.mainSpacing})`};
  .emoji-picker-react {
    font-family: blocal;
    position: absolute;
    z-index: 2;
    right: 0;

    .emoji-group:before {
      text-transform: unset;
    }
  }
`;

const CommentForm: FC<Props> = ({ articleId, commentId, commentParentId, getArticle, content }) => {
  const { submit, loading } = useCommentForm();
  const user = useSelector((state: RootState) => state.user.details);
  const [isPicker, setIsPicker] = useState(false);
  const allowAnonymousComments = useSelector(
    (state: RootState) => (state.platformConfig?.config as PlatformFullConfig).allowAnonymousComments,
  );
  const methods = useForm({
    mode: 'onChange',
  });

  const selectEmoji = (e: any, data: any) => {
    const currentValue = methods.getValues('content');
    methods.setValue('content', `${currentValue} ${data.emoji || ''}`, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setIsPicker(false);
  };

  useEffect(() => {
    if (content) {
      methods.setValue('content', content);
    }
  }, []);

  return (
    <Wrapper
      mt={2}
      mb={2}
      p={1}
      border="1px solid"
      configBorderRadius
      borderColor="colorInput"
      position="relative"
    >
      <FormProvider {...methods}>
        {loading && <Spinner overlay halfTransparent />}
        <StyledForm
          onSubmit={methods.handleSubmit((data: ArticleCommentReq, e) =>
            submit(data, articleId, commentId, commentParentId, () => {
              getArticle();
              methods.setValue('content', null);
            }),
          )}
        >
          <InputGroup>
            <Wrapper display="flex" flex="1">
              <Author id={user!._id} />
              <Wrapper flex="1">
                <FormField
                  isRequired
                  type="textarea"
                  name="content"
                  placeholderText="Komentarz..."
                  icon={faSmile}
                  iconClick={() => {
                    setIsPicker(!isPicker);
                  }}
                />
              </Wrapper>
              <StyledPicker>
                {isPicker && (
                  <Picker
                    disableAutoFocus
                    disableSearchBar
                    onEmojiClick={selectEmoji}
                    groupNames={{
                      smileys_people: __('emoticons.smileys_people'),
                      animals_nature: __('emoticons.animals_nature'),
                      food_drink: __('emoticons.food_drink'),
                      travel_places: __('emoticons.travel_places'),
                      activities: __('emoticons.activities'),
                      objects: __('emoticons.objects'),
                      symbols: __('emoticons.symbols'),
                      flags: __('emoticons.flags'),
                      recently_used: __('emoticons.recently_used'),
                    }}
                  />
                )}
              </StyledPicker>
            </Wrapper>
            <CommentButtons>
              <Button
                width={1}
                dataCy="commentSubmitButton"
                disabled={!methods.formState.isValid || methods.formState.isSubmitting || loading}
                type="submit"
                text={commentId ? 'application.save' : 'application.add'}
              />
            </CommentButtons>
          </InputGroup>
          {allowAnonymousComments && !commentId && (
            <Column width={1}>
              <FormField labelText="Dodaj komentarz anonimowo" type="checkbox" name="isAnonymous" />
            </Column>
          )}
        </StyledForm>
      </FormProvider>
    </Wrapper>
  );
};

export default CommentForm;
