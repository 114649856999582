import {FieldType, TableSchema} from "../types";

export const invitedUserSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        { name: 'Identyfikator', field: 'id', searchable: false, sortable: false, type: FieldType.Text },
        { name: 'E-mail', field: 'email', searchable: true, sortable: true, type: FieldType.Email },
        { name: 'Imię', field: 'firstName', searchable: true, sortable: true, type: FieldType.Text },
        { name: 'Nazwisko', field: 'lastName', searchable: true, sortable: true, type: FieldType.Text, defaultSort: true },
        { name: 'Telefon', field: 'tel', searchable: true, sortable: true, type: FieldType.Tel },
        { name: 'Czy użytkownik się zarejestrował?', field: 'isRegistered', searchable: false, sortable: true, type: FieldType.Boolean },
        { name: 'Zaproszenie wykorzystane?', field: 'isUsed', searchable: false, sortable: true, type: FieldType.Boolean },
    ],
};
