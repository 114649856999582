/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { CustomSelectOption, GroupRes, UserRole } from 'app-types';

import { useApi } from '../services/useApi';

interface UseReceivers {
  getGroups: () => void;
  groups: CustomSelectOption<UserRole>[];
}

export const useReceivers = (): UseReceivers => {
  const { api } = useApi();
  const [groups, setGroups] = useState<CustomSelectOption<UserRole>[]>([]);

  const getGroups = () => {
    api<GroupRes[]>({
      method: 'GET',
      path: 'group/my-groups',
      onSuccess: (data) => {
        const receiversGroups = data.map((option: GroupRes) => ({
          value: option.value,
          label: option.label,
        }));
        setGroups(receiversGroups);
      },
    });
  };

  return {
    getGroups,
    groups,
  };
};
