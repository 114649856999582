import {FieldType, TableSchema} from "../types";

export const groupTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "Identyfikator",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Nazwa",
            field: "label",
            searchable: true,
            sortable: true,
            defaultSort: true,
            type: FieldType.Text,
        },
        {
            name: "Czy podstawowy",
            field: "isDefault",
            searchable: true,
            sortable: true,
            type: FieldType.Boolean
        }
    ]
};
