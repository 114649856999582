import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface StyledArticleTitleProps {
  bigFont?: boolean;
  dashboardCarousel?: boolean;
}

const StyledArticleTitle = styled.h2<StyledArticleTitleProps>`
  background-color: ${({ theme }) => 'rgba(0, 0, 0, 0.7)'};
  color: ${({ theme }) => theme.colors.colorLight};
  padding: ${({ theme }) => `calc(1.5 * ${theme.mainSpacing})`};
  font-size: 1.6rem;
  font-weight: 700;
  display: block;
  position: absolute;
  bottom: ${({ theme }) => theme.mainSpacing};
  width: 100%;
  left: 0px;
  right: 0px;
  ${({ bigFont }) =>
    bigFont &&
    css`
      font-size: 2rem;
    `}
`;

interface ArticleTitleProps extends StyledArticleTitleProps {
  title: string;
}

// eslint-disable-next-line import/prefer-default-export
export const ArticleTitle: FC<ArticleTitleProps> = ({ title, bigFont }) => (
  <StyledArticleTitle bigFont={bigFont}>{title}</StyledArticleTitle>
);
