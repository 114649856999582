import React, { FC } from 'react';
import { FaChevronCircleRight, FaChevronCircleLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CarouselButton } from '..';
import { gtMedia } from '../../../config/config';
import { RootState } from '../../../reducers';

const StyledSwiperNavigation = styled.div`
  position: absolute;
  width: 100%;
  top: 40%;
  display: flex;
  justify-content: space-between;
  z-index: 999;

  .swiper-button-prev-unique {
    position: absolute;
    left: 0;
    top: 0;
  }

  .swiper-button-next-unique {
    position: absolute;
    top: 0;
    right: 0;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  ${gtMedia('md')} {
    top: 50%;

    .swiper-button-prev-unique {
      left: 5px;
    }
    .swiper-button-next-unique {
      right: 5px;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const SwiperNavigation: FC = () => {
  const configColors = useSelector((state: RootState) => state?.platformConfig?.colors);

  return (
    <StyledSwiperNavigation>
      <div className="swiper-button-prev-unique">
        <CarouselButton>
          <FaChevronCircleLeft size={38} color={configColors.colorPrimary} />
        </CarouselButton>
      </div>
      <div className="swiper-button-next-unique">
        <CarouselButton>
          <FaChevronCircleRight size={38} color={configColors.colorPrimary} />
        </CarouselButton>
      </div>
    </StyledSwiperNavigation>
  );
};
