/* eslint-disable no-return-await */
/* eslint-disable no-useless-catch */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartRes } from 'app-types';
import { OrderQuantityReq } from 'app-types';
import { notifications } from '../actions';
import { successNotification } from '../actions/notifications';
import { history } from '../App';
import { store } from '../config/store';
import { apiService } from '../services/apiService';

export const getCartDetails = createAsyncThunk('cart/details', async () => {
  const res = await apiService(`user/my-cart-details`, 'GET');
  return res;
});

export const addToCart = createAsyncThunk('cart/add-to-cart', async (payload: OrderQuantityReq) => {
  const res = await apiService(`catalog/add-to-cart`, 'POST', undefined, undefined, false, payload);
  store.dispatch(successNotification('Dodano do koszyka'));
  history.goBack();
  return res;
});

export const buyNow = createAsyncThunk('cart/add-to-cart', async (payload: OrderQuantityReq) => {
  const res = await apiService(`catalog/add-to-cart`, 'POST', undefined, undefined, false, payload);
  history.push('/dashboard/products/cart');
  return res;
});

export const updateCart = createAsyncThunk(
  'cart/update-cart',
  async (payload: OrderQuantityReq) => {
    store.dispatch(setLockCart(true));
    const res = await apiService(
      `catalog/update-cart-quantity`,
      'POST',
      undefined,
      undefined,
      false,
      payload,
    );
    store.dispatch(setLockCart(false));
    return res;
  },
);

export const removeFromCart = createAsyncThunk(
  'cart/remove-from-cart',
  async (productId: string) => {
    store.dispatch(setLockCart(true));
    const res = await apiService(`catalog/delete-from-cart/${productId}`, 'PATCH');
    store.dispatch(setLockCart(false));
    return res;
  },
);

export const completeOrder = createAsyncThunk('cart/complete-order', async () => {
  store.dispatch(setLockCart(true));
  const res = await apiService(`catalog/buy`, 'POST');
  store.dispatch(setLockCart(false));
  if (res.success) {
    store.dispatch(notifications.successNotification('Poprawnie dokonano zakupu.'));
    history.push('/dashboard/products/');
  }
  else {
    eval(res);
  }
  return res;
});

export interface CartState {
  details: null | CartRes;
  lockCart: boolean;
}

const cart = createSlice({
  name: 'cart',
  initialState: {
    details: null,
    lockCart: false,
  } as CartState,
  reducers: {
    setLockCart: (state, action: PayloadAction<boolean>) => {
      state.lockCart = action.payload;
    },
    setCart: (state, action: PayloadAction<CartRes | null>) => {
      state.details = action.payload;
    },
  },
  extraReducers: {
    [getCartDetails.fulfilled as any]: (state, action: PayloadAction<CartRes>) => {
      state.details = action.payload;
    },
    [addToCart.fulfilled as any]: (state, action: PayloadAction<CartRes>) => {
      if (state.details) {
        state.details = action.payload;
      }
    },
    [buyNow.fulfilled as any]: (state, action: PayloadAction<CartRes>) => {
      if (state.details) {
        state.details = action.payload;
      }
    },
    [updateCart.fulfilled as any]: (state, action: PayloadAction<CartRes>) => {
      if (state.details) {
        state.details = action.payload;
      }
    },
    [removeFromCart.fulfilled as any]: (state, action: PayloadAction<CartRes>) => {
      if (state.details) {
        state.details = action.payload;
      }
    },
    [completeOrder.fulfilled as any]: (state, action: PayloadAction<string>) => {
      if (state.details) {
        state.details = null;
      }
    },
  },
});

export const { setLockCart, setCart } = cart.actions;

export default cart.reducer;
