import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorCode, Colors, PlatformBasicConfig, PlatformFullConfig } from 'app-types';
import { errorNotification } from '../actions/notifications';
import { history } from '../App';
import { store } from '../config/store';
import { theme } from '../config/theme';
import { apiService } from '../services/apiService';
import { logout } from './userSlice';
import { Capacitor } from '@capacitor/core';

export interface PlatformConfig {
    host: string;
    tenantId: string;
    config: PlatformBasicConfig | PlatformFullConfig;
    loading: boolean;
    colors: Colors;
    basicForms: any[];
    borderRadius?: number;
}

const handleConfigErrorResponse = (err: any) => {
    const { response } = err;
    if (response.data.error_code === ApiErrorCode.TenantIsDeactivated) {
        history.push('/not-active');
    } else {
        store.dispatch(errorNotification(response.data.error_code));
    }
};

export const getPlatformConfig = createAsyncThunk(
    'init',
    async () => {
    
    
        const hostname = Capacitor.isNativePlatform() ? process.env.REACT_APP_HOSTNAME : window.location.hostname;
        console.log('TRYING TO GET CONFIG', hostname)
        return await apiService(
            `tenant/init/${hostname}`,
            'GET',
            undefined,
            handleConfigErrorResponse,
            true,
        )
    }
);

export const getFullPlatformConfig = createAsyncThunk('init', async () => {
    try {
        const hostname = Capacitor.isNativePlatform() ? process.env.REACT_APP_HOSTNAME : window.location.hostname;
        const config = await apiService(
            `tenant/init-full/${hostname}`,
            'GET',
            undefined,
            undefined,
            true,
        );

        return config;
    } catch (error) {
        store.dispatch(logout(null));
    }
});

export const platformConfig = createSlice({
    name: 'lang',
    initialState: {} as PlatformConfig,
    reducers: {},
    extraReducers: {
        [getPlatformConfig.pending as any]: (state) => {
            console.log('PENDING GET CONFIG');
            state.loading = true;
        },
        [getPlatformConfig.fulfilled as any]: (state, action: PayloadAction<PlatformBasicConfig | PlatformFullConfig>) => {
            console.log('GOT CONFIG', action.payload);
            if (action.payload) {
                const { colors, basicForms, borderRadius } =
                    action.payload;
                   
                state.config = action.payload;
                state.colors = colors;
                state.borderRadius = borderRadius;
                state.basicForms = basicForms;
                state.tenantId = action.payload._id;
            }
            state.loading = false;
        },
        [getPlatformConfig.rejected as any]: (state, action: PayloadAction<null>) => {
            console.log('CANNOT GET CONFIG');
            state.loading = false;
            state.colors = theme.colors;
        },
    },
});

export default platformConfig.reducer;
