import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

// eslint-disable-next-line import/prefer-default-export
export const usePlatform = () => {
  const platformId = useSelector((state: RootState) => state.platformConfig?.config?._id);
  const user = useSelector((state: RootState) => state?.user?.details);

  const [id, setId] = useState('');

  const setCorrectId = useCallback(() => {
    setId(platformId);
  }, [platformId, user]);

  useEffect(() => {
    setCorrectId();
  }, [platformId]);

  return {
    platformId: id,
    tenantQuery: `?tenantId=${id}`,
  };
};
