import clsx from 'clsx';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import Spinner from '../../Spinner';
import './ModalContent.scss';

interface Props {
  children: React.ReactNode;
  light?: boolean;
  minHeight?: number;
  full?: boolean;
  wide?: boolean;
}

const ModalContent = ({ children, light, minHeight, full, wide }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const loading = useSelector((state: RootState) => state.modal.isLoading);

  return (
    <div
      style={{ minHeight: minHeight ?? 'auto' }}
      className={clsx('modal-content', { light, wide, 'full-screen': full })}
    >
      {loading && <Spinner overlay halfTransparent />}
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default ModalContent;
