import { QuizQuestion } from 'app-types';
import React from 'react';
import { useQuiz } from './useQuiz';

type Dispatch = {
  setSurvey: React.Dispatch<React.SetStateAction<boolean>>;
  runQuiz: () => void;
  handleSurveySubmit: () => void;
};

export type QuizState = {
  quizId: string;
  loading: boolean;
  checkedAnswers: number[];
  correctAnswers: string[];
  wrongAnswers: string[];
  getAnswer: (answer: number) => void;
  submitQuiz: (actionType: 'submit' | 'next' | 'finish') => void;
  isLastQuestionRunning: () => boolean;
  checkAnswer: (
    quizId: string,
    questionId: string,
    answersIds: string[],
    survey?: boolean,
    data?: { content?: string; answerId?: string },
    isQuestionOpen?: boolean,
  ) => void;
  question: QuizQuestion | null;
  survey: boolean;
};

const QuizContext = React.createContext<QuizState | undefined>(undefined);
const QuizDispatchContext = React.createContext<Dispatch | undefined>(undefined);

type QuizProviderProps = { children: React.ReactNode };

const QuizProvider = ({ children }: QuizProviderProps) => {
  const {
    quizId,
    survey,
    runQuiz,
    checkAnswer,
    loading,
    isLastQuestionRunning,
    question,
    getAnswer,
    submitQuiz,
    checkedAnswers,
    correctAnswers,
    wrongAnswers,
    setSurvey,
    handleSurveySubmit,
  } = useQuiz();

  return (
    <QuizContext.Provider
      value={{
        quizId,
        checkAnswer,
        loading,
        isLastQuestionRunning,
        question,
        getAnswer,
        submitQuiz,
        checkedAnswers,
        correctAnswers,
        wrongAnswers,
        survey,
      }}
    >
      <QuizDispatchContext.Provider value={{ setSurvey, runQuiz, handleSurveySubmit }}>
        {children}
      </QuizDispatchContext.Provider>
    </QuizContext.Provider>
  );
};

const useQuizContext = () => {
  const quizContext = React.useContext(QuizContext);
  const dispatchContext = React.useContext(QuizDispatchContext);
  if (quizContext === undefined || dispatchContext === undefined) {
    throw new Error('useQuizContext must be used within a DatePickerProvider');
  }
  return { quizContext, dispatchContext };
};

export { QuizProvider, useQuizContext };
