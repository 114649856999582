import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../Common';

const StyledBanner = styled.div`
  position: fixed;
  z-index: 2000;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
`;

const StyledBannerText = styled.p`
  font-size: 1.2rem;
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  padding: ${({ theme }) => css`calc(${theme.mainSpacing}) ${theme.mainSpacing}`};
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    fill: #fff;
  }
  &:before {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.colors.colorPrimary};
    bottom: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

const StyledClose = styled.button`
  border: none;
  background: none;
  color: #fff;
  font-size: 3rem;
`;

const BannerIos = () => {
  const [isIos, setIsIos] = useState<boolean>();
  const [isComponentVisible, setComponentVisible] = useState<boolean>(false);

  // Detects if device is on iOS
  const isIosFn = useCallback(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }, []);
  // Detects if device is in standalone mode
  //@ts-ignore for ios
  const isInStandaloneMode = useCallback(() => 'standalone' in window.navigator && window.navigator.standalone, []);

  useEffect(() => {
    // Checks if should display install popup notification:
    if (isIosFn() && !isInStandaloneMode()) {
      setIsIos(true);
    }
    const displayed = !!localStorage.getItem('banner-displayed');
    setComponentVisible(!displayed);
  }, []);

  const hide = () => {
    localStorage.setItem('banner-displayed', 'true');
    setComponentVisible(false);
  };

  return (
    <StyledBanner>
      {isComponentVisible && (
        <>
          {isIos && (
            <StyledBannerText>
              <div>
                <strong>Zainstaluj aplikację</strong>. Naciśnij <Icon icon="ios-share" inline /> i "dodaj do ekranu
                początkowego"
              </div>
              <StyledClose onClick={hide} className="banner-close">
                &times;
              </StyledClose>
            </StyledBannerText>
          )}
        </>
      )}
    </StyledBanner>
  );
};
export default BannerIos;
