import React from 'react';
import styled from 'styled-components';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.colorPrimary};
  margin: 5px 0;
`;

const ShareIcons = styled.div`
  display: flex;
`;

const ShareIcon = styled.div`
  margin: 0 5px;
`;

const SharePanel = () => (
  <ShareContainer>
    <ShareIcons>
      <ShareIcon>
        <FacebookShareButton url={window.location.href}>
          <FacebookIcon round size={24} />
        </FacebookShareButton>
      </ShareIcon>
      <ShareIcon>
        <LinkedinShareButton url={window.location.href}>
          <LinkedinIcon round size={24} />
        </LinkedinShareButton>
      </ShareIcon>
      <ShareIcon>
        <FacebookMessengerShareButton appId="117498033570802" url={window.location.href}>
          <FacebookMessengerIcon round size={24} />
        </FacebookMessengerShareButton>
      </ShareIcon>
      <ShareIcon>
        <WhatsappShareButton url={window.location.href}>
          <WhatsappIcon round size={24} />
        </WhatsappShareButton>
      </ShareIcon>
    </ShareIcons>
  </ShareContainer>
);

export { SharePanel };
