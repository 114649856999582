import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { gtMedia } from '../../../config/config';

interface StyledProps {
  children: React.ReactNode;
  noPadding?: boolean;
  dark?: boolean;
  noLeft?: boolean;
  alignEnd?: boolean;
  startView?: boolean;
}

const StyledMain = styled.main<StyledProps>`
  min-height: calc(100vh - 200px);
  padding: ${({ theme, noPadding }) =>
    noPadding ? 0 : `${theme.headerHeightDesktop} 0 calc(${theme.mainSpacing} * 8)`};
  ${({ dark }) =>
    dark &&
    css`
      background-color: theme.colors.colorPrimary;
    `}
  ${({ alignEnd }) =>
    alignEnd &&
    css`
      display: flex;
      align-items: flex-end;
    `}
  ${({ startView }) =>
    startView &&
    css`
      padding-top: ${({ theme }) => theme.headerHeight};
    `}

  ${gtMedia('md')} {
    padding-left: ${({ theme }) => theme.desktopNavWidth};
  }
  ${({ noLeft }) =>
    noLeft &&
    css`
      ${gtMedia('md')} {
        padding-left: 0;
      }
    `};

  ${({ alignEnd }) =>
    alignEnd &&
    css`
      ${gtMedia('md')} {
        display: flex;
        align-items: flex-start;
      }
    `};

  ${({ startView }) =>
    startView &&
    css`
      ${gtMedia('md')} {
        padding-top: 0;
      }
    `}
`;

type Props = StyledProps;

const Main: FC<Props> = ({ children, dark, alignEnd, noLeft, noPadding, startView }) => (
  <StyledMain startView={startView} noPadding={noPadding} dark={dark} noLeft={noLeft} alignEnd={alignEnd}>
    {children}
  </StyledMain>
);

export default Main;
