import React, { FC } from 'react';
import styled from 'styled-components';
import { __ } from '../../../helpers/i18n';

interface Props {
  children?: React.ReactNode;
  text?: string | React.ReactNode;
  id?: string;
  name?: string;
  required?: boolean;
}

const StyledInputLabel = styled.label`
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} / 2)`};
  font-size: 1.1rem;
  font-weight: 700;
  display: block;
`;

// eslint-disable-next-line import/prefer-default-export
export const InputLabel: FC<Props> = ({ children, text, required, id, name }) => (
  <StyledInputLabel htmlFor={id || name}>
    {text}
    {children}
    {required && ' *'}
  </StyledInputLabel>
);
