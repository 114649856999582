import { FieldType, TableSchema } from "../types";

const messageStatusToString: {
    [key: number]: string
} = {
    0: 'Email',
    1: 'SMS',
    2: 'Push',
    3: 'FB',
    4: 'Linkedin',
};

export const communicationTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "createdDt",
            field: "createdDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date,
        },
        {
            name: "communication.sendingDt",
            field: "sendingDt",
            searchable: true,
            sortable: true,
            type: FieldType.Date,
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "communication.messageType",
            field: "messageType",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            searchableValues: messageStatusToString,
            customValue: value => messageStatusToString[value.messageType],
        },
        {
            name: "communication.isPlanned",
            field: "isPlanned",
            searchable: true,
            sortable: true,
            type: FieldType.Boolean,
        }
    ]
};
