import { Link } from 'react-router-dom';
import styled from'styled-components';

export const StyledLinkButton = styled(Link)`
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;