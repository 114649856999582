import React, { FC } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ButtonsContainer } from '../../../components/Layout';
import { Button } from '../../../components/Common';
import ModalContent from '../../../components/Common/Modal/ModalContent';
import ModalTitle from '../../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../../components/Common/Modal/ModalHeader';
import { modal } from '../../../actions';

interface Props {
  confirmCallback: () => void;
  hideModal: () => void;
  children?: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
}
const ChangeBoundType: FC<Props> = ({ children, confirmCallback, hideModal }) => {
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="application.confirmation" />
      </ModalHeader>
      <p className="confirmation-paragraph">
        Zmiana wiązania w platformie spowoduje utratę dotychczasowych opiekunów.<br />
        Czy chcesz zmienić pole po jakim następuje wiązanie użytkowników?
      </p>
      {children}
      <ButtonsContainer>
        <Button small variant="secondary" onClick={hideModal} text="application.no" />
        <Button small text="application.yes" onClick={confirmCallback} />
      </ButtonsContainer>
    </ModalContent>
  );
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ hideModal: modal.hideModal }, dispatch);

export default connect(null, mapDispatchToProps)(ChangeBoundType);
