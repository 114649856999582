import React from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ModalState } from '../types/modal';

interface ModalPayload {
  content: React.ReactNode;
  isClosable: boolean;
  isFullscreen: boolean;
}

interface ShowModal {
  type: 'SHOW_MODAL';
  payload: ModalPayload;
}

interface HideModal {
  type: 'HIDE_MODAL';
}

interface PrepareModal {
  type: 'PREPARE_MODAL';
}

interface UnprepareModal {
  type: 'UNPREPARE_MODAL';
}

interface ModalLoading {
  type: 'MODAL_LODAING';
  payload: boolean;
}

type Action = ShowModal | HideModal | PrepareModal | UnprepareModal | ModalLoading;

const initialState: ModalState = {
  isVisible: false,
  isClosable: true,
  isLoading: false,
  isPrepared: false,
  isUnprepared: false,
  isFullscreen: false,
  content: null,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'PREPARE_MODAL':
      return { ...state, isPrepared: true, content: null };
    case 'MODAL_LODAING':
      return { ...state, isLoading: action.payload };
    case 'UNPREPARE_MODAL':
      return { ...state, isPrepared: false };
    case 'SHOW_MODAL':
      disableBodyScroll(document.querySelector('.modal-wrapper'));
      return {
        ...state,
        isVisible: true,
        content: action.payload.content,
        isClosable: action.payload.isClosable,
        isFullscreen: action.payload.isFullscreen,
      };
    case 'HIDE_MODAL':
      clearAllBodyScrollLocks();
      return {
        ...state,
        isVisible: false,
        isClosable: true,
        isPrepared: true,
      };
    default:
      return state;
  }
};
