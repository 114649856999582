import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantSegment } from 'app-types';
import { apiService } from '../services/apiService';

export interface AvailableModules {
    list: TenantSegment[] | null; // for user
}

const initialState: AvailableModules = {
    list: null
}

export const getAvailableModules = createAsyncThunk(
    'getAvailableModules',
    async () => apiService(`tenant/available-segments`, 'GET'),
);

export const availableModules = createSlice({
    name: 'availableModules',
    initialState,
    reducers: {},
    extraReducers: {
        [getAvailableModules.fulfilled as any]: (
            state,
            action: PayloadAction<TenantSegment[]>,
        ) => {
            state.list = action.payload;
        },
        [getAvailableModules.rejected as any]: (state) => {
            state.list = null
        },
    },
});


export default availableModules.reducer;
