/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import {
  ArticleRes,
  ApiErrorCode,
  SelectCategory,
  SelectOption,
  VideoStatus,
  IdAndTitleRes,
  ArticlePlace,
  CustomSelectOption,
} from 'app-types';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import EditorJS, { API, OutputData } from '@editorjs/editorjs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { RootState } from '../../reducers';
import { useApi } from '../../services/useApi';
import { errorNotification, successNotification } from '../../actions/notifications';
import { history } from '../../App';
import { MultimediaFile } from '../../types/photo';
import { __ } from '../../helpers/i18n';
import { apiUrl } from '../../config/apiUrl';
import useSegmentHeader from '../useSegmentHeader';

interface UseArticle {
  article?: ArticleRes | null;
  loading: boolean;
  isVideoAvailable: boolean;
  articleContent?: string | API | EditorJS | OutputData;
  getPhoto: () => string;
  getArticle: () => void;
  handleCreate: (data: SelectOption) => void;
  handleSave: (data: any) => void;
  getCategories: () => void;
  articleCategories: SelectOption[];
  articleQuizzes: SelectOption[];
  articlePlaces: CustomSelectOption<ArticlePlace>[];
  submit: (
    payload: any,
    photos: MultimediaFile[],
    videos: MultimediaFile[],
    content?: string | API | OutputData | EditorJS,
    editId?: string,
    videoFn?: string | null,
  ) => void;
}

export interface ContentWysiwyg {
  type: string;
  data: string;
}

interface Props {
  methods?: UseFormReturn<FieldValues>;
}

export const useArticle = ({ methods }: Props): UseArticle => {
  const TIMEOUT = 600000;
  const dispatch = useDispatch();
  const { api, loading } = useApi({ timeout: TIMEOUT, methods });
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [articleCategories, setArticleCategories] = useState<SelectOption[]>([]);
  const [articleQuizzes, setArticleQuizzes] = useState<SelectOption[]>([]);
  const platformId = useSelector((state: RootState) => state?.platformConfig?.config?._id);
  useSegmentHeader('articles');

  const { articleId } = useParams<{ articleId: string }>();
  const [article, setArticle] = useState<ArticleRes | null>();
  const [articleContent, setContent] = useState<API | EditorJS | OutputData | string>();
  const handleSave = async (data: API | EditorJS) => {
    const savedData = await data.saver.save();
    setContent(savedData);
  };

  const articlePlaces = [
    {
      value: ArticlePlace.KnowledgeBase,
      label: 'Warto wiedzieć',
    },
    {
      value: ArticlePlace.News,
      label: __('application.news'),
    },
    {
      value: ArticlePlace.Promoted,
      label: __('application.promoted'),
    },
    {
      value: ArticlePlace.Standalone,
      label: __('application.articleStandalone'),
    },
    {
      value: ArticlePlace.Articles,
      label: 'Aktualności',
    },
  ];

  const submit = (
    payload: any,
    photos: MultimediaFile[],
    videos: MultimediaFile[],
    content?: string | API | OutputData | EditorJS,
    editId?: string,
    videoFn?: string | null,
  ) => {
    const formData = new FormData();
    let data: any = {};
    const { isDedicated, ...rest } = payload;
    if (isDedicated) {
      data = rest;
    } else {
      data = { ...rest, forGroups: [] };
    }
    if (videoFn) data.videoFn = videoFn;
    formData.append('body', JSON.stringify(data));

    if (content) {
      const contentFetch = typeof content === 'string' ? content : JSON.stringify(content);
      formData.append('content', contentFetch);
    } else {
      formData.append('content', JSON.stringify(article?.content ?? ''));
    }

    photos.forEach((photo) =>
      photo.file.forEach((singleFile) => formData.append(photo.fieldName, singleFile)),
    );
    videos.forEach((video) =>
      video.file.forEach((singleFile) => formData.append('video', singleFile)),
    );

    api({
      method: editId ? 'PATCH' : 'POST',
      path: editId ? `article/edit/${editId}` : 'article/add',
      onSuccess: () => {
        if (editId) {
          dispatch(successNotification(__('application.articleEditedProperly')));
        } else {
          dispatch(successNotification(__('application.articleCreatedProperly')));
        }
        history.push('/dashboard/articles/manage');
      },
      data: formData,
    });
  };

  const getQuizzes = () => {
    api<IdAndTitleRes[]>({
      method: 'GET',
      path: 'quiz/list',
      onSuccess: (data) => {
        const quizzesOptions = data.map((quiz) => ({
          value: quiz._id,
          label: quiz.title,
        }));
        setArticleQuizzes(quizzesOptions);
      },
    });
  };

  const getCategories = () => {
    api<SelectCategory[]>({
      method: 'GET',
      path: 'category/all',
      onSuccess: (data) => {
        setArticleCategories(
          data.map((category: SelectCategory) => ({
            value: category._id,
            label: category.title,
          })),
        );
      },
      onError: (error: ApiErrorCode) => {
        dispatch(errorNotification(error));
      },
    });
  };

  const handleCreate = (data: SelectOption) => {
    api({
      method: 'POST',
      path: 'category/add',
      data: { title: data, tenantId: platformId },
      onSuccess: () => getCategories(),
    });
  };

  const getArticle = () => {
    setArticle(null);
    api<ArticleRes>({
      method: 'GET',
      path: `article/${articleId}`,
      onSuccess: (data) => {
        setArticle(data);
      },
    });
  };

  const getPhoto = () => `${apiUrl}article/image/articleFn/${articleId}`;
  const getVideoStatus = () => {
    api<{ status: VideoStatus }>({
      method: 'GET',
      path: `article/check-video-status/${articleId}`,
      onSuccess: (data) => {
        if (data?.status === VideoStatus.Complete) setIsVideoAvailable(true);
      },
    });
  };

  useEffect(() => {
    if (article) {
      const { content, videoPrvLink } = article;
      if (videoPrvLink) getVideoStatus();
      setContent(content);
    }
  }, [article]);

  useEffect(() => {
    getQuizzes();
    if (articleId) {
      getArticle();
    }
  }, [articleId]);
  return {
    article,
    getPhoto,
    getArticle,
    submit,
    handleCreate,
    articleCategories,
    articleQuizzes,
    loading,
    getCategories,
    handleSave,
    articleContent,
    isVideoAvailable,
    articlePlaces,
  };
};
