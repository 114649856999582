import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { FormSelect } from '..';
import { useTenant } from '../../../hooks/useTenant';
import { RootState } from '../../../reducers';
import { __ } from '../../../helpers/i18n';
import { CustomSelectOption } from 'app-types';

// eslint-disable-next-line import/prefer-default-export
export const SelectTenantAndLoadModules: FC = () => {
  const { tenants, handleLoadActiveModuleAndSelectTenant } = useTenant();
  const platform = useSelector((state: RootState) => state?.platformConfig?.config);

  return (
    <FormSelect
      id="selectTenant"
      defaultValue={{
        label: platform.name,
        value: platform._id
      }}
      label="Wybierz platformę"
      name="selectTenant"
      required
      handleChange={(e) => handleLoadActiveModuleAndSelectTenant(e)}
      options={tenants.map((tenantOption) => ({
        value: {
          id: tenantOption._id,
          host: tenantOption.host,
        },
        label: tenantOption.text,
      }) as CustomSelectOption<{ id: string, host: string }>)}
    />
  );
};
