import {FieldType, TableSchema} from "../types";

const statusToString: {
    [key: number]: string;
} = {
    0: "Opened",
    1: "InProgress",
    2: "Closed",
};

export const contactManagementRequestTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "id",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        },
        {
            name: "createdDt",
            field: "createdDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "status",
            field: "status",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
            searchableValues: statusToString,
            customValue: value => statusToString[value.status]
        }
    ]
};
