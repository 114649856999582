import React, { FC } from 'react';
import './Alert.scss';
import styled from 'styled-components';
import { __ } from '../../../helpers/i18n';

interface AlertProps {
  type: string;
  text: string;
  simple?: boolean;
  notification?: boolean;
}

const StyledAlert = styled.div`
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
`;

const Alert: FC<AlertProps> = ({ type, simple, notification, text }) => (
  <StyledAlert className={`alert alert-${type} ${simple ? 'alert-simple' : ''} ${notification ? 'notification' : ''}`}>
    {__(text)}
  </StyledAlert>
);

export default Alert;
