import { FieldType, TableSchema } from '../types';

export const documentTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'application.id',
      field: '_id',
      searchable: false,
      sortable: false,
      type: FieldType.Text
    },
    {
      name: 'application.title',
      field: 'title',
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: 'application.description',
      field: 'description',
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: 'application.date',
      field: 'createdDt',
      searchable: false,
      sortable: true,
      type: FieldType.Date
    }
  ]
};
