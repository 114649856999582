import React, { FC } from 'react';
import styled from 'styled-components';
import { gtMedia } from '../../../../config/config';
import { __ } from '../../../../helpers/i18n';

const StyledHeaderTitle = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.colorDark};
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  flex: 1;
  padding: ${({ theme }) => `calc(${theme.mainSpacing}) ${theme.mainSpacing}`};
  order: 2;
  flex: 0 0 100%;
  text-align: center;

  ${gtMedia('md')} {
    order: 1;
    flex: 1;
    text-align: left;
    padding: ${({ theme }) => `0 ${theme.mainSpacing}`};
  }
`;

interface HeaderTitleProps {
  title?: string;
}

const HeaderTitle: FC<HeaderTitleProps> = ({ title }) => <StyledHeaderTitle>{__(title)}</StyledHeaderTitle>;

export default HeaderTitle;
