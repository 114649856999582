import ApiService from '../services/api-service';

export const fetchApiListData = (url: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'FETCH_API_LIST_DATA_SUCCESS',
    'FETCH_API_LIST_DATA_FAILED',
    null,
    null,
    url,
    'LOADING_API_LIST',
  );
};
export const fetchApiListInfiniteData = (url: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'FETCH_API_LIST_INFINITE_DATA_SUCCESS',
    'FETCH_API_LIST_INFINITE_DATA_FAILED',
    null,
    null,
    url,
    'LOADING_API_LIST',
  );
};

export const refreshApiList = () => (dispatch: any) => dispatch({ type: 'REFRESH_API_LIST' });

export const clearApiList = () => (dispatch: any) => {
  dispatch({ type: 'CLEAR_API_LIST' });
};
