import React, { FC } from 'react';
import { apiUrl } from '../../../../config/apiUrl';
import { Wrapper, Text } from '../..';
import { StyledInfoBox } from '../../InfoBox';
import styled from 'styled-components';
import { usePlatform } from '../../../../hooks/usePlatform';
import { CardProps, StyledImage, StyledLink, StyledInfo } from '..';

const PointsBox = styled(StyledInfoBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.colorTextSecondary};
`;

export const QuizCard: FC<CardProps> = ({ to, points, extraText, title, imgUrl }) => {
  const { tenantQuery } = usePlatform();
  return (
    <StyledLink to={to || '#'}>
      <Wrapper overflow="hidden" position="relative">
        <StyledImage src={`${apiUrl}${imgUrl}/${tenantQuery}`} alt="" />
        {points && (
          <PointsBox>
            <Text fontSize="medium" fontWeight={800} color="#ffffff">
              {points}
            </Text>
          </PointsBox>
        )}
      </Wrapper>
      <StyledInfo>
        {title && <Text as='h2' mb={1}>{title}</Text>}
        {extraText && <Text fontSize="small">{extraText}</Text>}
      </StyledInfo>
    </StyledLink>
  );
};