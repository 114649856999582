import React, { FC } from 'react';
import Icon from '../Icon';

interface Props {
  path: string;
  icon?: string;
  name?: string;
  blank?: boolean;
}

const ExternalLink: FC<Props> = ({ path, icon, name, blank }) => (
  <a target={blank ? '__blank' : undefined} href={path}>
    {icon && <Icon externalLink big icon={icon} />}
    {name && <p>name</p>}
  </a>
);

export default ExternalLink;
