export enum DashboardElement {
  ARTICLE,
  TEMPORARY_ACTION,
}

export interface DashboardElementRes {
  type: DashboardElement;
  id: string;
  title: string;
  createdDt: Date;
}
