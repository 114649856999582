import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledSectionHeader = styled.div<{ marginB?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ marginB }) =>
    marginB &&
    css`
      margin-bottom: 10px;
    `}
`;
