import React, { FC } from 'react';
import styled from 'styled-components';
import { apiUrl } from '../../../../config/apiUrl';
import { Wrapper, Text, Button } from '../..';
import { usePlatform } from '../../../../hooks/usePlatform';
import { CardProps, StyledImage, StyledInfo } from '..';
import { formatDate } from '../../../../utils/formatDate';
import { EventData } from '../../../../components/Styled/StyledEventData';
import { ButtonsContainer } from '../../../Layout';

const EventTitle = styled.h2`
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: ${({ theme }) => `${theme.mainSpacing}`};
`;

// eslint-disable-next-line import/prefer-default-export
export const EventCard: FC<CardProps> = ({
  to,
  date,
  place,
  endDate,
  startDate,
  extraText,
  title,
  imgUrl,
}) => {
  const { tenantQuery } = usePlatform();
  return (
    <Wrapper display="block">
      {imgUrl && <StyledImage src={`${apiUrl}${imgUrl}/${tenantQuery}`} alt="" />}
      <StyledInfo>
        {date && <EventData date text={formatDate(date)} />}
        {(startDate || endDate) && (
          <EventData
            date
            text={
              startDate && endDate
                ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                : startDate
                ? `Data rozpoczęcia: ${formatDate(startDate)}`
                : endDate
                ? `Data zakończenia: ${formatDate(endDate)}`
                : ''
            }
          />
        )}
        {place && <EventData place text={place} />}
        {title && <EventTitle>{title}</EventTitle>}
        {extraText && <Text fontSize="small">{extraText}</Text>}
        <ButtonsContainer>
          <Button uppercase to={to} text="event.checkDetails" bg="secondary" />
        </ButtonsContainer>
      </StyledInfo>
    </Wrapper>
  );
};
