import {Email, Form, IsRequired, MinLength, Pwd, Pwd2} from "itf-validation/dist";

@Form({ post: 'user/change-password/:userId' })
export class ChangePasswordDto {
    constructor () {
        const formName: string = 'ChangePasswordDto'
    }
    @Pwd()
    pwd1: string;

    @Pwd2('pwd1')
    pwd2: string;
}
