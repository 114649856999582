import React, { FC, useEffect } from 'react';
import { Container, Row } from '../../Layout';
import { PageTitle, Spinner } from '../../Common';
import { PromotedArticle } from '../PromotedArticle';
import { NewsArticles } from '../NewsArticles';
import StyledColumn from './StyledColumn';
import { useDashboard } from '../../../hooks/dashboard/useDashboard';

const HomeNewsDesktop: FC = () => {
  const { items, loading, getNewsSection } = useDashboard();
  useEffect(() => {
    getNewsSection();
  }, []);
  if (loading) return <Spinner />;

  if (items.length > 0) {
    return (
      <section>
        <Container>
          <Row mb={1}>
            <StyledColumn width={1}>
              <PageTitle>Aktualności</PageTitle>
            </StyledColumn>
            <PromotedArticle items={items.slice(0, 1)} />
            <NewsArticles items={items.slice(1, 3)} />
          </Row>
        </Container>
      </section>
    );
  } else {
    return null;
  }
};

export default HomeNewsDesktop;
