import { useDispatch } from 'react-redux';
import { successNotification } from '../actions/notifications';
import { history } from '../App';
import { __ } from '../helpers/i18n';
import ApiService from '../services/api-service';


interface UseTransaction {
    transactionAction: (actionType: 'reject' | 'accept', transcationId: string) => void;
}

const useTransaction = (): UseTransaction => {
    const dispatch = useDispatch();

    const transactionAction = async (actionType: 'reject' | 'accept', transactionId: string) => {
      const endpointUrl = `transaction/${actionType}-transaction/${transactionId}`;
      const notificationText = __(`application.transaction-${actionType}`);
  
      await ApiService.callFetch('PATCH', endpointUrl, () => {
        dispatch(successNotification(notificationText));
        history.push('/dashboard/transactions/list');
      });
    };

    return { transactionAction }

}

export default useTransaction;