import React, { FC } from 'react';
import { ContentRemover } from '..';
import { StyledFileWrapper } from '../../Styled';

interface Props {
  children: React.ReactNode;
  deletePhotos?: () => void;
}

const PhotoWrapper: FC<Props> = ({ children, deletePhotos }) => (
  <StyledFileWrapper>
    {children}
    {deletePhotos && <ContentRemover handleRemove={deletePhotos} bgColor="#ffffff" />}
  </StyledFileWrapper>
);

export default PhotoWrapper;
