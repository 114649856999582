import React, { FC } from 'react';
import { Wrapper } from '..';

interface Props {
  children: React.ReactNode
}

// eslint-disable-next-line import/prefer-default-export
export const ActionIconsWrapper: FC<Props> = ({ children }) => (
  <Wrapper width={1} display="flex" flexDirection="row" justifyContent="flex-end">
    {children}
  </Wrapper>
);

