import React from 'react';
import { Column, Container, Row } from '../../Layout';
import Icon from '../Icon'; 
import { __ } from '../../../helpers/i18n';
import useTransaction from '../../../hooks/useTransaction';
import styled, { useTheme } from 'styled-components';

interface StyledProps {
  pLeft0?: boolean;
  bgColor?: string;
}

const TransactionBox = styled(Column)<StyledProps>`
    text-align: center;
    ${({pLeft0}) => pLeft0 ? 'padding-left: 0' : 'padding-right: 0'};
    background-color: ${({bgColor}) => bgColor && bgColor};
`


interface Props extends StyledProps {
  transactionId: string;
}

const TransactionVerifyMenu = ({ transactionId }: Props) => {

  const { transactionAction } = useTransaction();
  const { colors } = useTheme()

  return (
    <section className="transaction-verify-actions">
      <Container>
        <Row>
          <TransactionBox onClick={() => transactionAction('reject', transactionId)} role="button" autoWidth>
            <div className="content">
              <Icon icon="transaction-reject" />
              Odrzuć
            </div>
          </TransactionBox>
          <TransactionBox bgColor={colors.colorLight} onClick={() => transactionAction('accept', transactionId)} role="button" autoWidth>
            <div className="content">
              <Icon icon="transaction-accept" />
              Zaakceptuj
            </div>
          </TransactionBox>
        </Row>
      </Container>
    </section>
  );
};

export default TransactionVerifyMenu;
