/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { SelectOption } from 'app-types';
import styled from 'styled-components';
import { Wrapper } from '../../../../../components/Common';

interface ArticleCategoriesProps {
  categories: SelectOption[];
}

const StyledCategory = styled.div`
  color: ${({ theme }) => theme.colors.colorLight};
  margin-right: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `calc(${theme.mainSpacing})`};
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  border-radius: ${({ theme }) => `${theme.borderRadius / 2}px`};
`;

// eslint-disable-next-line import/prefer-default-export
export const ArticleCategories: FC<ArticleCategoriesProps> = ({ categories }) => (
  <Wrapper display="flex" flexWrap="wrap" width={1} pb={2}>
    {categories.map(({ label }: SelectOption) => (
      <StyledCategory>#{label}</StyledCategory>
    ))}
  </Wrapper>
);
