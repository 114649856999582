import React, { FC } from 'react';
import { FaEye } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { IconActionButton } from '..';

interface PreviewIconProps {
  path: string;
}

// eslint-disable-next-line import/prefer-default-export
export const PreviewIcon: FC<PreviewIconProps> = ({ path }) => {
  const { colors } = useTheme();
  return (
    <IconActionButton to={path} bgColor={colors.colorSecondary} title="communication.preview">
      <FaEye size={18} color="#fff" />
    </IconActionButton>
  );
};
