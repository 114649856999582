import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { gtMedia } from '../../../config/config';

interface StyledProps {
  grid?: boolean;
}

const StyledFormContainer = styled.div<StyledProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  position: relative;

  /* form {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1.4rem;
    flex-wrap: wrap;
  } */

  .form-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  /* ${(props) =>
    props.grid &&
    css`
      form {
        flex-direction: row;
        margin: 0 calc(-1 * ${props.theme.mainSpacing});

        > * {
          flex: 0 0 100%;
          padding: 0 props.theme.mainSpacing;
        }
      
      
      .form-field,
      .input-container {
        flex: 0 0 100%;
        padding: 0 props.theme.mainSpacing;
      }
      ${gtMedia('sm')} {
        .form-field,
        .input-container {
          flex: 0 0 50%;
        }
      }
      ${gtMedia('md')} {
        .form-field,
        .input-container {
          flex: 0 0 25%;
        }
      }
      .field-type-checkbox,
      .field-type-wysywig,
      .field-type-fileDragDrop {
        flex: 0 0 100%;
      }
    `} */
`;

interface Props {
  children: React.ReactNode;
  grid?: boolean;
  className?: string;
}

const FormContainer: FC<Props> = ({ children, grid }) => (
  <StyledFormContainer grid={grid}>{children}</StyledFormContainer>
);

export default FormContainer;
