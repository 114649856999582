import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

export const formatDateTime = (val: string | Date) => {
  try {
    const d = new Date(val);
    return format(d, 'EEEE, do LLLL HH:mm', { locale: pl });
  } catch (e) {
    return '-';
  }
};
