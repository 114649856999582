import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';


interface StyledProps extends LayoutProps, SpaceProps, FlexboxProps {
  buttonsContainer?: boolean;
  maxWidth?: any;
  w100?: boolean;
}

export const StyledRow = styled.div<StyledProps>`
  width: auto;
  display: flex;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: auto;
    `}
  ${({ buttonsContainer, theme }) =>
    buttonsContainer &&
    css`
      margin: 0;
      &.small-button {
        button {
          width: auto;
        }
      }
      button {
        width: 100%;
        margin-bottom: calc(${theme.mainSpacing} * 2)};
      }
    `}
    ${layout}
    ${space}
    ${flexbox}
`;

interface Props extends StyledProps {
  children: React.ReactNode;
  as?: 'div' | 'ul' | 'section';
  onClick?: () => void;
}

const Row: FC<Props> = ({
  children,
  onClick,
  as,
  maxWidth,
  ...props
}) => (
  <StyledRow
    as={as || 'div'}
    onClick={onClick}
    maxWidth={maxWidth}
    {...props}
  >
    {children}
  </StyledRow>
);

export default Row;

Row.defaultProps = {
  flexWrap: 'wrap',
  flexDirection: 'row',
  display: 'flex',
  marginLeft: -(1),
  marginRight: -(1)
}