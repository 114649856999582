import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectOption, Permission, FilterTable } from 'app-types';

export type SelectFilterTable = {
  label: string;
  value: FilterTable;
  permission?: Permission;
};

export interface FilterByState {
  filterBy: SelectFilterTable;
}

const initialState: FilterByState = {
  filterBy: { label: 'Wszyscy', value: 'all' },
};

const filterBySlice = createSlice({
  name: 'filterBy',
  initialState,
  reducers: {
    setFilterBy: (state, action: PayloadAction<SelectFilterTable>) => {
      state.filterBy = action.payload;
    },
    clearFilterBy: (state) => {
      state.filterBy = initialState.filterBy;
    },
  },
});

export const { setFilterBy, clearFilterBy } = filterBySlice.actions;

export default filterBySlice.reducer;
