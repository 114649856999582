import { UserRole } from '../user';
import { SelectOption } from '../form';

export enum MessageType {
  Mail,
  SMS,
  Push,
  FB,
  Linkedin,
}

export interface CommunicationTableRes {
  _id: string;
  createdDt: Date;
  sendingDt: Date;
  title: string;
  messageType: MessageType;
  isPlanned: boolean;
}

export enum CommunicationTableType {
  Planned,
  History,
}

export interface PushMessageReq {
  title: string;
  message: string;
  receivers: Receivers[];
  link?: string;
  sendingDt?: Date;
}

export interface CommunicationRes {
  _id: string;
  title?: string;
  content?: string;
  message?: string;
  createdDt: Date;
  sendingDt: Date;
  mailFn: string;
  isPlanned: boolean;
  receivers?: any;
  receiver?: string;
  result: string;
  hasBeenSent: boolean;
  tenantId: string;
  messageType: MessageType;
  design?: string;
}

export interface TemplateReq {
  title?: string;
  content: string;
  design?: string;
  messageType: MessageType;
  destination?: TemplateDest;
}

export interface TemplateRes {
  _id: string;
  title?: string;
  createdDt: Date;
  content: string;
  design?: string;
  messageType: MessageType;
  destination?: TemplateDest;
  tenantId: string;
}

export interface TemplateTableRes {
  _id: string;
  createdDt: Date;
  title?: string;
  messageType: MessageType;
  destination?: TemplateDest;
}

export enum TemplateDest {
  Registration = 'registration',
  Invitation = 'invitation',
  AddToContest = 'add_to_contest',
  ProfileAccepted = 'profile_accepted',
  ProfileRejected = 'profile_rejected',
}

export type FilterCommunicationTable = CommunicationTableType;

export interface SmsInfoReq {
  message: string;
  receivers: Receivers[];
  sendingDt?: Date;
}

export interface Receivers {
  label: string;
  value: string;
  __isNew__?: boolean;
}

export interface MailInfoReq {
  body: MailInfoBody;
  content: string;
  design: string;
}

export interface MailInfoBody {
  title: string;
  sendingDt: Date;
  receivers: Receivers[];
}

export interface SMSReportReq {
  startDt: Date;
  endDt: Date;
}

export interface SMSReportRes {
  _id: string;
  sender: string;
  content: string;
  totalMessages: string;
  totalDelivered: string;
  totalNotDelivered: string;
}

export interface SMSStatRes {
  total: number;
  delivered: number;
  returned: number;
}
