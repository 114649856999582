import { FieldType, TableSchema } from "../types";

export const quizListSchema: TableSchema = {
    countPerPage: 9,
    fields: [
        {
            name: "Identyfikator",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Tytuł",
            field: "title",
            searchable: false,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Opis",
            field: "description",
            searchable: false,
            sortable: false,
            type: FieldType.Text,
        },
        {
            name: "Data rozpoczęcia",
            field: "startDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            oppositeSortDir: true,
            type: FieldType.Date
        },
        {
            name: "Data zakończenia",
            field: "endDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date
        },
        {
            name: "Ilość punktów",
            field: "maxPoints",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        }
    ]
};
