import React, { FC } from 'react';
import { Answer } from 'app-types';

import { OpenQuestionAnswer } from '../../../views/Dashboard/Quizzes/Quiz/OpenQuestionAnswer';
import { Row, Column } from '../../Layout';
import { Text, Image, VideoPlayer, QuizButton, ResponsiveImage } from '..';
import { apiUrl } from '../../../config/apiUrl';
import { useQuizContext } from '../../../hooks/quizzes/QuizProvider';

// eslint-disable-next-line import/prefer-default-export
export const QuestionsContent: FC = () => {
  const { quizContext } = useQuizContext();

  const { question, quizId, correctAnswers, wrongAnswers, checkedAnswers, getAnswer } = quizContext;
  const isButtonChosen = (ansI: number) =>
    checkedAnswers.includes(ansI) && (correctAnswers.length > 0 || wrongAnswers.length > 0);
  const isQuestionAnswered = () => correctAnswers.length !== 0 || wrongAnswers.length !== 0;

  return (
    <>
      <Row mb={1}>
        <Column alignContent="center" justifyContent="center" width={[1, null, null, 1 / 2]}>
          {question && <Text fontSize="normal">{question?.question}</Text>}
        </Column>
        {question?.questionFn && (
          <Column mb={2} width={1}>
            <ResponsiveImage
              mobileRatio={14 / 9}
              src={`${apiUrl}quiz/image/questionPictureFn/${quizId}/${question._id}`}
            />
          </Column>
        )}
        {question?.videoFn && <VideoPlayer videoSrc={question.videoFn} />}
      </Row>
      <Row>
        {question?._id && (!question?.answer || question.isQuestionWithScale) && (
          <OpenQuestionAnswer questionId={question._id} />
        )}
        {!question?.isQuestionWithScale &&
          question?.answer &&
          question.answer.map((an: Answer, ansI: number) => {
            return (
              <Column width={[1, 1, 1 / 2, 1 / 4]} mb={1} key={an._id}>
                <QuizButton
                  checked={checkedAnswers.includes(ansI)}
                  isCorrect={correctAnswers.includes(an._id)}
                  isWrong={isButtonChosen(ansI) && wrongAnswers.includes(an._id)}
                  onClick={!isQuestionAnswered() ? () => getAnswer(ansI) : undefined}
                >
                  {an?.content && (
                    <Text textAlign="center" color="colorDark" padding="15px 40px">
                      {an?.content}
                    </Text>
                  )}
                  {an?.answerFn && (
                    <Image
                      src={`${apiUrl}quiz/image/answerPictureFn/${quizId}/${question._id}/${an._id}`}
                    />
                  )}
                </QuizButton>
              </Column>
            );
          })}
      </Row>
    </>
  );
};
