import { StaticEntity } from 'app-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

import { useApi } from '../services/useApi';

interface UseStaticSites {
  staticContent: StaticEntity | null;
  getContent: (path: string) => void;
  platformId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useStaticSites = (): UseStaticSites => {
  const { api } = useApi();
  const platformId = useSelector((state: RootState) => state.platformConfig?.config?._id);
  const [staticContent, setStaticContent] = useState<StaticEntity | null>(null);

  const getContent = (path: string) => {
    api<StaticEntity>({
      method: 'GET',
      path: `${path}/${platformId}`,
      onSuccess: (data) => {
        setStaticContent(data);
      },
    });
  };

  return { staticContent, getContent, platformId };
};
