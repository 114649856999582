import styled, { css } from 'styled-components';
import { Column } from '../../../Layout';

interface StyledColumnProps {
  side?: boolean;
  newsBox?: boolean;
  last?: boolean;
}

const StyledColumn = styled(Column)<StyledColumnProps>`
  ${({ side }) =>
    side &&
    css`
      padding: ${({ theme }) => `0 (${theme.mainSpacing} * 0.5)`};
    `}
  ${({ newsBox }) =>
    newsBox &&
    css`
      margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
    `}
  ${({ last }) =>
    last &&
    css`
      margin-bottom: 0;
    `}
`;

export default StyledColumn;
