import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegistrationType, SMSStatRes, SelectOption, TenantSegment, AcceptType } from 'app-types';
import { apiService } from '../services/apiService';

export interface ActiveModules {
  _id: string;
  text: string;
}

export interface SelectedTenantAndActiveModules {
  activeModules: TenantSegment[] | null;
  boundType: SelectOption | null;
  infoIcon: string | null;
  registrationType: RegistrationType | null;
  privacyAcceptType: AcceptType | null;
  rulesAcceptType: AcceptType | null;
  registrationFields: SelectOption[] | null;
  smsStats: SMSStatRes | null;
  host: string | null;
  invitationMail: SelectOption | null;
}

export const getTenantActiveModules = createAsyncThunk(
  'getTenantActiveModules',
  async (tenantId: string) => await apiService(`tenant/active-modules/${tenantId}`, 'GET'),
);

export const selectedTenantAndActiveModules = createSlice({
  name: 'selectedTenantAndActiveModules',
  initialState: {} as SelectedTenantAndActiveModules,
  reducers: {},
  extraReducers: {
    [getTenantActiveModules.fulfilled as any]: (
      state,
      action: PayloadAction<{
        segments: TenantSegment[];
        boundType: SelectOption;
        infoIcon: string | null;
        registrationType: RegistrationType | null;
        rulesAcceptType: AcceptType | null;
        privacyAcceptType: AcceptType | null;
        registrationFields: SelectOption[] | null;
        smsStats: SMSStatRes | null;
        host: string | null;
        invitationMail: SelectOption | null;
      }>,
    ) => {
      state.activeModules = action.payload.segments;
      state.boundType = action.payload.boundType;
      state.infoIcon = action.payload.infoIcon;
      state.registrationType = action.payload.registrationType;
      state.rulesAcceptType = action.payload.rulesAcceptType;
      state.privacyAcceptType = action.payload.privacyAcceptType;
      state.registrationFields = action.payload.registrationFields;
      state.smsStats = action.payload.smsStats;
      state.host = action.payload.host;
      state.invitationMail = action.payload.invitationMail;
    },
    [getTenantActiveModules.rejected as any]: (state) => {
      state.activeModules = null;
      state.boundType = null;
      state.infoIcon = null;
      state.registrationType = null;
      state.privacyAcceptType = null;
      state.rulesAcceptType = null;
      state.registrationFields = null;
      state.smsStats = null;
      state.host = null;
      state.invitationMail = null;
    },
  },
});

export default selectedTenantAndActiveModules.reducer;
