import React, { FC, useEffect, useMemo } from 'react';

import { Container } from '../../../../components/Layout';
import { StatisticsBox, ActionsBoxesContainer } from '../../../../components/Common';
import { store } from '../../../../config/store';
import { theme } from '../../../../config/theme';
import { useBasicStatistics } from '../../../../hooks/useBasicStatistics';
import { usePlatform } from '../../../../hooks/usePlatform';

// eslint-disable-next-line import/prefer-default-export
export const BasicStatistics: FC = () => {
  const colors = store.getState().platformConfig?.colors;
  const { getStats, stats } = useBasicStatistics();
  const { platformId } = usePlatform();

  useEffect(() => {
    console.log(platformId);
    if (platformId) {
      getStats(platformId);
    }
  }, [platformId]);

  const statiscticsBoxes = useMemo(
    () => [
      {
        _id: 0,
        number: stats?.allInDB,
        icon: 'data-base',
        description: 'statisctics.usersInDataBaseAmount',
        backgroundColor: colors?.colorPrimary ?? theme.colors.colorPrimary,
      },
      {
        _id: 1,
        number: stats?.registered,
        icon: 'registered',
        description: 'statisctics.usersRegisteredNumber',
        backgroundColor: colors?.colorSecondary ?? theme.colors.colorSecondary,
      },
      {
        _id: 2,
        number: stats?.toVerify,
        icon: 'verify',
        description: 'statisctics.usersToVerificationAmount',
        backgroundColor: colors?.colorBackground ?? theme.colors.colorBackground,
      },
      {
        _id: 3,
        number: stats?.smsStats?.total,
        icon: 'messages',
        description: 'statisctics.sendedThisMonthSMSAmount',
        backgroundColor: colors?.colorBackground ?? theme.colors.colorBackground,
      },
    ],
    [stats],
  );
  return (
    <section>
      <Container>
        <ActionsBoxesContainer>
          {statiscticsBoxes.map(({ _id, number, description, icon, backgroundColor }) => {
            return (
              (number || number === 0) && (
                <StatisticsBox
                  key={_id}
                  number={number}
                  icon={icon}
                  backgroundColor={backgroundColor}
                  description={description}
                />
              )
            );
          })}
        </ActionsBoxesContainer>
      </Container>
    </section>
  );
};
