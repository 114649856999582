import styled, { css } from 'styled-components';

interface StyledSelectProps {
  multiple?: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const StyledSelect = styled.select<StyledSelectProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  background-position: ${({ theme }) => `right calc(${theme.mainSpacing} * 1.5) center`};
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: ${({ theme }) => theme.colors.colorInput};
  background-image: url('/assets/images/arrow-down.svg');
  border: transparent;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  outline: none;
  height: 49px;
  padding: ${({ theme }) => `calc(2 * ${theme.mainSpacing}) ${theme.mainSpacing}`};
  color: ${({ theme }) => theme.colors.colorDark};
  display: block;
  width: 100%;
  line-height: 1.5rem;
  transition: $transition-time background-color;
  padding-top: 0;
  padding-bottom: 0;

  ${({ multiple }) =>
    multiple &&
    css`
      background-color: ${({ theme }) => theme.colors.colorInput};
      width: 100%;
      height: auto;
      padding: ${({ theme }) => `calc(${theme.mainSpacing} * .5)`};
      margin: 0;
      border: none;
      -moz-appearance: menulist;
      -webkit-appearance: menulist;
      appearance: menulist;
    `}
`;
