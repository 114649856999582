import React, { FC, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import styled from 'styled-components';
import { IconButton } from '..';

interface Props {
  pageCount: number;
  initialPage?: number;
  page?: number;
  onPageChange?: (page: number) => void;
}

const StyledPaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
  .pagination {
    margin: ${({ theme }) => theme.mainSpacing} 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    font-weight: 300;
    li {
      min-width: 20px;
      cursor: pointer;
      margin: 0 ${({ theme }) => `calc(${theme.mainSpacing} / 2) calc(${theme.mainSpacing} / 4)`};
      .button-link {
        color: ${({ theme }) => theme.colors.colorPrimary};
        background-color: ${({ theme }) => theme.colors.colorLight};
        border-color: ${({ theme }) => theme.colors.colorPrimary};
        width: 30px;
        padding: 5px 8px;
        height: 30px;
        border-radius: ${({ theme }) => `${theme.borderRadius}px`};

        &.previous {
          width: auto;
          height: auto;
        }
      }
    }
    .previous,
    .next {
      width: 100%;
      button {
        padding: 0;
        height: 30px;
        width: 30px;
        margin-bottom: 0;
        border-radius: ${({ theme }) => `${theme.borderRadius}px`};
      }
      max-width: 30px;
    }
    @include gt-sm {
      li {
        margin: 0 ${({ theme }) => `calc(${theme.mainSpacing} / 2)`};
      }
      .previous,
      .next {
        width: auto;
      }
    }
    .button-link {
      color: ${({ theme }) => theme.colors.colorPrimary};
      background-color: ${({ theme }) => theme.colors.colorLight};
      border-color: ${({ theme }) => theme.colors.colorPrimary};
      width: 30px;
      padding: 0 ${({ theme }) => `calc(${theme.mainSpacing} / 4)`};
      height: 30px;
      border-radius: ${({ theme }) => `${theme.borderRadius}px`};

      &.previous {
        width: auto;
        height: auto;
      }
    }
    .selected {
      .button-link {
        color: ${({ theme }) => theme.colors.colorPrimary};
        background-color: ${({ theme }) => theme.colors.colorLight};
        border: 1px solid ${({ theme }) => theme.colors.colorPrimary};
      }
    }
    .button-link-active {
      color: ${({ theme }) => theme.colors.colorLight};
      background: ${({ theme }) => theme.colors.colorPrimary};
    }
    .disabled {
      .button {
        background-color: ${({ theme }) => theme.colors.colorDisabled};
        cursor: not-allowed;
        box-shadow: none;
      }
    }
  }
`;

const Pagination: FC<Props> = ({ page, pageCount, initialPage, onPageChange }: Props) => {
  useEffect(() => {
    if (typeof onPageChange === 'undefined') return;
    if (page && page > pageCount) {
      onPageChange(pageCount - 1);
    }
  }, [pageCount]);

  if (pageCount < 2) return null;

  return (
    <StyledPaginationWrapper>
      <ReactPaginate
        containerClassName="pagination pagination-mobile"
        pageLinkClassName="button button-link"
        activeLinkClassName="button-link-active"
        previousLinkClassName="button-active-primary"
        nextLinkClassName="button-active-primary"
        disabledClassName="button-link disabled"
        onPageChange={({ selected }: { selected: number }) =>
          onPageChange && onPageChange(selected + 1)
        }
        previousLabel={
          <IconButton disabled={page === 1} width="30px" height="30px">
            <IoIosArrowBack size={18} color="#fff" />
          </IconButton>
        }
        nextLabel={
          <IconButton disabled={pageCount === page}>
            <IoIosArrowForward size={18} color="#fff" />
          </IconButton>
        }
        initialPage={(initialPage || 1) - 1}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </StyledPaginationWrapper>
  );
};
export default Pagination;
