import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getPersistConfig } from 'redux-deep-persist';

import rootReducer from '../reducers';


const config = getPersistConfig({
  key: 'root',
  storage, // whatever storage you use
  whitelist: ['platformConfig.tenantId', 'user'],
  version: 1,
  rootReducer, // your root reducer must be also passed here
})

const persistedReducer = persistReducer(config, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        'PREPARE_MODAL',
        'SHOW_MODAL',
        'HIDE_MODAL',
        'UNPREPARE_MODAL',
        'SUCCESS_NOTIFICATION',
        'HIDE_NOTIFICATION',
      ],
    },
  }),
});

export const persistor = persistStore(store);
