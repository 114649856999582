import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled, { css } from 'styled-components';
import { gtMedia } from '../../../config/config';
import { IconPlaceholder } from './IconPlaceholder';

interface StyledIconProps {
    toLeft?: boolean;
    disabled?: boolean;
}

const StyledIcon = styled.div<StyledIconProps>`
  display: flex;
  justify-content: ${({ toLeft }) => (toLeft ? 'flex-start' : 'center')};
  align-items: center;
  font-size: 1.5rem;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  svg {
    width: 15px;
    height: 15px;
    transition: fill ${({ theme }) => theme.transitionTime};
    fill: ${({ theme }) => theme.colors.colorDark};
  }
`;

interface StyledIconWrapperProps {
    big?: boolean;
    externalLink?: boolean;
    inputIcon?: boolean;
    carouselIcon?: boolean;
    pwdIcon?: boolean;
    userIcon?: boolean;
    navIcon?: boolean;
    size?: number;
    color?: string;
    inline?: boolean;
}

const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  ${({ inline }) =>
        inline &&
        css`
      display: inline-flex;
    `}
  ${({ big }) =>
        big &&
        css`
      ${StyledIcon} svg {
        width: 20px;
        height: 20px;
      }
    `}
  ${({ size }) =>
        size &&
        css`
      ${StyledIcon} svg {
        height: ${size}px;
      }
    `}
  ${({ color }) =>
        color &&
        css`
      ${StyledIcon} svg {
        fill: ${color};
      }
    `}
  ${({ pwdIcon }) =>
        pwdIcon &&
        css`
      position: absolute;
      right: 21px;
      bottom: 16px;
      z-index: 1;
    `}
  ${({ externalLink }) =>
        externalLink &&
        css`
      margin: ${({ theme }) => `1 * ${theme.mainSpacing} 0`};

      {StyledIcon} {
          svg {
            height: 30px;
            width: 30px;
          }
        }
    `}
  ${({ carouselIcon }) =>
        carouselIcon &&
        css`
      width: 30px;
      .custom-svg {
        stroke-width: 5px;
        stroke: #000;
      }
      ${StyledIcon} {
        svg {
          width: 35px;
          height: 35px;

          ${gtMedia('md')} {
            width: 30px;
            height: 30px;
          }
      }
    }
  }
    `}

  ${({ inputIcon }) =>
        inputIcon &&
        css`
      position: absolute;
      left: 15px;
      top: $main-spacing;
      padding-right: ${({ theme }) => `calc(${theme.mainSpacing} * 1.2)`};
      width: 1.2rem;
      height: 1.2rem;
      z-index: 1;
    `}
    ${({ userIcon }) =>
        userIcon &&
        css`
      position: absolute;
      right: 0;
      top: 50%;
      ${StyledIcon} {
        svg {
          height: 150px;
          transform: translate(10%, -50%);
          width: auto;
          fill: ${({ theme }) => theme.colors.colorLight};
          opacity: 0.1;
        }
      }
    `}
`;

interface Props extends StyledIconWrapperProps, StyledIconProps {
    icon?: string;
    faIcon?: IconProp;
    onClickHandler?: () => void;
    customPath?: string;
}

const Icon: FC<Props> = ({
    icon,
    faIcon,
    pwdIcon,
    carouselIcon,
    inputIcon,
    externalLink,
    toLeft,
    userIcon,
    big,
    onClickHandler,
    size,
    color,
    disabled,
    customPath,
    inline,
}) => {     
    return <StyledIconWrapper
        userIcon={userIcon}
        inputIcon={inputIcon}
        pwdIcon={pwdIcon}
        externalLink={externalLink}
        big={big}
        size={size}
        color={color}
        inline={inline}
    >
        <StyledIcon
            toLeft={toLeft}
            role={onClickHandler && 'button'}
            onClick={onClickHandler}
            disabled={disabled}
        >
            {faIcon && <FontAwesomeIcon icon={faIcon} />}
            {icon && <ReactSVG
                src={`/assets/images/${icon}.svg`}
                loading={() => <IconPlaceholder big={big} size={size} />}
            />}
        </StyledIcon>
    </StyledIconWrapper>
}

export default Icon;
