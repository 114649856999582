import { BasicUserData } from '../user';

export type VerifyStatus = 'accept' | 'reject' | 'delivered';

export enum RealizationStatus {
  NoAction,
  Accepted,
  Rejected,
  Delivered,
}

export interface TempAddRealizationReq {
  [x: string]: any;
  photosFn?: string[];
  temporaryActionId: string;
}

export interface AddRealizationReq {
  [x: string]: any;
  photosFn?: string[];
}

export interface RealizationTableRes {
  _id: string;
  createdDt: Date;
  temporaryActionTitle: string;
  firstName: string;
  lastName: string;
  status: RealizationStatus;
  photosFn: string[];
}

export interface RealizationContentValue {
  id: string;
  label: string;
  value: string;
}

export interface RealizationRes {
  _id: string;
  content: RealizationContentValue[];
  createdDt: Date;
  photosFn: string[];
  status: RealizationStatus;
  temporaryActionId: string;
  temporaryActionTitle: string;
  tenantId: string;
  user: BasicUserData;
  userId: string;
}
