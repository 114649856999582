import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { UserRole } from 'app-types';
import { setHeader } from '../slices/headerSlice';
import { RootState } from '../reducers';
import { __ } from '../helpers/i18n';

/**
 *
 * @param back - if not given will back to previous page
 */

const useSegmentHeader = (
    module: 'articles' | 'news' | 'quizzes' | 'groups' | 'surveys' | 'time-actions' | 'attachments' | 'contact-management' | 'products' | 'presentations' | 'custom-banners' | 'custom-tiles' | 'events' | 'users' | 'ads',
    part?: 'add' | 'edit' | 'list' | 'pointHistory' | 'categories' | 'editCategory' | 'ranking' | 'quizResults' | 'view',
    back?: string,
    noLeft?: boolean,
    hideOnMobile?: boolean,
    hide?: boolean,
): void => {
    const dispatch = useDispatch();
    const availableModules = useSelector((state: RootState) => state.availableModules.list);
    const availableSelectedTenantModules = useSelector((state: RootState) => state.selectedTenantAndModules.activeModules);
    const user = useSelector((state: RootState) => state.user.details);

    const found = (user?.role === UserRole.SuperAdmin ? availableSelectedTenantModules : availableModules)?.find(m => m.segment.text === module);

    let title = part ? __(`segment.${part}`) : '';
    if (found) title = `${found.segmentNameForTenant} ${part ? `- ${__(`segment.${part}`)}` : ''}`;

    useEffect(() => {
        dispatch(
            setHeader({
                title,
                back: back ?? '',
                noLeft: noLeft ?? false,
                hideOnMobile: hideOnMobile ?? false,
                hide: hide ?? false,
            }),
        );
    }, [title, back, noLeft, hideOnMobile, hide, dispatch]);
};
export default useSegmentHeader;
