import React, { FC } from 'react';
import styled from 'styled-components';
import { space, 
  typography, 
  flexbox, 
  layout,
  TypographyProps, 
  SpaceProps, 
  FlexboxProps, 
  LayoutProps,
  position,
  PositionProps,
  } from 'styled-system';


export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface StyledProps extends PositionProps, TypographyProps, SpaceProps, FlexboxProps, LayoutProps {
  hidden?: boolean;
  autoWidth?: boolean;
  auto?: boolean;
}

export const StyledColumn = styled.div<StyledProps>`
  background-color: transparent;
  outline: none;
  border: none;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  position: relative;
  ${layout}
  ${space}
  ${typography}
  ${flexbox} 
  ${position}
`;



interface Props extends StyledProps {
  children: React.ReactNode;
  onClick?: () => void;
  as?: 'div' | 'li' | 'ul' | 'main' | 'section';
  role?: string;
  auto?: boolean;
}

const Column: FC<Props> = ({
  onClick,
  hidden,
  role,
  autoWidth,
  padding = '0 10px',
  auto,
  children,
  as,
  ...props
}) => (
  <>
  <StyledColumn
    hidden={hidden}
    autoWidth={autoWidth}
    auto={auto}
    role={role}
    onClick={onClick}
    padding={padding}
    as={as ?? 'div'}
    {...props}
  >
    {children}
  </StyledColumn>
  </>
);

export default Column;
