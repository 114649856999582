import React, { FC, ReactNode } from 'react';
import { Row, Column } from '../../Layout';

interface Props {
  form: ReactNode;
  header: ReactNode;
  noMargin?: boolean;
}

const DownContent: FC<Props> = ({ form, header, noMargin }) => (
  <Row maxWidth>
    <Column width={1}>{header}</Column>
    <Column width={1}>{form}</Column>
  </Row>
);

export default DownContent;
