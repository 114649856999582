import React, { FC, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Spinner } from '..';
import { Column } from '../../Layout';
import {
  ModeratorManage,
  ModeratorManageDispatch,
} from '../../../hooks/moderator/ModeratorManageProvider';
import { FormSelect } from '../FormSelect';
import { ModeratorModule } from '../../../hooks/moderator/useModeratorManageReducer';

// eslint-disable-next-line import/prefer-default-export
export const ModeratorRoleSetting: FC = () => {
  const moderator = useContext(ModeratorManage);
  const dispatch = useContext(ModeratorManageDispatch);
  const { watch } = useFormContext();
  const [hidePermissionInput, setHidePermissionInput] = useState(false);

  const watchModeratorModules = watch('moderatorModules');

  useEffect(() => {
    const modules: ModeratorModule[] | null = watchModeratorModules;
    if (modules?.length) {
      if (!modules.some((module) => module?.modulesAction && module.modulesAction?.length > 0)) {
        setHidePermissionInput(true);
      } else {
        setHidePermissionInput(false);
      }
    }
  }, [watchModeratorModules]);

  if (!moderator || !dispatch) return <Spinner />;

  return (
    <>
      <Column width={1}>
        <FormSelect
          label="Wybierz moduły zarządzane przez użytkownika o roli: Moderator"
          required
          name="moderatorModules"
          isMulti
          options={moderator?.state.allModules ? moderator?.state.allModules : []}
          handleChange={(e) => moderator.handleModuleChange(e)}
        />
      </Column>
      {!hidePermissionInput && (
        <Column width={1}>
          <FormSelect
            label="Wybierz uprawnienia użytkownika o roli: Moderator"
            name="moderatorPermissions"
            required
            isMulti
            options={moderator?.state.allActions ? moderator?.state.allActions : []}
            handleChange={(e) => moderator.handleActionChange(e)}
          />
        </Column>
      )}
    </>
  );
};
