import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router';
import { Spinner } from '../../../components/Common';
import { RootState } from '../../../reducers';
import { handleLogout } from '../../../slices/userSlice';

const LoginInvitationRedirect = () => {
    const dispatch = useDispatch();
    const { invitationId } = useParams<{ invitationId: string }>();
    const user = useSelector((state: RootState) => state.user.details);

    useEffect(() => {
        if (user) {
            dispatch(handleLogout());
        }
    }, []);

    if (user) return <Spinner />
    return <Redirect to={`/login/${invitationId}`} />;
};

export default LoginInvitationRedirect;
