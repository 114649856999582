import { FieldType, TableSchema } from "../types";

export const customTileTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "Identyfikator",
      field: "id",
      searchable: false,
      sortable: false,
      type: FieldType.Text
    },
    {
      name: "Nazwa",
      field: "title",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "URL",
      field: "path",
      searchable: true,
      sortable: true,
      type: FieldType.Url
    }
  ]
};
