import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface Props {
  children: React.ReactNode;
  preview?: boolean;
  isMobile?: boolean;
  isHeader?: boolean;
}

const StyledTableR = styled.tr<{ isMobile?: boolean, isHeader?: boolean }>`
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} / 1.5)`};

  ${({ isHeader }) => !isHeader &&
  css`
  &:nth-child(odd) {
    background: ${({ theme }) => lighten(0.3, theme.colors.colorDisabled)};
  }
  `}
  &.highlight {
    /* background: rgba($color-primary, 0.5); */
  }

  &.dehighlight {
    opacity: 0.5;
    background: #fff;
  }
  ${({ isMobile }) => isMobile &&
  css`
    display: flex;
    flex-flow: column;
  `}
`;

// eslint-disable-next-line import/prefer-default-export
export const TableR = ({ children, isMobile, isHeader }: Props) => {

  return (
    <StyledTableR isMobile={isMobile} isHeader={isHeader}>
      {children}
    </StyledTableR>
  );
};
