import { LoggerModuleType } from '../../types';
import { FieldType, TableSchema } from '../types';

const moduleTypeToString: {
  [key: number]: string;
} = {
  0: 'Article',
  1: 'Attachment',
  2: 'Auth',
  3: 'Communication',
  4: 'Contest',
  5: 'CustomBanner',
  6: 'CustomTile',
  7: 'Group',
  8: 'User',
  9: 'Catalog',
  10: 'Quiz',
  11: 'Presentation',
  12: 'TemporaryAction',
  13: 'Facebook',
  14: 'Linkedin',
  15: 'Twitter',
  16: 'ContactForm',
  17: 'Ranking',
};

export const logsTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'Identyfikator',
      field: 'id',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'Data',
      field: 'timestamp',
      searchable: false,
      sortable: true,
      defaultSort: true,
      type: FieldType.Date,
    },

    {
      name: 'Typ',
      field: 'meta.moduleType',
      searchable: false,
      sortable: true,
      type: FieldType.Text,
      searchableValues: moduleTypeToString,
      customValue: value => moduleTypeToString[value.meta.moduleType],
      omitSelect: true,
    },
    {
      name: 'Akcja',
      field: 'message',
      searchable: false,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'Email',
      field: 'meta.email',
      searchable: true,
      sortable: true,
      type: FieldType.Email,
      omitSelect: true,
    },
    {
      name: 'Tel',
      field: 'meta.tel',
      searchable: true,
      sortable: true,
      type: FieldType.Tel,
      omitSelect: true,
    },
    {
      name: 'Dodatkowe informacje',
      field: 'meta',
      searchable: false,
      sortable: false,
      type: FieldType.Custom,
    },
  ],
};

export const getLogsSchemaForType = LoggerModuleType => {
  const schema = logsTableSchema;
};
