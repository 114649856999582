import React, { useRef } from 'react';
import { API, OutputData, ToolConfig, ToolConstructable } from '@editorjs/editorjs'
import { createReactEditorJS } from 'react-editor-js'
import { EditorJSi18n } from '../../../config/editorJs-i18n';
import { EditorCore } from '@react-editor-js/core';

const ReactEditorJS = createReactEditorJS()

interface Props {
  value: OutputData;
  onChange?: (data: API) => void;
  autofocus?: boolean;
  readOnly?: boolean;
  i18n?: EditorJSi18n;
  placeholder?: string;
  tools?: {
    [key: string]: ToolConstructable | ToolConfig;
  };
  ref?: React.MutableRefObject<EditorCore | null>
}

export const EditorJsContainer = ({ value, onChange, i18n, ref, placeholder, autofocus, readOnly, tools }: Props) => {
  const handleInitialize = React.useCallback((instance: EditorCore) => {
    if (ref) ref.current = instance
  }, [ref])

  return <ReactEditorJS
    onInitialize={handleInitialize}
    defaultValue={value}
    onChange={onChange}
    autofocus={autofocus}
    readOnly={readOnly}
    tools={tools}
    i18n={i18n}
    placeholder={placeholder}
  />

}