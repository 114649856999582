import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  title: string;
  back: string;
  noLeft: boolean;
  hideOnMobile: boolean;
  hide: boolean;
}

const initialState: HeaderState = {
  title: '',
  back: '',
  noLeft: false,
  hideOnMobile: false,
  hide: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<HeaderState>) => {
      state.title = action.payload.title;
      state.back = action.payload.back;
      state.noLeft = action.payload.noLeft;
      state.hideOnMobile = action.payload.hideOnMobile;
      state.hide = action.payload.hide;
    },
  },
});

export const { setHeader } = headerSlice.actions;

export default headerSlice.reducer;
