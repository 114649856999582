import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { layout } from 'styled-system';
import { Spinner } from '..';
import { usePlatform } from '../../../hooks/usePlatform';

interface StyledProps {
  round?: boolean;
  svg?: boolean;
  contain?: boolean;
  auto?: boolean;
  avatar?: boolean;
  height?: any;
}

interface Props extends StyledProps {
  image?: string;
  absolute?: boolean;
  src?: string;
  alt?: string;
  isPreview?: boolean;
}

const StyledImage = styled.img<StyledProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;

  ${({ avatar }) =>
    avatar &&
    css`
      height: 100%;
    `}
  ${({ contain }) =>
    contain &&
    css`
      object-fit: contain;
    `}
  ${({ auto }) =>
    auto &&
    css`
      object-fit: unset;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    `}

  ${({ round }) =>
    round &&
    css`
      border-radius: ${({ theme }) => theme.borderRound};
    `}

    ${layout}
`;

const Image: FC<Props> = ({
  avatar,
  contain,
  image,
  round,
  auto,
  absolute,
  src,
  alt,
  isPreview,
  ...props
}) => {
  const { tenantQuery, platformId } = usePlatform();
  const srcLink = isPreview ? `${src}` : `${src}/${tenantQuery}`;
  const imageLink = isPreview
    ? `/assets/images/${image}`
    : `/assets/images/${image}/${tenantQuery}`;

  if (platformId === '') {
    return <Spinner />;
  }
  return (
    <StyledImage
      auto={auto}
      contain={contain}
      avatar={avatar}
      round={round}
      src={srcLink || (absolute ? image : imageLink)}
      alt={alt || ''}
      {...props}
    />
  );
};

export default Image;
