import React from 'react';
import { SelectOption, UserRole } from 'app-types';
import { __ } from '../../../helpers/i18n';
import { FormSelect } from '../FormSelect';

const availableRoles: SelectOption[] = [
  {
    label: 'Moderator',
    value: UserRole.Moderator,
  },
  {
    label: 'Super użytkownik',
    value: UserRole.SuperUser,
  },
  {
    label: 'Użytkownik',
    value: UserRole.User,
  },
];

interface Props {
  name: string;
}

const UserRolesSelect = ({ name }: Props) => {
  return (
    <FormSelect
      name={name}
      label={__('communication.chooseReceiversGroup')}
      isMulti
      options={availableRoles}
    />
  );
};

export { UserRolesSelect };
