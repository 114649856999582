import { FieldType, TableSchema } from '../types';

const statusToString: {
  [key: number]: string;
} = {
  0: 'Oczekujące',
  1: 'Zaakceptowane',
  2: 'Odrzucone',
  3: 'Dostarczone'
};

export const realizationTableSchema: TableSchema = {
  countPerPage: 9,
  fields: [
    {
      name: 'id',
      field: 'id',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'photosFn',
      field: 'photosFn',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'createdDt',
      field: 'createdDt',
      searchable: false,
      sortable: true,
      type: FieldType.Date,
      defaultSort: true,
    },
    {
      name: 'contestTitle',
      field: 'temporaryActionTitle',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'application.firstName',
      field: 'user.firstName',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'application.lastName',
      field: 'user.lastName',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'Status',
      field: 'status',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
      searchableValues: statusToString,
      customValue: value => statusToString[value.status],
    },
  ],
};
