import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  space,
  typography,
  color,
  flexbox,
  layout,
  border,
  TypographyProps,
  BorderProps,
  ColorProps,
  PositionProps,
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  variant,
} from 'styled-system';
import { __ } from '../../../helpers/i18n';

interface StyledButtonProps
  extends TypographyProps,
    SpaceProps,
    ColorProps,
    FlexboxProps,
    BorderProps,
    PositionProps,
    LayoutProps {
  bRadius?: string;
  round?: boolean;
  disabled?: boolean;
  uppercase?: boolean;
  small?: boolean;
  reverse?: boolean;
  onClick?: any;
  variant?: string;
  fontSize?: string;
  fontWeight?: number;
  type?: 'submit' | 'button' | 'reset';
  borderColor?: string;
  blank?: boolean;
  zIndex?: number;
  id?: string;
}

const StyledLink = styled(Link)`
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledA = styled.a`
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: ${({ bRadius, theme }) => (bRadius || `${theme.borderRadius}px`) ?? 'unset'};
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.15);
  transition: 0.4s;
  font-family: ${({ theme }) => theme.fontPrimary};

  ${layout}
  ${space}
  ${border}
  ${typography}
  ${flexbox}
  ${variant({
    scale: 'buttons',
    variants: {
      primary: {
        bg: 'colorPrimary',
      },
      secondary: {
        bg: 'colorSecondary',
      },
      danger: {
        bg: 'colorDanger',
      },
      success: {
        bg: 'colorSuccess',
      },
      additional: {
        bg: 'colorTextSecondary',
      },
    },
  })}
  &:hover {
    cursor: pointer;
    filter: ${({ disabled }) => (disabled ? 'brightness(100%)' : 'brightness(105%)')};
  }
  ${({ round }) =>
    round &&
    css`
      border-radius: 50%;
    `}
  ${({ small }) =>
    small &&
    css`
      padding: ${({ theme }) => theme.mainSpacing};
    `}
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `}
    ${color}
    * {
    color: ${({ color }) => color ?? '#fff'};
    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: backgroundColor;
      `};
    font-size: ${({ fontSize }) => fontSize ?? '1.4rem'};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.colorDisabled};

      &:hover {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: ${({ theme }) => theme.colors.colorDisabled};
      }
    `}
`;

interface Props extends StyledButtonProps {
  children?: React.ReactNode;
  to?: string;
  text?: string;
  dataCy?: string;
  color?: string;
  backgroundColor?: string;
}

export const Button: FC<Props> = ({
  text,
  bRadius,
  disabled,
  onClick,
  children,
  to,
  reverse,
  round,
  small,
  color,
  borderColor,
  uppercase,
  backgroundColor,
  blank,
  zIndex,
  id,
  dataCy,
  ...props
}) => {
  return (
    <StyledButton
      id={id}
      onClick={onClick}
      disabled={disabled}
      bRadius={bRadius}
      round={round}
      small={small}
      zIndex={zIndex}
      reverse={reverse}
      borderColor={borderColor}
      uppercase={uppercase}
      color={reverse ? backgroundColor : color ?? '#fff'}
      backgroundColor={disabled ? 'colorDisabled' : backgroundColor}
      data-cy={dataCy}
      {...props}
    >
      {to && !blank && <StyledLink to={to} />}
      {to && blank && <StyledA href={to} target="__blank" />}
      {text && __(text)}
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: 'primary',
  pt: '15px',
  pb: '15px',
  pr: '40px',
  pl: '40px',
  width: [1, null, 'auto', 'auto'],
  border: '1px solid transparent',
};
