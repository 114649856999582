import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { SingleColumnsConfig } from 'app-types';
import { ButtonsContainer } from '../../Layout';
import { Button, FormField, FormSelect, Spinner, UserRolesSelect } from '../../Common';
import ModalContent from '../../Common/Modal/ModalContent';
import ModalTitle from '../../Common/Modal/ModalTitle';
import ModalHeader from '../../Common/Modal/ModalHeader';
import { modal } from '../../../actions';
import { __ } from '../../../helpers/i18n';
import { useApi } from '../../../services/useApi';
import { usersTableSchema } from 'app-tables';
import { useAdditionalFields } from '../../../hooks/useAdditionalFields';
import { RootState } from '../../../reducers';
import { successNotification } from '../../../actions/notifications';
import { refreshApiTable } from '../../../actions/api-table';
import styled from 'styled-components';

const StyledFieldGroup = styled.div`
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} / 2)`};
  padding-bottom: ${({ theme }) => `calc9${theme.mainSpacing} / 2)`};
  border-bottom: 1px solid #eee;
`;

const TableColumnNames = () => {
  const dispatch = useDispatch();
  const platformId = useSelector((state: RootState) => state?.platformConfig?.config?._id);

  const methods = useForm({
    mode: 'onBlur',
  });
  const { api, loading } = useApi({ methods });
  const { findFormByName, additionalFields } = useAdditionalFields();

  const loadData = async () => {
    await api<SingleColumnsConfig>({
      path: `column-config/${platformId}/users`,
      method: 'GET',
      onSuccess: (data) => {
        methods.reset(data);
      },
    });
  };

  const saveData = async (data: { [key: string]: string }) => {
    await api<SingleColumnsConfig, { columns: { [key: string]: string } }>({
      path: `column-config/${platformId}/users`,
      method: 'PATCH',
      onSuccess: (data) => {
        dispatch(successNotification('Poprawnie zapisano konfigurację'));
        dispatch(refreshApiTable());
        dispatch(modal.hideModal());
      },
      data: {
        columns: data,
      },
    });
  };

  useEffect(() => {
    findFormByName('register');
    loadData();
  }, []);

  const allFields = usersTableSchema.fields.map((field) => ({
    name: field.name,
    field: field.field,
  }));

  additionalFields.forEach((field) => {
    if (!field?.groupBy) {
      const existing = allFields.find((f) => f.field === `additionalInfo.${field.name}`);
      if (!existing) {
        allFields.push({
          name: field.label,
          field: `additionalInfo.${field.name}`,
        });
      }
    }
  });

  return (
    <ModalContent light>
      {loading && <Spinner overlay />}
      <ModalHeader>
        <ModalTitle title="table.columnSettings" />
      </ModalHeader>
      <p>{__('table.columnSettingsDescription')}</p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(saveData)}>
          {allFields.map((field) => (
            <StyledFieldGroup key={field.field}>
              <FormField
                type="text"
                name={`${field.field}.name`}
                placeholderText="Własna nazwa"
                labelText={field.name}
              />
              <UserRolesSelect name={`${field.field}.forRoles`} />
            </StyledFieldGroup>
          ))}

          <ButtonsContainer>
            <Button
              small
              onClick={() => dispatch(modal.hideModal)}
              text="application.cancel"
              variant="secondary"
            />
            <Button type="submit" small text="application.submit" />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </ModalContent>
  );
};

export { TableColumnNames };
