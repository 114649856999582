import {FieldType, TableSchema} from "../types";

export const contestListForUserTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "Identyfikator",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Data rozpoczęcia",
            field: "startDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date,
        },
        {
            name: "Data zakończenia",
            field: "endDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
        },
        {
            name: "Nazwa wydarzenia",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Opis",
            field: "description",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Adres",
            field: "address",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
        }
    ]
};
