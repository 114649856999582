import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image } from '..';
import { gtMedia } from '../../../config/config';
import { apiUrl } from '../../../config/apiUrl';
import { RootState } from '../../../reducers';

const StyledBrand = styled.div<{ big?: boolean }>`
  display: flex;
  /* align-self: flex-end; */
  align-items: center;
  margin: 0;
  height: 100%;
  width: auto;
  max-width: 180px;

  a {
    display: block;
    height: 100%;
  }

  ${gtMedia('md')} {
    order: 1;
    flex: 0 0 100%;
    justify-content: flex-end;
  }
  ${({ big }) =>
    big &&
    css`
      max-width: 275px;
    `}
`;

interface Props {
  big?: boolean;
}

const Brand: FC<Props> = ({ big }) => {
  const platformId = useSelector((state: RootState) => state?.platformConfig?.config?._id);
  return (
    <StyledBrand big={big}>
      {/* <StyledBrandIcon icon="logotyp" onClickHandler={() => history.push('/dashboard')} /> */}

      {platformId && (
        <Link to="/">
          <Image auto src={`${apiUrl}tenant/logoFn/${platformId}`} />
        </Link>
      )}
    </StyledBrand>
  );
};

export default Brand;
