import { FieldType, TableSchema } from '../types';

export const presentationTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: 'Identyfikator',
      field: 'id',
      searchable: false,
      sortable: false,
      type: FieldType.Text,
    },
    {
      name: 'Tytuł',
      field: 'startTitle',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
    {
      name: 'Podtytuł',
      field: 'startSubtitle',
      searchable: true,
      sortable: true,
      type: FieldType.Text,
    },
  ],
};
