import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MultiplePhotoPayload, OrderQuantityReq } from 'app-types';
import { store } from '../config/store';
import { apiService } from '../services/apiService';
import { mapBy } from '../utils/mappers';

interface InitialState {
  details: MultiplePhotoPayload[] | null;
  loading: boolean;
}

export const deleteAll = createAsyncThunk('multiplePhoto/deleteAll', async () => {
  if (store.getState() && store.getState()?.photos?.details) {
    await apiService(
      'facebook/multiple-photos',
      'DELETE',
      undefined,
      undefined,
      false,
      mapBy(store.getState().photos.details as MultiplePhotoPayload[], 'link'),
    );
  }
});

export const multiplePhotoSlice = createSlice({
  name: 'multiplePhotoSlice',
  initialState: {} as InitialState,
  reducers: {
    setPhotos: (state, action: PayloadAction<MultiplePhotoPayload[]>) => {
      if (state.details) {
        state.details = [...state.details, ...action.payload];
      } else {
        state.details = action.payload;
      }
    },
    removePhoto: (state, action: PayloadAction<{ id: string }>) => {
      if (state.details) {
        const filtered = state.details?.filter((photo) => photo._id !== action.payload.id);
        state.details = filtered;
      }
    },
    clearAllPhoto: (state) => {
      state.details = null;
    },
  },
  extraReducers: {
    [deleteAll.fulfilled as any]: (state) => {
      state.details = null;
    },
  },
});

export const { setPhotos, removePhoto, clearAllPhoto } = multiplePhotoSlice.actions;

export default multiplePhotoSlice.reducer;
