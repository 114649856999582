import { CustomSelectOption, Permission, UserRole, SelectOption } from 'app-types';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { Button } from '..';
import { gtMedia, ltMedia } from '../../../config/config';
import { inputSelectStyles } from '../../../helpers/additionalStyles';
import { __ } from '../../../helpers/i18n';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { RootState } from '../../../reducers';
import { SelectFilterTable } from '../../../views/Dashboard/Users/Manage';
import { Column, ButtonsContainer } from '../../Layout';
import { StyledColumn } from '../../Layout/Column';
import { StyledRow } from '../../Layout/Row';
import { ColumnNamingButton } from '../ColumnNamingButton';

const StyledFilterAndExport = styled(StyledRow)`
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 2.5)`};
  border-bottom: 1px solid #f3f3f3;
  justify-content: space-between;

  .button {
    margin-bottom: 0;
  }
`;

const FilterColumn = styled(StyledColumn)`
  margin-bottom: 0;
  ${ltMedia('md')} {
    margin-bottom: ${({ theme }) => theme.mainSpacing};
  }
  ${gtMedia('md')} {
    max-width: 320px;
  }
`;
interface Props {
  selectOptions: () => (SelectOption | CustomSelectOption<UserRole>)[];
  handleOnChange: (e: any) => void;
  filterBy: SelectFilterTable;
  handleOpen?: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const FilterAndExport: FC<Props> = ({
  selectOptions,
  handleOnChange,
  filterBy,
  handleOpen,
}) => {
  const { hasAccess } = useUserAccess();
  const user = useSelector((state: RootState) => state.user.details);

  return (
    <StyledFilterAndExport width={1} m={0} flexWrap="wrap">
      <FilterColumn width={[1, null, 1 / 3]}>
        <Select
          menuPortalTarget={document.body}
          options={selectOptions()}
          styles={inputSelectStyles()}
          onChange={(e) => handleOnChange(e)}
          name="filter"
          placeholder={__('application.choose')}
          value={filterBy}
        />
      </FilterColumn>

      <Column padding={0} width={[1, null, 1 / 3]}>
        <ButtonsContainer>
          {user && [UserRole.Admin, UserRole.SuperAdmin].includes(user.role) && (
            <ColumnNamingButton />
          )}
          {hasAccess(Permission.ExportUsers) && (
            <Button small type="button" onClick={handleOpen} text="application.exportToCsv" />
          )}
        </ButtonsContainer>
      </Column>
    </StyledFilterAndExport>
  );
};
