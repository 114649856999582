import { AxiosResponse } from 'axios';
import { ApiErrorCode } from 'app-types';
import { errorNotification } from '../actions/notifications';
import { store } from '../config/store';
import { __ } from './i18n';

interface ErrorToField {
  path: string;
  message: string;
}

interface DTOError {
  key: string;
  args?: string;
}

type SetError = (
  path: string,
  message: {
    message: string;
  },
  options: { shouldFocus: boolean },
) => void;
// eslint-disable-next-line import/prefer-default-export
export const handlingError = (
  response: AxiosResponse,
  setError?: SetError,
):
  | {
      type: string;
      payload: ApiErrorCode;
    }
  | null
  | undefined => {
  if (!response) {
    return store.dispatch(errorNotification(ApiErrorCode.OtherError));
  }
  const { status, data } = response;
  const { message } = data;

  switch (status) {
    case 401:
      return null;
    case 400:
      typeof message === 'object'
        ? message.map(({ path, message }: ErrorToField) =>
            setError?.(path, translateFormErrors(message), { shouldFocus: true }),
          )
        : store.dispatch(errorNotification(data.error_code));
      break;
    default:
      store.dispatch(errorNotification(data.error_code));
  }
};

export const translateFormErrors = (err: string): { message: string } => {
  const parsedError: DTOError = JSON.parse(err);

  if (parsedError?.args) {
    return { message: __(parsedError.key, { value: parsedError.args }) as string };
  } else {
    return { message: __(parsedError.key) as string };
  }
};
