import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: React.ReactNode;
  preview?: boolean;
  isMobile?: boolean;
}

const StyledTableD = styled.td<{ isMobile?: boolean }>`
  max-width: ${({ isMobile }) => isMobile ? `100%` : `300px`};
  padding: ${({ theme }) => `calc(${theme.mainSpacing} / 1.5)`};
  font-size: 1.2rem;
  vertical-align: middle;
  text-align: ${({ isMobile }) => isMobile ? `left` : `center`};
`;

// eslint-disable-next-line import/prefer-default-export
export const TableD = ({ children, isMobile }: Props) => (
  <StyledTableD isMobile={isMobile} >
    {children}
  </StyledTableD>
);
