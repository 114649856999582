import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  isHidden: boolean;
}

const initialState: HeaderState = {
  isHidden: false,
};

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setIsFooterHidden: (state, action: PayloadAction<boolean>) => {
      state.isHidden = action.payload;
    },
  },
});

export const { setIsFooterHidden } = footerSlice.actions;

export default footerSlice.reducer;
