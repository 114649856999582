import React, { FC } from 'react';
import styled from 'styled-components';
import { ContentRemover } from '..';
import { StyledFileWrapper } from '../../Styled';

interface Props {
  children: React.ReactNode;
  deleteVideo?: () => void;
}

const StyledVideoWrapper = styled(StyledFileWrapper)`
  display: flex;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.colorInput};
  .react-player {
    padding-top: 56.25%;
    position: relative;
  }

  .react-player > div,
  .react-player > video {
    top: 0;
    left: 0;
    position: absolute;
    border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const VideoWrapper: FC<Props> = ({ children, deleteVideo }) => (
  <StyledVideoWrapper>
    {children}
    {deleteVideo && <ContentRemover handleRemove={deleteVideo} />}
  </StyledVideoWrapper>
);
