import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import styled from 'styled-components';
import { rgba } from 'polished';
import { UserRole } from 'app-types';
import { Link } from 'react-router-dom';
import { gtMedia } from '../../../config/config';
import { RootState } from '../../../reducers';
import { usePlatform } from '../../../hooks/usePlatform';
import { getProperPath, isExternal } from '../../../utils/dashboardLink';

interface Props {
    path: string;
    backgroundColor: string;
    text: string;
    backgroundUrl?: string;
}

interface StyledLinkProps {
    $isSuperUser?: boolean;
}

interface StyledContentWrapperProps {
    backroundColor?: string;
    backgroundUrl?: string;
}

export const StyledActionText = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.colorLight};
  font-weight: 900;
  position: relative;
  z-index: 1;
`;

export const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  position: relative;
  background-color: ${({ backroundColor }) => backroundColor ?? '#000'};
  background-image: ${({ backgroundUrl }) => `url(${backgroundUrl})` ?? 'none'};
  box-shadow: ${({ theme }) =>
        `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(
            theme.colors.colorDark,
            0.25,
        )}`};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.mainSpacing};
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
`;

const StyledLink = styled(Link) <StyledLinkProps>`
  height: 150px;
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing}/2)`};
  text-decoration: none;
  flex: 0 0 50%;
  position: relative;
  ${gtMedia('md')} {
    flex: 0 0 ${({ $isSuperUser }) => ($isSuperUser ? '33.33' : '25')}%;
  }
`;

const StyledA = styled.a<StyledLinkProps>`
  height: 150px;
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing}/2)`};
  text-decoration: none;
  flex: 0 0 50%;
  position: relative;
  ${gtMedia('md')} {
    flex: 0 0 ${({ $isSuperUser }) => ($isSuperUser ? '33.33' : '25')}%;
  }
`;

const StyledImage = styled.img`
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
`;

const UserActionBox: FC<Props> = ({ backgroundColor, text, path, backgroundUrl }) => {
    const user = useSelector((state: RootState) => state.user.details);
    const host = useSelector((state: RootState) => state.platformConfig?.config?.host);

    const hostPath = Capacitor.isNativePlatform()
        ? process.env.REACT_APP_HOSTNAME!
        : window.location.origin;
    const { tenantQuery } = usePlatform();

   const content = (
        <>
            <StyledContentWrapper backroundColor={backgroundColor}>
                {backgroundUrl && <StyledImage src={`${backgroundUrl}/${tenantQuery}`} />}
                <StyledActionText>{text}</StyledActionText>
            </StyledContentWrapper>
        </>
    );

    if (isExternal(path)) {
        return (
            <StyledA
                $isSuperUser={user?.role === UserRole.SuperUser}
                role="button"
                href={path}
                target="_blank"
            >
                {content}
            </StyledA>
        );
    }
    return (
        <StyledLink
            $isSuperUser={user?.role === UserRole.SuperUser}
            role="button"
            to={getProperPath(path)}
        >
            {content}
        </StyledLink>
    );
};

export default UserActionBox;
