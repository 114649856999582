import {FieldType, TableSchema} from "../types";

export const reportCommunicationTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "_id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "createdDt",
            field: "createdDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
            defaultSort: true
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "sendingDt",
            field: "sendingDt",
            searchable: true,
            sortable: true,
            type: FieldType.Date,
        },
        {
            name: "messageType",
            field: "messageType",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "isPlanned",
            field: "isPlanned",
            searchable: true,
            sortable: true,
            type: FieldType.Boolean
        },
        {
            name: "hasBeenSent",
            field: "hasBeenSent",
            searchable: true,
            sortable: true,
            type: FieldType.Boolean
        }
    ]
};
