import { AdditionalInfo, SuperUserRes } from '../user';
import { AdditionalField } from '../form';

export enum ContestStatus {
  Created,
  InProgress,
  Finished,
  Cancelled,
  Blocked,
  Deleted,
}

export interface ContestTableRes {
  _id: string;
  createdDt: Date;
  title: string;
  contestStatus: ContestStatus;
}

export interface ContestListForUserTableRes {
  _id: string;
  startDt: Date;
  endDt: Date;
  title: string;
  description: string;
  address: string;
}

export interface AddContestReq {
  body: AddContestBody;
  content: string;
}

export interface AddContestBody {
  title: string;
  createdDt: Date;
  endDt?: Date;
  contestFn: string;
  startDt?: Date;
  address: string;
  description: string;
  signUpStartDt?: Date;
  signUpEndDt?: Date;
  isSignUpEnabled: boolean;
}

export interface ParticipantTableRes {
  _id: string;
  firstName: string;
  lastName: string;
  participants: Participant;
  usedCode: CodeStatus;
  scanningPerson?: SuperUserRes;
}

export interface Participant {
  _id: string;
  firstName: string;
  lastName: string;
  tel?: string;
  email?: string;
  additionalInfo?: AdditionalInfo;
  qrFn?: string;
  usedCode?: CodeStatus;
  scannedDt?: Date;
  scanningPerson?: SuperUserRes;
  userOpenedCode?: boolean;
  identityNumber?: string;
  openedCodeDt?: Date;
}

export interface AddParticipantReq {
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  [key: string]: any;
}

export interface ContestRes {
  _id: string;
  title: string;
  address: string;
  description: string;
  content: string;
  createdDt: Date;
  contestFn: string;
  startDt: Date;
  endDt: Date;
  contestStatus: ContestStatus;
  additionalField: AdditionalField[];
  tenantId: string;
  takingPart: boolean;
  signUpStartDt?: Date;
  signUpEndDt?: Date;
  isSignUpEnabled: boolean;
}

export interface AllContestRes {
  title: string;
  address: string;
  description: string;
  content: string;
  createdDt: Date;
  contestFn: string;
  startDt: Date;
  endDt: Date;
  contestStatus: ContestStatus;
  additionalField: AdditionalField[];
  tenantId: string;
  enableDelete: boolean;
  takingPart: boolean;
  signUpStartDt?: Date;
  signUpEndDt?: Date;
  isSignUpEnabled: boolean;
}

export interface EventRes {
  _id: string;
  title: string;
  description: string;
  content: string;
  address: string;
  startDt: Date;
  endDt: Date;
  shortcut: string;
}

export interface QrDetailsRes {
  _id: string;
  firstName: string;
  lastName: string;
  status: boolean;
}

export enum CodeStatus {
  Created,
  Accepted,
  Rejected,
}
