import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import { DashboardElementRes } from 'app-types';
import { ArticleTitle } from '../../../Styled/ArticleTitle';
import { SwiperNavigation } from '../../SwiperNavigation';
import { ResponsiveImage } from '../..';
import {
  getImageLinkForDashboardElement,
  getMainLinkForDashboardElement,
} from '../../../../utils/dashboardLink';

interface Props {
  items: DashboardElementRes[];
}

const StyledLink = styled(Link)`
  position: relative;
  overflow: hidden;
  width: 100%;
`;
const StyledWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  cursor: pointer;
  box-shadow: ${({ theme }) =>
    `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(
      theme.colors.colorDark,
      0.25,
    )}`};
  overflow: hidden;
  &:hover {
    img {
      transition: 0.3s;
      transform: scale(1.01);
    }
  }
  a {
    display: block;
    height: 100%;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const NewsCarosuel: FC<Props> = ({ items }) => {
  return (
    <Swiper
      navigation={{
        hideOnClick: true,
        nextEl: '.swiper-button-next-unique',
        prevEl: '.swiper-button-prev-unique',
      }}
      slidesPerView={1}
    >
      {items?.map((item) => (
        <SwiperSlide key={item.id}>
          <StyledWrapper>
            <StyledLink to={`${getMainLinkForDashboardElement(item)}`}>
              <ResponsiveImage
                mobileRatio={14 / 9}
                src={`${getImageLinkForDashboardElement(item)}`}
                alt={item.title}
              />
              <ArticleTitle title={item.title} />
            </StyledLink>
          </StyledWrapper>
        </SwiperSlide>
      ))}
      <SwiperNavigation />
    </Swiper>
  );
};
