import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
  component: any;
  exact?: boolean;
  path?: string;
}

const RouteUnauthorized = ({ exact, path, component }: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.user.details);

  if (!user) {
    return <Route exact={exact} path={path} component={component} />;
    // } else if ([UserRole.Admin, UserRole.Moderator, UserRole.SuperAdmin].includes(user.role)) {
    //   return <Route render={() => <Redirect to="/admin" />} />;
  } else {
    return <Route render={() => <Redirect to="/dashboard" />} />;
  }
};

export default RouteUnauthorized;
