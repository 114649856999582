import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../../config/apiUrl';
import { RootState } from '../../../reducers';
import { ResponsiveImage } from '../ResponsiveImage';
import { Wrapper } from '..';

const DashboardBackground: FC = () => {
  const platformId = useSelector((state: RootState) => state?.platformConfig?.config?._id);
  return (
    <Wrapper width={1}>
      <ResponsiveImage
        noTopRadius
        mobileRatio={20 / 5}
        src={`${apiUrl}tenant/dashboardImageFn/${platformId}`}
        alt=""
      />
    </Wrapper>
  );
};
export default DashboardBackground;
