import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: React.ReactNode;
  preview?: boolean;
}

const Table = styled.table`
  width: 100%;
  overflow: auto;
`;

// eslint-disable-next-line import/prefer-default-export
export const StyledTable = ({ children }: Props) => {

  return (
    <Table>
      {children}
    </Table>
  );
};
