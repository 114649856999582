import {FieldType, TableSchema} from "../types";

export const catalogCategoryTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "id",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        },
        {
            name: "category.createdDt",
            field: "createdDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date
        },
        {
            name: "category.title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
    ]
};
