import {SelectOption} from "../form";

export interface ContactUser {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
}

export interface ContactManagementTitleTableRes {
    _id: string;
    createdDt: Date;
    title: string;
}

export interface ContactManagementRequestTableRes {
    _id: string;
    createdDt: Date;
    title: string;
    status: ContactManagementStatus;
}

export enum ContactManagementStatus {
    New,
    InProgress,
    Closed,
    Delivered
}

export interface ContactManagementTitleRes {
    _id: string;
    createdDt: Date;
    title: string;
    users: SelectOption[];
    tenantId: string;
}

export interface SetContactTitleReq {
    title: string;
    users: SelectOption[];
}

export interface SendContactForm {
    email: string;
    firstName: string;
    lastName: string;
    fileFn?: string;
    content: string;
    titleId: string;
}

export interface ContactManagementRequestRes {
    _id: string,
    title: string;
    createdDt: Date;
    fileFn?: string;
    content: string;
    usersIds: string[];
    status: ContactManagementStatus;
    requestedUser: ContactUser;
    responder: ContactUser;
    tenantId: string;
}

export interface ChangeStatusReq {
    status: ContactManagementStatus;
}
