import {FieldType, TableSchema} from "../types";

export const userCodesTaleSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "Identyfikator",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Imię",
            field: "user.firstName",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Nazwisko",
            field: "user.lastName",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Email",
            field: "user.email",
            searchable: true,
            sortable: true,
            type: FieldType.Email
        },
        {
            name: "Tel",
            field: "user.tel",
            searchable: true,
            sortable: true,
            type: FieldType.Tel
        },
        {
            name: "Nazwa zamówienia",
            field: "order.name",
            searchable: false,
            sortable: true,
            type: FieldType.Text,
        },
        {
            name: "Kod produktu",
            field: "order.productCode",
            searchable: false,
            sortable: true,
            type: FieldType.Text,
        },
        {
            name: "Email odpowiedzialny",
            field: "order.responsiblePersonEmail",
            searchable: false,
            sortable: true,
            type: FieldType.Text,
        },
        {
            name: "Kod użyty",
            field: "order.isUsed",
            searchable: false,
            sortable: true,
            type: FieldType.Boolean,
        },
        {
            name: "Kod weryfikacji",
            field: "order.uniqueProductCode",
            searchable: false,
            sortable: false,
            type: FieldType.Text,
        }
    ]
};
