import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { gtMedia, ltMedia } from '../../../config/config';
import { RootState } from '../../../reducers';
import { Brand, Logout } from '../../Common';
import HeaderBackArrow from './HeaderBackArrow';
import { Container } from '../../Layout';
import HeaderTitle from './HeaderTitle';
import HeaderContent from './HeaderContent';

interface StyledHeaderProps {
  noLeft?: boolean;
  hideOnMobile?: boolean;
}

const StyledHeader = styled.header<StyledHeaderProps>`
  position: absolute;
  top: 0;
  left: 0;
  padding: ${({ theme }) => `${theme.mainSpacing} 0`};
  width: 100%;
  background: transparent;
  z-index: 998;
  height: ${({ theme }) => theme.headerHeight};

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      ${ltMedia('md')} {
        display: none;
      }
    `}

  ${gtMedia('md')} {
    padding-left: ${({ theme }) => theme.desktopNavWidth};
  }
  ${({ noLeft }) =>
    noLeft &&
    css`
      ${gtMedia('md')} {
        padding-left: 0;
      }
    `}
`;

const HeaderBrand = styled.div`
  height: 100%;
  ${gtMedia('md')} {
    display: none;
  }
`;

const Header: FC = () => {
  const { title, back, noLeft, hideOnMobile, hide } = useSelector((state: RootState) => state.header);
  const user = useSelector((state: RootState) => state.user.details);
  if (hide) return null;

  return (
    <StyledHeader noLeft={noLeft} hideOnMobile={hideOnMobile}>
      <Container>
        <HeaderContent>
          {window.location.pathname !== '/dashboard' && <HeaderBackArrow path={back} />}
          {window.location.pathname === '/dashboard' && user && <Logout mobile />}
          {!!title && <HeaderTitle title={title} />}
          <HeaderBrand>
            <Brand />
          </HeaderBrand>
        </HeaderContent>
      </Container>
    </StyledHeader>
  );
};

export default Header;
