import React, { FC } from 'react';
import { Container } from '../../components/Layout';
import StyledPageError from '../../components/Styled/StyledPageError';
import {Content} from './Content';

export const PlatformNotActive: FC = () => (
  <StyledPageError noLeft>
    <Container>
      <Content />
    </Container>
  </StyledPageError>
);

