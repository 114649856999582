import { createGlobalStyle, css } from 'styled-components';
import { apiUrl } from './apiUrl';

interface GlobalFontProps {
  _id: string;
}

export const GlobalFont = createGlobalStyle<GlobalFontProps>`
  @font-face {
    font-family: 'blocal';
    src: url(${apiUrl}tenant/fonts/normal/?tenantId=${(props) => props._id});
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'blocal';
    src: url(${apiUrl}tenant/fonts/medium/?tenantId=${(props) => props._id});
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'blocal';
    src: url(${apiUrl}tenant/fonts/bold/?tenantId=${(props) => props._id});
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }
`;

interface GlobalTypographyPrps {
  colorPrimary?: string;
}

export const GlobalTypopgrahy = createGlobalStyle<GlobalTypographyPrps>`
 a {
   text-decoration: none;
   font-weight: 500;
   color: ${({ theme }) => theme.colors.colorPrimary};
 }
`;

interface GlobalBorderRadiusProps {
  borderRadius?: number;
}

export const GlobalBorderRadius = createGlobalStyle<GlobalBorderRadiusProps>`
  select, input, a, button {
    ${({ borderRadius }) =>
      borderRadius &&
      css`
        border-radius: ${borderRadius}px;
      `}
  }
`;
