import { FormProvider, useForm } from 'react-hook-form';
import React, { FC, useContext } from 'react';
import { v4 as uuid } from 'uuid';

import { StyledForm } from '../../../../../components/Styled';
import { Button, FormField, Spinner, Text, Wrapper } from '../../../../../components/Common';
import { ButtonsContainer, Column } from '../../../../../components/Layout';
import { useQuiz } from '../../../../../hooks/quizzes/useQuiz';
import { useQuizContext } from '../../../../../hooks/quizzes/QuizProvider';

interface OpenQuestionAnswerProps {
  questionId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const OpenQuestionAnswer: FC<OpenQuestionAnswerProps> = ({ questionId }) => {
  const { quizId } = useQuiz();
  const { quizContext } = useQuizContext();
  const methods = useForm({
    mode: 'onBlur',
  });

  const getButtonText = () => {
    if (isLastQuestionRunning()) {
      return survey ? 'survey.end' : 'quiz.finishQuiz';
    }
    return 'quiz.nextQuestion';
  };

  const { isLastQuestionRunning, survey, checkAnswer, question } = quizContext;

  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={methods.handleSubmit((data: { answerId?: string; content?: string }) =>
          checkAnswer(
            quizId,
            questionId,
            data?.answerId ? [data?.answerId] : [uuid()],
            survey,
            data,
            true,
          ),
        )}
      >
        {question?.isQuestionWithScale ? (
          <ButtonsContainer flexDirection="row" justifyContent="center" mb="30px" pr={5} pl={5}>
            {question?.answer &&
              question?.answer.map(({ _id, content }) => (
                <Wrapper display="flex" justifyContent="center" flexWrap="wrap">
                  <Text textAlign="center" width={1}>
                    {content}
                  </Text>
                  <Column width="60px">
                    <FormField type="radio" name="answerId" value={_id} />
                  </Column>
                </Wrapper>
              ))}
          </ButtonsContainer>
        ) : (
          <Column width={1}>
            <FormField type="textarea" name="content" placeholderText="Odpowiedź" />
          </Column>
        )}
        <ButtonsContainer p="0 10px">
          <Button type="submit" text={getButtonText()} />
        </ButtonsContainer>
      </StyledForm>
    </FormProvider>
  );
};
