import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../actions/modal';
import { TableColumnNames } from '../../Modals';
import { Button } from '../Button';

const ColumnNamingButton = () => {
  const dispatch = useDispatch();

  const displayColumnsModal = () => {
    dispatch(showModal(<TableColumnNames />));
  };
  return <Button small text="table.columnSettings" onClick={displayColumnsModal} />;
};

export { ColumnNamingButton };
