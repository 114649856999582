import { FieldType, TableSchema } from "../types";

const videoStatusToString: {
  [key: number]: string
} = {
  0: 'in_progress',
  1: 'available',
  2: 'progress_error'
};

export const articleTableSchema: TableSchema = {
  countPerPage: 9,
  fields: [
    {
      name: "_id",
      field: "id",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "createdDt",
      field: "createdDt",
      searchable: false,
      sortable: true,
      type: FieldType.Date,
      defaultSort: true
    },
    {
      name: "title",
      field: "title",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "videoStatus",
      field: "videoStatus",
      searchable: false,
      sortable: true,
      type: FieldType.Text,
      customValue: value => videoStatusToString[value.videoStatus],
    }
  ]
};
