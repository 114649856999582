import React from 'react';
import styled, { css } from "styled-components"

interface Props {
    big?: boolean;
    size?: number;
}

const StyledIconPlaceholder = styled.div<Props>`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.colorGray};
    ${({ big }) =>
        big &&
        css`
    width: 20px;
    height: 20px;
`}
${({ size }) =>
        size &&
        css`
    width: ${size}px;
    height: ${size}px;
`}`;

const IconPlaceholder = ({ big, size }: Props) => {
    return <StyledIconPlaceholder big={big} size={size} />
}

export { IconPlaceholder }