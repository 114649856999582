import React, { FC, useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import styled, { useTheme } from 'styled-components';

import { IconButton, Text } from '..';
import { PaginationButton } from '../Button/PaginationButton'

interface NewPaginationProps {
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  dataLength: number;
  page: number;
}

const StyledPagination = styled.div`
  margin: ${({ theme }) => theme.mainSpacing} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  font-weight: 300;
`;

// eslint-disable-next-line import/prefer-default-export
export const NewPagination: FC<NewPaginationProps> = ({ page, gotoPage, previousPage, canPreviousPage, nextPage, canNextPage, dataLength }) => {
  const isOnPage = (current: number): boolean => page + 1 === current;
  const { colors } = useTheme();
  const hadleClick = (dest: number | null) => {
    if (dest && !isOnPage(dest)) {
      gotoPage(dest - 1);
    }
  };
  const [buttons, setButtons] = useState<(number | null)[]>([1, 2, null, null, null]);

  const setButtonsArray = (content: number, currentPage: number) => {
    if (content <= 20) {
      setButtons([1, 2, null, null, null]);
    }  else if (content <= 30) {
      setButtons([1, 2, 3, null, null]);
    } else if (content <= 40) {
      setButtons([1, 2, 3, 4]);
    } else if (content <= 50) {
      setButtons([1, 2, 3, 4, 5]);
    } else {
      if (currentPage <= 2) {
        setButtons([1, 2, 3, currentPage + 3, Math.ceil(dataLength / 10)]);
      } else if (currentPage === Math.ceil(dataLength / 10) || currentPage + 1 === Math.ceil(dataLength / 10)) {
        setButtons([1, Math.ceil(dataLength / 10) - 3, Math.ceil(dataLength / 10) - 2, Math.ceil(dataLength / 10) - 1, Math.ceil(dataLength / 10)]);
      } else {
        setButtons([1, currentPage - 1, currentPage, currentPage + 1, Math.ceil(dataLength / 10)]);
      }
    }

  };

  useEffect(() => {
    setButtonsArray(dataLength, page + 1)
  }, [page]);


  return (
    <StyledPagination>
      <IconButton bg="#000" onClick={() => previousPage()} disabled={!canPreviousPage} width="30px" height="30px" mr={1}><IoIosArrowBack size={18} color="#fff" /></IconButton>
      <PaginationButton onClick={() => hadleClick(1)} active={isOnPage(1)} number={1} />
      {dataLength > 50 && page > 2 && <Text as="span" color={colors.colorPrimary}>...</Text>}
      <PaginationButton onClick={() => hadleClick(buttons[1])}  number={`${buttons[1]}`} active={buttons[1] ? isOnPage(buttons[1]) : false} />
      {buttons[2] && <PaginationButton onClick={() => hadleClick(buttons[2])} number={`${buttons[2]}`} active={buttons[1] ? isOnPage(buttons[2]) : false} />}
      {buttons[3] && <PaginationButton onClick={() => hadleClick(buttons[3])} number={`${buttons[3]}`} active={buttons[1] ? isOnPage(buttons[3]) : false} />}
      {dataLength > 50 && page < Math.ceil(dataLength / 10) - 3 && <Text as="span" color={colors.colorPrimary}>... </Text>}
      {buttons[4] && <PaginationButton onClick={() => hadleClick(buttons[4])} number={`${buttons[4]}`} active={buttons[1] ? isOnPage(buttons[4]) : false} />}
      <IconButton bg="#000" onClick={() => nextPage()} disabled={!canNextPage} width="30px" height="30px"><IoIosArrowForward size={18} color="#fff" /></IconButton>
    </StyledPagination>
  );
};
