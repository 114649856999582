import { FieldType, TableSchema } from "../types";

export const temporaryActionTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "_id",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        },
        {
            name: "application.createdDt",
            field: "createdDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
            defaultSort: true,
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: true,
            type: FieldType.Text,
        },
        {
            name: "application.startDt",
            field: "startDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
        },
        {
            name: "application.endDt",
            field: "endDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
        },
        {
            name: "application.isMainRanking",
            field: "isMainRanking",
            searchable: false,
            sortable: true,
            type: FieldType.Boolean
        },
        {
            name: "application.isDeactivated",
            field: "isDeactivated",
            searchable: false,
            sortable: true,
            type: FieldType.Boolean
        }
    ]
};
