import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeTabStyles = css`
  color: ${({ theme }) => theme.colors.colorLight};
  cursor: default;
  background: ${({ theme }) => theme.colors.colorSecondary};
  border: none;
`;

export const StyledViewsTabs = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.colorInput};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  display: flex;
  height: 50px;
  text-align: center;
  overflow: hidden;
  margin-bottom: ${({ theme }) => `calc(3 * ${theme.mainSpacing})`};
`;

export const StyledNavTab = styled(NavLink)`
  display: flex;
  align-items: center;
  border-radius: 0;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  height: 100%;
  font-weight: 200;
  padding: ${({ theme }) => theme.mainSpacing};
  &.active {
    ${activeTabStyles}
  }
`;

export const StyledTab = styled.div<{ isFirst: boolean; isActive: boolean; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  height: 100%;
  max-width: ${({ width }) => `${width}%`};
  font-weight: 200;
  padding: ${({ theme }) => theme.mainSpacing};
  ${({ isFirst }) =>
    !isFirst &&
    css`
      border-left: 1px solid ${({ theme }) => theme.colors.colorInput};
    `}

  ${({ isActive }) => isActive && activeTabStyles}
`;
