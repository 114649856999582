/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Permission, PlatformFullConfig, QuizType } from 'app-types';
import { rgba } from 'polished';
import ReactPlayer from 'react-player';
import { editorJsTools } from '../../../../config/editorJs-tools';
import { useArticle } from '../../../../hooks/articles/useArticle';
import { ButtonsContainer, Column, Container, Main, Row } from '../../../../components/Layout';
import {
  Alert,
  Button,
  EditorJsContainer,
  PageTitle,
  ResponsiveImage,
  SharePanel,
  Spinner,
  VideoNotAvailable,
} from '../../../../components/Common';
import { RootState } from '../../../../reducers';
import { ArticleCategories } from './ArticleCategories';
import { StyledWysiwyg } from '../../../../components/Styled';
import { useUserAccess } from '../../../../hooks/useUserAccess';
import { usePlatform } from '../../../../hooks/usePlatform';
import ArticleComments from './ArticleComments';

export const StyledWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  .react-player {
    padding-top: 56.25%;
    position: relative;
  }

  .react-player > div {
    top: 0;
    left: 0;
    position: absolute;
    border-radius: ${({ theme }) => `${theme.borderRadius}px`};
    .player .vp-video {
      border-radius: ${({ theme }) => `${theme.borderRadius}px`};
      box-shadow: ${({ theme }) =>
        `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(
          theme.colors.colorDark,
          0.25,
        )}`};
    }
  }
`;

const correctQuizUrl = (quizType: QuizType) =>
  quizType === QuizType.Quiz ? 'dashboard/quizzes/preview/' : 'dashboard/quizzes/surveys/preview/';

const ArticlePreview: FC = () => {
  const { article, loading, getPhoto, getArticle, isVideoAvailable } = useArticle({});
  const { hasAccess } = useUserAccess();
  const { colors } = useTheme();
  const { platformId } = usePlatform();
  const allowCommenting = useSelector(
    (state: RootState) => (state.platformConfig?.config as PlatformFullConfig).allowCommenting,
  );

  if (loading) return <Spinner />;

  if (!article) {
    return (
      <Main>
        <Container>
          <Alert type="error" simple text="article.notFound" />
        </Container>
      </Main>
    );
  }

  return (
    <Main>
      <Container>
        {hasAccess(Permission.EditArticle) && (
          <ButtonsContainer mb={1}>
            <Button
              small
              variant="secondary"
              to={`/dashboard/articles/edit/${article._id}`}
              text="application.edit"
            />
          </ButtonsContainer>
        )}
        <Row>
          <Column width={1}>
            <PageTitle>{article.title}</PageTitle>
          </Column>

          {article?.articleCategory && (
            <Column width={1}>
              <ArticleCategories categories={article.articleCategory} />
            </Column>
          )}
          <Column mb={2} width={1}>
            <ResponsiveImage mobileRatio={14 / 9} src={getPhoto()} />
          </Column>
          {hasAccess(Permission.ShareArticle) && (
            <Column width={1}>
              <SharePanel />
            </Column>
          )}
          {article?.content && article.content !== 'undefined' && (
            <Column width={1} alignItems="center" justifyContent="center">
              <StyledWrapper>
                <StyledWysiwyg preview>
                  <EditorJsContainer
                    tools={editorJsTools({ _id: platformId, wysiwygType: 'article' })}
                    value={article?.content && JSON.parse(article.content)}
                    autofocus={false}
                    readOnly
                  />
                </StyledWysiwyg>
              </StyledWrapper>
            </Column>
          )}
          {article.videoPrvLink && (
            <Column width={1} alignItems="center" justifyContent="center">
              <StyledWrapper>
                {isVideoAvailable ? (
                  <ReactPlayer
                    class="react-player"
                    controls
                    playsInline
                    loop
                    height="100%"
                    width="100%"
                    config={{
                      vimeo: { playerOptions: { color: colors.colorPrimary.slice(1) } },
                    }}
                    url={article.videoPrvLink}
                  />
                ) : (
                  <VideoNotAvailable />
                )}
              </StyledWrapper>
            </Column>
          )}
          {article?.articleQuiz && article.articleQuiz.length > 0 && (
            <Column width={1} alignItems="center" justifyContent="center">
              <Button
                m="0 auto"
                to={`/${correctQuizUrl(article.quizType)}${article?.articleQuiz[0].value}`}
                text={article.quizType === QuizType.Quiz ? 'quiz.runQuiz' : 'survey.runSurvey'}
              />
            </Column>
          )}
        </Row>
        {allowCommenting && (
          <ArticleComments article={article} getArticle={getArticle} isLoading={loading} />
        )}
      </Container>
    </Main>
  );
};

export default ArticlePreview;
