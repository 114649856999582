import { TenantSegment, CustomTileRes, UserRole, SelectOption } from 'app-types';
import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiUrl } from '../../../config/apiUrl';
import { ActionsBoxesContainer } from '..';
import { RootState } from '../../../reducers';
import { useApi } from '../../../services/useApi';
import UserActionBox from '../UserActionBox';
import { refreshUserDetails } from '../../../slices/userSlice';
import { getAvailableModules } from '../../../slices/availableModules';

enum BoxType {
    SEGMENT,
    CUSTOM,
}

interface SingleBox {
    type: BoxType;
    box: TenantSegment | CustomTileRes;
}

// eslint-disable-next-line import/prefer-default-export
export const UserBoxes: FC = () => {
    const { api } = useApi();
    const [modulesForTenant, setModulesForTenant] = useState<TenantSegment[]>();
    const [tilesForUser, setTilesForUser] = useState<CustomTileRes[]>();
    const [allTiles, setAllTiles] = useState<SingleBox[]>([]);
    const platformId = useSelector((state: RootState) => state?.platformConfig?.config?._id);
    const user = useSelector((state: RootState) => state.user?.details);
    const availableModules = useSelector((state: RootState) => state.availableModules.list);
    const dispatch = useDispatch();

    const host = useSelector((state: RootState) => state.platformConfig?.config?.host);

    if (!user) dispatch(refreshUserDetails());

    const renderCorrectModules = (resModules: TenantSegment[]) => {
        if (UserRole.Moderator === user?.role && resModules) {
            const isIncluded = (module: TenantSegment) =>
                user.userModules.some((userModule: SelectOption) => userModule.value === module._id);
            setModulesForTenant(resModules.filter((module) => isIncluded(module)));
        } else {
            setModulesForTenant(resModules);
        }
    };

    useEffect(() => {
        dispatch(getAvailableModules());

        api<CustomTileRes[]>({
            method: 'GET',
            path: 'custom-tile/available-tiles',
            onSuccess: (res) => {
                setTilesForUser(res);
            },
        });
    }, []);

    useEffect(() => {
        if (availableModules) renderCorrectModules(availableModules);
    }, [availableModules]);

    useEffect(() => {
        if (tilesForUser && modulesForTenant) {
            const tiles: SingleBox[] = [
                ...modulesForTenant.map((module) => ({
                    type: BoxType.SEGMENT,
                    box: module,
                })),
                ...tilesForUser.map((tile) => ({
                    type: BoxType.CUSTOM,
                    box: tile,
                })),
            ].sort((a, b) => Number(a.box.order || 0) - Number(b.box.order || 0));

            setAllTiles(tiles);
        }
    }, [tilesForUser, modulesForTenant]);

    return (
        <ActionsBoxesContainer>
            {allTiles.map((tile) => {
                if (tile.type === BoxType.SEGMENT) {
                    const { segment, segmentBg, segmentNameForTenant, _id, fileFn } =
                        tile.box as TenantSegment;
                    return (
                        <UserActionBox
                            key={_id}
                            backgroundColor={segmentBg}
                            text={segmentNameForTenant}
                            path={segment.path}
                            backgroundUrl={fileFn && `${apiUrl}tenant/module-photo/${platformId}/${_id}`}
                        />
                    );
                } else if (tile.type === BoxType.CUSTOM) {
                    const { path, color, _id, fileFn, title } = tile.box as CustomTileRes;
                    return (
                        <UserActionBox
                            key={_id}
                            backgroundColor={color}
                            text={title}
                            path={path}
                            backgroundUrl={fileFn && `${apiUrl}custom-tile/tile-photo/${platformId}/${_id}`}
                        />
                    );
                }
            })}
        </ActionsBoxesContainer>
    );
};
