import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { modal as modalActions } from '../../../../actions';
import { RootState } from '../../../../reducers';
import { ModalState } from '../../../../types/modal';

import './ModalHeader.scss';

interface Props {
  modal: ModalState;
  children: React.ReactNode;
  hideModal: () => void;
  navigation?: boolean;
}

const CloseButton = styled.button`
  color: ${({ theme }) => theme.colors.colorPrimary};
`;

const ModalHeader = ({ children, modal, hideModal, navigation }: Props) => (
  <div className="modal-header">
    {children}
    {modal.isClosable && (
      <CloseButton type="button" className={`modal-exit ${navigation ? 'navigation' : ''}`} onClick={hideModal}>
        &times;
      </CloseButton>
    )}
  </div>
);

const mapStateToProps = (state: RootState) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ hideModal: modalActions.hideModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader);
