import { SelectOption } from '..';

export enum AdStatus {
  PENDING,
  ACCEPTED,
  REJECTED,
}

export interface AdEntity {
  _id: string;
  createdDt: Date;
  link: string;
  dateFrom: Date;
  dateTo: Date;
  place: AdPlace;
  imageFn: string;
  status: AdStatus;
  title: string;
}

export interface AdListDetails {
  _id: string;
  link: string;
  dateFrom: Date;
  dateTo: Date;
  place: AdPlace;
  status: AdStatus;
  title: string;
}

export interface AdDisplayDetails {
  id: string;
  link: string;
  place: AdPlace;
  imageFn: string;
  title: string;
}

export interface AdDetails {
  id: string;
  link: string;
  dateFrom: Date;
  dateTo: Date;
  place: AdPlace;
  imageFn: string;
  title: string;
}

export interface AdFormData {
  id?: string;
  imageFn: File[];
  link: string;
  dateFrom: string;
  dateTo: string;
  place: string;
  title: string;
}

export interface AdReq {
  id?: string;
  imageFn: File[];
  link: string;
  dateFrom: Date;
  dateTo: Date;
  place: AdPlace;
  title: string;
}

export enum AdPlace {
  PAGE_BOTTOM,
  PAGE_TOP
}

export interface AdStatusReq {
  newStatus: AdStatus
}
