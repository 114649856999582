export enum Permission {
  AddQuiz,
  DeleteQuiz,
  EditQuiz,
  AddArticle,
  DeleteArticle,
  EditArticle,
  HideArticle,
  ModerateComments,
  AddContest,
  DeleteContest,
  HideContest,
  ScanQRCode,
  ShowContestParticipants,
  AcceptUser,
  RejectUser,
  BlockUserActions,
  ShowUser,
  PwdReset,
  AddSuperuser,
  AssignSuperuser,
  DeleteParticipant,
  EditContest,
  CancelContest,
  FinishContest,
  BlockContest,
  ExportUsers,
  ExportParticipants,
  AddParticipants,
  UserAcceptance,
  ImportUsers,
  ChangeRole,
  CreateCommunication,
  ViewCommunication,
  DeleteCommunication,
  CreateGroup,
  ExportGroupUsers,
  DeleteGroup,
  ViewStats,
  CustomTiles,
  CustomBanners,
  Attachments,
  AppLogs,
  CatalogManagement,
  CatalogOrdersManagement,
  PresentationManagement,
  RankingManipulate,
  RankingManagement,
  TemporaryActionManagement,
  AddAd,
  DeleteAd,
  EditAd,
  ShareArticle,
  ShareEvent,
  ExportArticles,
}
