import { FieldType, TableSchema } from "../types";

const costTypeToString: {
    [key: number]: string;
} = {
    0: "Punkty",
    1: "Pieniądze",
    2: "Kupon",
};

export const catalogProductTableSchema: TableSchema = {
    countPerPage: 12,
    fields: [
        {
            name: "product.id",
            field: "id",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        },
        {
            name: "product.data",
            field: "createdDt",
            searchable: false,
            sortable: true,
            defaultSort: true,
            type: FieldType.Date
        },
        {
            name: "product.name",
            field: "name",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.price",
            field: "price",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.pointsPrice",
            field: "pointsPrice",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.costType",
            field: "costType",
            searchable: false,
            sortable: true,
            type: FieldType.Text,
            searchableValues: costTypeToString,
            customValue: value => costTypeToString[value.costType]
        },
        {
            name: "product.category",
            field: "productCategory",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.quantityInStock",
            field: "quantityInStock",
            searchable: false,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.currency",
            field: "currency",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        },
        {
            name: "product.canBeExchange",
            field: "canBeExchanged",
            searchable: false,
            sortable: false,
            type: FieldType.Boolean
        },
        {
            name: "product.shortDescription",
            field: "shortDescription",
            searchable: false,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.responsiblePersonEmail",
            field: "responsiblePersonEmail",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "product.limitPerPerson",
            field: "limitPerPerson",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        }
    ]
};
