import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import { gtMedia } from '../../../config/config';
import { handleLogout } from '../../../slices/userSlice';

const StyledLogout = styled.div<LogoutProps>`
  ${({ mobile }) =>
    mobile &&
    css`
      ${gtMedia('md')} {
        visibility: hidden;
      }
    `}
`;

interface LogoutProps {
  mobile?: boolean;
}

const Logout: FC<LogoutProps> = ({ mobile }) => {
  const dispatch = useDispatch();
  return (
    <StyledLogout mobile={mobile}>
      <Icon big icon="logout" onClickHandler={() => dispatch(handleLogout())} />
    </StyledLogout>
  );
};

export default Logout;
