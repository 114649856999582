import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { __ } from '../../../helpers/i18n';
import { useReceivers } from '../../../hooks/useReceivers';
import { Column } from '../../Layout';
import { FormField } from '../FormField';
import { FormSelect } from '../FormSelect';

interface Props {
  title: string;
  methods: UseFormReturn;
}

const GroupsSelect = ({ title, methods }: Props) => {
  const { groups, getGroups } = useReceivers();

  useEffect(() => {
    getGroups();
  }, []);

  const isDedicated = methods.watch('isDedicated');

  useEffect(() => {
    if(!isDedicated) {
      methods.setValue('forGroups', [])
    }
  }, [isDedicated])

  return (
    <>
      <Column width={1}>
        <FormField labelText={title} type="checkbox" name="isDedicated" />
      </Column>
      {isDedicated && (
        <Column width={1}>
          <FormSelect
            name="forGroups"
            label={__('communication.chooseReceiversGroup')}
            isMulti
            options={groups}
          />
        </Column>
      )}
    </>
  );
};

export { GroupsSelect };
