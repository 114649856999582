import React, { FC } from 'react';
import styled from 'styled-components';

import { Icon, Text } from '..';
import { gtMedia } from '../../../config/config';
import { __ } from '../../../helpers/i18n';

const StyledVideoNotAvailable = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height ?? '40vw'};
  display: flex;
  padding: ${({ theme }) => `${theme.mainSpacing}`};
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  color: ${({ theme }) => theme.colors.colorLight};
  background-color: ${({ theme }) => theme.colors.colorDark};
  ${gtMedia('lg')} {
    height: ${({ height }) => height ?? '20vw'};
  }
`;

const IconWrapper = styled.div`
  width: 40px;
`;

interface Props {
  height?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const VideoNotAvailable: FC<Props> = ({ height }) => (
  <StyledVideoNotAvailable height={height}>
    <IconWrapper>
      <Icon externalLink disabled icon="iconfinder" color="#fff" size={20} />
    </IconWrapper>
    <div>
      <Text color="#fff" fontSize="big" mb={1}>
        {__('application.videoNotAvailable')}
      </Text>
      <Text color="#fff" fontSize="small">
        {__('application.videoIsBurrefing&checkLater')}
      </Text>
    </div>
  </StyledVideoNotAvailable>
);
