import { UserRole } from '../user';
import { SelectOption } from '../form';

export interface RankingRes {
  id: string;
  startedDt: Date;
  points: ScoredActionsRes[];
  action: any;
  user: any;
}

export interface RankingTableRes {
  _id: string;
  place: number;
  total: number;
  firstName: string;
  lastName: string;
  points: string;
}

export interface ScoredObj {
  points: number;
  id: string;
}

export interface RankingDetails {
  position: number;
  points: ScoredActionsRes[];
  pointsToTop: number;
}

export interface AddRankingName {
  name: string;
  tenantId: string;
}

export enum ActionType {
  SolvedQuiz = 'solved_quiz',
  FirstLogging = 'first_logging',
  RegularLogging = 'regular_logging',
  VerifyNewProfile = 'verify_new_profile',
  StartedPoints = 'started_points',
  AddedRealization = 'added_realization',
  VerifyRealization = 'verify_realization',
  SolvedSurvey = 'solved_survey',
  AddedByAdmin = 'added_by_admin',
  InvitationAccepted = 'invitation_accepted',
  Registration = 'registration',
  SendInvitation = 'send_invitation',
  VerifyRealizationIn5Days = 'verify_realization_in_5_days',
  SpentInShop = 'spent_in_shop'
}

export interface ConfigRankingTableRes {
  _id: string;
  title: string;
  participants: UserRole[];
  isDeactivated: boolean;
}

export interface RankingConfigReq {
  title: string;
  scoredActions: ScoredActionsReq[];
}

export interface ScoredActionsReq {
  action: SelectOption;
  points?: number;
  numOfRegularAction?: number;
}

export interface ScoredActionsRes {
  action: SelectOption;
  points: number;
  _id?: string;
  numOfRegularAction?: number;
}

export interface RankingConfigRes {
  title: string;
  scoredActions: ScoredActionsRes[];
}

export interface IdAndTitle {
  _id: string;
  title: string;
}

export interface ChangeCustomPoints {
  userId: string;
  tempActionId: string;
  points: number;
}

export interface PointsHistoryTableRes {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  points: string;
  actionType: string;
  createdDt: Date;
  rankingConfigName: string;
}

export interface PlaceRes {
  missingPoints: number;
  minPoints: number;
  maxPoints: number;
}

export interface UserRankingRes {
  rank: number | string;
  points: number;
}

export interface MyRankingRes {
  id: string;
  title: string;
  allowAllAccess?: boolean;
}
