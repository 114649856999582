/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { PositionProps, LayoutProps } from 'styled-system';
import { IconButton } from '..';

interface IconActionButtonProps extends PositionProps, LayoutProps {
  children: React.ReactNode;
  bgColor: string;
  onClick?: () => void;
  to?: string;
  title: string;
  dataCy?: string
}

// eslint-disable-next-line import/prefer-default-export
export const IconActionButton: FC<IconActionButtonProps> = ({
  children,
  title,
  bgColor,
  onClick,
  to,
  dataCy,
  ...props
}) => (
  <IconButton
    bRadius="50%"
    to={to}
    onClick={onClick}
    width="35px"
    height="35px"
    bg={bgColor}
    title={title}
    mr={1}
    dataCy={dataCy}
    {...props}
  >
    {children}
  </IconButton>
);
