import { VideoStatus } from "../article";

export enum QuizPublicationStatus {
  UNPUBLISHED,
  PUBLISHED,
}

export interface Answer {
    _id: string;
    content?: string;
    answerFn?: string;
    isCorrect?: boolean;
    count?: number;
    explaination?: string;
    percentageCount?: string;
}


export interface QuizQuestion {
    _id: string;
    question: string;
    questionFn?: string;
    videoFn?: string;
    videoUri?: string;
    videoStatus?: VideoStatus;
    answer?: Answer[];
    isExplainationNeeded?: boolean;
    isQuestionWithScale?: boolean;
    count?: number;
}

export interface QuizListRes {
    _id: string;
    title: string;
    description: string;
    maxPoints: number;
}

export enum QuestionStatus {
    InProgress,
    Solved,
    Finished,
}

export enum QuizStatus {
    Created,
    InProgress,
    Finished
}

export interface QuizTableRes {
    _id: string;
    createdDt: Date;
    title: string;
    startDt: Date;
    endDt: Date;
    isPublished: boolean;
    isDeactivated: boolean;
    /*  questions: QuizQuestion[];
      allVidsUploaded?: boolean;*/
}

export interface QuizDetailsRes {
    details: DetRes;
    quizStatus: QuizStatus;
}

export interface DetRes {
    articleId?: string;
    maxPoints: number;
    pointsPerWrongAnswer: number;
    pointsPerCorrectAnswer: number;
    description: string;
    _id: string;
    title: string;
    startDt: Date;
    endDt: Date;
}

export interface QuizDetailRes {
    _id: string;
    title: string;
    description: string;
    createdDt: Date;
    pointsPerCorrectAnswer: number;
    pointsPerWrongAnswer: number;
    startDt: Date;
    endDt: Date;
    articleId: string;
    maxPoints: number;
}

export interface QuizQuestionResult {
    quizId: string;
    questionId: string;
    numOfCorrectAnswers?: number;
    numOfWrongAnswers?: number;
    answersIds: string[];
    questionResults: any;
    pointsPerCorrectAnswer?: number;
    pointsPerWrongAnswer?: number;
}

export interface QuizListRes {
    _id: string;
    title: string;
    startDt: Date;
    endDt: Date;
    maxPoints: number;
    description: string;
}

export interface SolvedQuizListRes {
    quizId: string;
    title: string;
    createdDt: Date;
    correctPoints: number;
    wrongPoints: number;
}

export interface NextQuestionRes {
    question: QuizQuestion;
    index?: number;
    length?: number;
}

export interface CheckAnswerReq {
    questionId: string;
    quizId: string;
    answersIds: string[];
    content?: string;
}

export interface CheckAnswerRes {
    question: string;
    userAnswers: Answer[];
}

export interface FinishQuizRes {
    sumOfCorrectAnswers: number,
    sumOfWrongAnswers: number,
    pointsPerCorrectAnswer: number,
    pointsPerWrongAnswer: number,
    correctPoints: number,
    wrongPoints: number,
    finalPoints: number
}

export interface IdAndTitleRes {
    _id: string;
    title: string;
}

export interface QuizRes {
    title: string;
    createdDt: Date;
    description: string;
    quizFn: string;
    pointsPerCorrectAnswer: number;
    pointsPerWrongAnswer: number;
    maxPoints: number;
    questions: QuizQuestion[];
    startDt: Date;
    endDt: Date;
    articleId: string;
    tenantId: string;
    status: QuizPublicationStatus;
}

export enum QuizType {
    Quiz,
    Survey,
}

export interface QuestionAnswersRes {
    question: string;
    usersAnswers: UserAnswersRes[];
}

export interface UserAnswersRes {
    firstName: string;
    lastName: string;
    userId: string;
    content: string | UserClosedAnswers | UserClosedAnswers[];
}

export interface UserClosedAnswers {
    content: string;
    isCorrect: boolean;
    _id: string;
}

export interface QuizSummaryRes {
    _id: string;
    question: string;
    answer: Answer[];
    questionFn?: string;
    answerFn?: string;
}

export interface QuizMainDataReq {
    title: string;
    description: string;
    pointsPerCorrectAnswer?: number;
    pointsPerWrongAnswer?: number;
    startDt: Date;
    endDt: Date;
    quizPictureFn: string;
    isPublished?: boolean;
}
