import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { getDefaultLanguage } from '../utils/language';

export default class SessionService {
    static setCurrentLanguage(lang: string) {
        localStorage.setItem('language', JSON.stringify(lang));
    }

    static getCurrentLanguage() {
        try {
            return JSON.parse(localStorage.getItem('language') || 'null') || getDefaultLanguage();
        } catch {
            return getDefaultLanguage();
        }
    }

    // @TODO: fix user type
    static setUser(user: any) {
        return localStorage.setItem('user', JSON.stringify(user));
    }

    static clearSessionStorage() {
        localStorage.clear();
        sessionStorage.clear();
    }

    static clearUserSession() {
        localStorage.removeItem('user');
    }

    static isUserSet() {
        return JSON.parse(localStorage.getItem('user') || 'false');
    }


    static setToken = async (token: string) => {
        if (!Capacitor.isNativePlatform()) return;
        await Preferences.set({ key: 'iplatform_token', value: token });
    };

    static getToken = async (): Promise<string> => {
        if (!Capacitor.isNativePlatform()) return '';
        const token = await Preferences.get({ key: 'heyzzp_token' });

        if (!token) return '';
        return token.value || '';
    };
}

