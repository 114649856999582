import React, { FC, useEffect } from 'react';
import { QuizProvider, useQuizContext } from '../../../hooks/quizzes/QuizProvider';
import { Button, QuestionsContent, Spinner } from '..';
import { ButtonsContainer } from '../../Layout';

interface QuizOnRunProps {
  surveyType?: boolean;
}

const QuizOnRun: FC<QuizOnRunProps> = ({ surveyType }) => {
  const { quizContext, dispatchContext } = useQuizContext();
  useEffect(() => {
    dispatchContext.runQuiz();
    if (surveyType) dispatchContext.setSurvey(true);
  }, []);

  const { handleSurveySubmit } = dispatchContext;
  const {
    survey,
    checkedAnswers,
    correctAnswers,
    wrongAnswers,
    submitQuiz,
    isLastQuestionRunning,
    loading,
  } = quizContext;
  let button = null;
  if (survey) {
    button = (
      <Button
        onClick={handleSurveySubmit}
        text={!isLastQuestionRunning() ? 'quiz.nextQuestion' : 'survey.end'}
      />
    );
  } else {
    button = (
      <>
        {correctAnswers.length === 0 && wrongAnswers.length === 0 ? (
          <Button onClick={() => submitQuiz('submit')} text="application.submit" />
        ) : !isLastQuestionRunning() ? (
          <Button disabled={loading} onClick={() => submitQuiz('next')} text="quiz.nextQuestion" />
        ) : (
          <Button disabled={loading} onClick={() => submitQuiz('finish')} text="quiz.finishQuiz" />
        )}
      </>
    );
  }
  return (
    <>
      <QuestionsContent />
      {checkedAnswers.length > 0 && <ButtonsContainer>{button}</ButtonsContainer>}
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const QuizOnRunWrapper: FC<QuizOnRunProps> = ({ surveyType }) => (
  <QuizProvider>
    <QuizOnRun surveyType={surveyType} />
  </QuizProvider>
);
