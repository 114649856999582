import { FieldType, TableSchema } from "../types";

export const rankingConfigurationTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "_id",
            field: "_id",
            searchable: false,
            sortable: false,
            type: FieldType.Text,
        },
        {
            name: "title",
            field: "title",
            searchable: true,
            sortable: false,
            type: FieldType.Text,
        },
        {
            name: "isDeactivated",
            field: "isDeactivated",
            searchable: false,
            sortable: true,
            type: FieldType.Boolean,
            defaultSort: true,
        }
    ]
};
