import {FieldType, TableSchema} from "../types";

export const reportCommentsTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "id",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "date",
            field: "date",
            searchable: false,
            sortable: true,
            type: FieldType.Date,
            defaultSort: true
        },
        {
            name: "content",
            field: "content",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "authorFirstName",
            field: "authorFirstName",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "authorLastName",
            field: "authorLastName",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "isAnonymous",
            field: "isAnonymous",
            searchable: true,
            sortable: true,
            type: FieldType.Boolean
        }
    ]
};
