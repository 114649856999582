import React, { FC } from 'react';
import { FaEdit } from 'react-icons/fa';
import { IconActionButton } from '..';

interface PreviewIconProps {
  path: string;
  dashboardIcon?: boolean;
  dataCy?: string
}

// eslint-disable-next-line import/prefer-default-export
export const EditIcon: FC<PreviewIconProps> = ({ path, dashboardIcon, dataCy }) => (
  <IconActionButton dataCy={dataCy} position={dashboardIcon ? 'absolute' : 'static'} top={dashboardIcon ? '100px' : 'unset'} right={dashboardIcon ? '20px' : 'unset'} display={dashboardIcon ? ['none', null, null, 'flex'] : 'flex'} to={path} bgColor={dashboardIcon ? '#fff' : 'colorPrimary'} title="application.edit">
    <FaEdit size={18} color={dashboardIcon ? '#000' : "#fff"} />
  </IconActionButton>
);
