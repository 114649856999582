import axios, { Method } from 'axios';
import { useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ApiErrorCode } from 'app-types';
import { MsgResult } from '../types/msgResults';
import { handlingError } from '../helpers/handlingErrors';
import { axiosConfig } from '../config/axios';

export enum ReqStatus {
  NotStarted = 1,
  InProgress,
  FulFilled,
  Rejected,
  ValidationError,
}

interface Props {
  timeout?: number;
  methods?: UseFormReturn<FieldValues>;
}
interface UseApi {
  api: <T, K = void>({
    method,
    path,
    msgResult,
    data,
    onSuccess,
    onError,
    withoutLoading,
    withDelay,
    params,
  }: ApiProps<T, K>) => Promise<void>;
  loading: boolean;
  reqStatus: ReqStatus;
}

export const useApi = (props?: Props): UseApi => {
  const [loading, setLoading] = useState(false);
  const [reqStatus, setReqStatus] = useState<ReqStatus>(ReqStatus.NotStarted);
  const delay = 500;
  const getSetTimeoutTime = (startAt: Date, withDelay?: boolean) => {
    const latency = startAt.getTime() - new Date().getTime();
    const isLatencyMoreThenDelay = latency < delay;
    return withDelay && isLatencyMoreThenDelay ? delay - latency : 0;
  };

  const api = async <T, K = void>({
    method,
    path,
    msgResult,
    data,
    onSuccess,
    onError,
    withoutLoading,
    withDelay,
    params,
  }: ApiProps<T, K>) => {
    if (!withoutLoading) {
      setLoading(true);
      setReqStatus(ReqStatus.InProgress);
    }
    const startAt = new Date();
    axios(axiosConfig(path, method, data, params, props?.timeout))
      .then((res) =>
        setTimeout(() => {
          onSuccess?.(res?.data?.payload as T);
          setLoading(false);
          setReqStatus(ReqStatus.FulFilled);
        }, getSetTimeoutTime(startAt, withDelay)),
      )
      .catch((err) =>
        setTimeout(() => {
          setLoading(false);
          if (err?.response) {
            const { status, data } = err.response;
            data.error_code === ApiErrorCode.FormValidationError
              ? setReqStatus(ReqStatus.ValidationError)
              : setReqStatus(ReqStatus.Rejected);
          }
          if (onError) onError(err);
          else handlingError(err?.response, props?.methods?.setError);
        }, getSetTimeoutTime(startAt, withDelay)),
      )
      .finally(() => {
        props?.methods?.trigger();
      });
  };

  return { api, loading, reqStatus };
};

interface ApiProps<T, K = void> {
  method: Method;
  path: string;
  msgResult?: MsgResult;
  data?: K;
  onSuccess?: (res: T) => void;
  onError?: (res: any) => void;
  withoutLoading?: boolean;
  withDelay?: boolean;
  params?: string;
}
