import React, { ReactNode } from 'react';

import './SectionTitle.scss';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  highlight?: boolean;
  noMarginTop?: boolean;
}

const SectionTitle = ({ children, highlight, noMarginTop }: Props) => (
  <h2 className={clsx('section-title', { highlight, 'no-margin-top': noMarginTop })}>{children}</h2>
);

export default SectionTitle;
