import styled from 'styled-components';
import { Main } from '../../Layout';

const StyledPageError = styled(Main)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default StyledPageError;
