import {Form, IsRequired, MinLength, Pwd, Pwd2} from "itf-validation/dist";

@Form({ post: 'user/change-pass' })
export class ChangePassDto {
    constructor () {
        const formName: string = 'ChangePassDto'
    }
    @IsRequired()
    oldPwd: string;

    @IsRequired()
    newPwd: string;

    @IsRequired()
    newPwd2: string;
}
