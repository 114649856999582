import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { Column, Row } from '../../Layout';
import { Text } from '..';
import { RootState } from '../../../reducers';
import { getUserDetails } from '../../../actions/user';
import UserImage from '../UserImage';
import { UserName } from './UserName';

interface Props {
  showAvatar?: boolean;
  hideEmail?: boolean;
  className?: string;
}

const UserDetails: FC<Props> = ({ showAvatar, hideEmail }) => {
  const user = useSelector((state: RootState) => state.user.details);
  const dispatch = useDispatch();
  const { colors } = useTheme();

  if (!user) {
    dispatch(getUserDetails());
    return null;
  }

  const { firstName, lastName, email } = user;

  return (
    <Row justifyContent="center" alignItems="center" mb={3}>
      {showAvatar && (
        <Column alignItems="center" justifyContent="center" width={1}>
          <UserImage />
        </Column>
      )}
      <Column width={1} alignItems="center" justifyContent="center">
        <UserName firstName={firstName} lastName={lastName} />
      </Column>
      {!hideEmail && (
        <Column width={1} alignItems="center" justifyContent="center">
          <Text as="span" color={colors.colorPrimary} fontSize="extraSmall" fontWeight="bold">
            {email}
          </Text>
        </Column>
      )}
    </Row>
  );
};

export default UserDetails;
