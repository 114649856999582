import {FieldType, TableSchema} from "../types";

export const solvedQuizTableSchema: TableSchema = {
    countPerPage: 10,
    fields: [
        {
            name: "Identyfikator",
            field: "id",
            searchable: true,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Tytuł",
            field: "title",
            searchable: false,
            sortable: true,
            type: FieldType.Text
        },
        {
            name: "Data rozpoczęcia",
            field: "startDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date
        },
        {
            name: "Data zakończenia",
            field: "endDt",
            searchable: false,
            sortable: true,
            type: FieldType.Date
        },
        {
            name: "Ilość zdobytych punktów",
            field: "myPoints",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        },
        {
            name: "Ilość punktów",
            field: "maxPoints",
            searchable: false,
            sortable: false,
            type: FieldType.Text
        }
    ]
};
