import React, { FC } from 'react';
import useHeader from '../../../hooks/useHeader';

interface ContentWthCustomHeaderProps {
  children: React.ReactNode;
  title: string;
}

// eslint-disable-next-line import/prefer-default-export
export const ContentWthCustomHeader: FC<ContentWthCustomHeaderProps> = ({ title, children }) => {
  useHeader(title, '/');
  return <>{children}</>;
};
