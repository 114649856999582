import {FieldType, TableSchema} from "../types";

export const quizTableSchema: TableSchema = {
  countPerPage: 10,
  fields: [
    {
      name: "Identyfikator",
      field: "id",
      searchable: true,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "Data utworzenia",
      field: "createdDt",
      searchable: false,
      sortable: true,
      type: FieldType.Date,
      defaultSort: true
    },
    {
      name: "Tytuł",
      field: "title",
      searchable: false,
      sortable: true,
      type: FieldType.Text
    },
    {
      name: "Data rozpoczęcia",
      field: "startDt",
      searchable: false,
      sortable: true,
      type: FieldType.Date
    },
    {
      name: "Data zakończenia",
      field: "endDt",
      searchable: false,
      sortable: true,
      type: FieldType.Date
    },
    {
      name: "Opublikowano",
      field: "isPublished",
      searchable: false,
      sortable: true,
      type: FieldType.Boolean
    },
    {
      name: "Dezaktywowany",
      field: "isDeactivated",
      searchable: false,
      sortable: true,
      type: FieldType.Boolean
    }
/*    {
      name: "questions",
      field: "questions",
      searchable: false,
      sortable: false,
      type: FieldType.Text
    },
    {
      name: "allVidsUploaded",
      field: "allVidsUploaded",
      searchable: false,
      sortable: false,
      type: FieldType.Boolean
    }*/
  ]
};
