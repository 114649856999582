import ApiService from '../services/api-service';

export const getUserDetails = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_USER_DETAILS_SUCCESS',
    'GET_USER_DETAILS_FAILED',
    null,
    null,
    'user/details',
    'LOADING_USER',
  );
};

export const getPoints = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_USER_POINTS_SUCCESS',
    'GET_USER_POINTS_FAILED',
    null,
    null,
    'ranking/details',
    'LOADING_USER',
  );
};
