import * as React from 'react';
import './Spinner.scss';
import styled from 'styled-components';
import { theme } from '../../../config/theme';

interface Props {
  overlay?: boolean;
  small?: boolean;
  transparent?: boolean;
  halfTransparent?: boolean;
  dark?: boolean;
  fading?: boolean;
  light?: boolean;
}

const StyledSpinner = styled.div`
  border: 3px solid ${({ theme }) => theme.colors && theme.colors.colorPrimary};
  border-color: ${({ theme }) => theme.colors && theme.colors.colorPrimary} transparent transparent transparent;
`;

const Spinner = ({ overlay, transparent, halfTransparent, dark, small, fading, light }: Props) => (
  <div
    className={`spinner-container ${overlay ? 'overlay' : ''}
    ${small ? 'small' : ''} ${transparent ? 'transparent' : ''} 
    ${halfTransparent ? 'half-transparent' : ''} 
    ${dark ? 'dark' : ''}
    ${fading ? 'fading' : ''}
    ${light ? 'light' : ''}
    `}
  >
    <StyledSpinner className="spinner" />
  </div>
);

export default Spinner;
