import { SelectOption } from '../form';
import { Segment } from '../segment';
import { SMSStatRes } from '../communication';
import { AdListDetails, CustomSelectOption } from '..';

export interface TenantImageRes {
  _id: string;
}

export interface AddTenantReq {
  name: string;
  text: string;
  title: string;
  description: string;
  host: string;
  logoFn: string;
  pwaFn: string;
  longAppName: string;
  shortAppName: string;
  dashboardImageFn: string;
  colorPrimary: string;
  colorSecondary: string;
  colorBackground: string;
  colors: Colors;
  fontBold: string;
  fontMedium: string;
  fontNormal: string;
  segment: TenantSegment[];
  boundType?: string[];
  tenantFbPageID?: string;
  tenantInstagramPageID?: string;
  tenantTwitterPageID?: string;
  tenantLIPageID?: string;
  tenantYbPageID?: string;
  tenantContactMail?: string;
  tenantWebsite?: string;
  isDeactivated?: boolean;
  borderRadius?: number;
  isUserActiveAfterRegister?: boolean;
  basicForms?: any;
  allowCommenting?: boolean;
  allowAnonymousComments?: boolean;
  allowNestedComments?: boolean;
  allowUserToDeleteOwnComments?: boolean;
  initialsInsteadOfName?: boolean;
  responsibleForAwardsEmail?: string;
  payUAccount?: string;
  payUClientSecret?: string;
  currency?: 'PLN' | 'EUR' | 'USD';
  secondKeyMD5?: string;
  fbPages: SelectOption[];
}

export interface TenantRes {
  _id: string;
  name: string;
  text: string;
  title: string;
  description: string;
  host: string;
  logoFn: string;
  pwaFn: string;
  longAppName: string;
  shortAppName: string;
  dashboardImageFn: string;
  mainImageFn: string;
  colorPrimary: string;
  colorSecondary: string;
  colorBackground: string;
  colors: Colors;
  fontBold: string;
  fontMedium: string;
  fontNormal: string;
  segment: TenantSegment[];
  boundType?: string[];
  tenantFbPageID?: string;
  tenantInstagramPageID?: string;
  tenantTwitterPageID?: string;
  tenantLIPageID?: string;
  tenantYbPageID?: string;
  tenantContactMail?: string;
  tenantWebsite?: string;
  isDeactivated?: boolean;
  isUserActiveAfterRegister?: boolean;
  basicForms?: any;
  borderRadius?: number;
  allowCommenting?: boolean;
  allowAnonymousComments?: boolean;
  allowNestedComments?: boolean;
  allowUserToDeleteOwnComments?: boolean;
  responsibleForAwardsEmail?: string;
  payUAccount?: string;
  payUClientSecret?: string;
  currency?: 'PLN' | 'EUR' | 'USD';
  secondKeyMD5?: string;
  fbPages: SelectOption[];
}

export interface TenantSegment {
  _id: string;
  segmentNameForTenant: string;
  segmentBg: string;
  fileFn: string;
  segment: Segment;
  order: number;
}

export interface Colors {
  colorLight: string;
  colorGray: string;
  colorDark: string;
  colorDanger: string;
  colorNotice: string;
  colorSuccess: string;
  colorWarning: string;
  colorDisabled: string;
  colorField: string;
  colorPrimary: string;
  colorBackground: string;
  colorSecondary: string;
  colorInput: string;
  colorTextSecondary: string;
}

export interface BasicForm {
  additionalFields: SelectOption[];
  _id: string;
  text: string;
  isDefault: boolean;
}

export enum RegistrationType {
  AcceptIsNotNeeded,
  AcceptIsNeeded,
  AcceptByField,
}

export enum AcceptType {
  OnRegister,
  OnFirstLogin
}


export interface RegistrationTypeReq {
  acceptFields: SelectOption[];
  registrationType: CustomSelectOption<RegistrationType>;
  rulesAcceptType: CustomSelectOption<AcceptType>;
  privacyAcceptType: CustomSelectOption<AcceptType>;
}

export interface TenantSegment {
  _id: string;
  segmentNameForTenant: string;
  segmentBg: string;
  fileFn: string;
  segment: Segment;
}

export interface TenantTableRes {
  _id: string;
  name: string;
  host: string;
  isDeactivated: boolean;
}

export interface ActiveModulesRes {
  segments: TenantSegment[];
  boundType: SelectOption;
  registrationType: RegistrationType;
  registrationFields: SelectOption[];
  infoIcon: string;
}

export interface PlatformBasicConfig {
  _id: string;
  isUserActiveAfterRegister: boolean;
  borderRadius: number;
  name: string;
  title: string;
  host: string;
  description: string;
  longAppName: string;
  shortAppName: string;
  text: string;
  basicForms: any;
  colors: Colors;
  logoFn: string;
  pwaFn: string;
  dashboardImageFn: string;
  currency: string;
  privacyAcceptType: AcceptType;
  rulesAcceptType: AcceptType;
  isDeactivated: boolean;
}

export type PlatformFullConfig = PlatformBasicConfig & {
  mainImageFn: string;
  fontNormal: string;
  fontMedium: string;
  fontBold: string;
  segment: any;
  boundType: SelectOption;
  tenantFbPageID: string;
  tenantInstagramPageID: string;
  tenantFacebookToken: string;
  tenantTwitterPageID: string;
  tenantLIPageID: string;
  tenantYbPageID: string;
  tenantContactMail: string;
  tenantWebsite: string;
  isDeactivated: boolean;
  registrationType: RegistrationType;
  registrationFieldsToCheck: SelectOption[];
  smsSenderName: string;
  infoIcon: string;
  allowCommenting: boolean;
  allowNestedComments: boolean;
  allowAnonymousComments: boolean;
  allowUserToDeleteOwnComments: boolean;
  initialsInsteadOfName: boolean;
  responsibleForAwardsEmail: string;
  payUAccount: string;
  payUClientSecret: string;
  smsStats: SMSStatRes;
  globalProductsLimit: number;
  secondKeyMD5: string;
  fbPages?: SelectOption[];
  ads?: AdListDetails[];
}

export interface ClearTenantReq {
  password: string;
}

export interface ColumnsConfig {
  name: string,
  forRoles: SelectOption[]
}

export interface NestedColumnsConfig {
  [field: string]: {
    name: string,
    forRoles: SelectOption[]
  }
}

export class SingleColumnsConfig {
  [field: string]: ColumnsConfig | NestedColumnsConfig;
}

export interface TenantSelectOption {
  _id: string;
  text: string;
  host: string;
}